@media print {
	
	#header, 
	#header_mobile, 
	#footer_wrapper, 
	.section_bottom_page, 
	.diagonale, 
	#section_temoignage, 
	.video_rte{
		display: none !important; 
	}

	#node_base .node_base_container .inside{
		padding: 0;
		padding-top: 20px;
	}

	.container{
		width: 100% !important; 
	}

	.node-type-actualite, .page-forms-joining, .node-type-page-de-base{
		#page_banner{

			> *{
				display: none; 
			}

			#print_image{
				display: block;
				width: 60%; 
				height: auto; 
				margin: 0px auto; 
			}
		}

		#node_base .node_base_container{
			margin-top: 0;
		}
	}

	#breadcrumbs{
		margin-bottom: 50px;
		background: none; 
	}
	#breadcrumbs .easy-breadcrumb a{
		color: #000;
	}

	.page-forms-joining{
		.checkbox_container, .step_actions, #steps_container, #breadcrumbs, .print{
			display: none; 
		}

		#step_confirmation{
			padding: 0;
			.step_confirmation_container{
				width: 100%; 
			}
		}

		#step_confirmation .step_confirmation_container .total_recap{
			print-color-adjust: exact;
		}
	}

	#node_base .node_base_container h1{
		font-size: 25px;
		margin-bottom: 30px;
	}

	#page_banner .content{
		padding-top: 0;
	}

	#page_banner{
		height: auto; 
		margin-bottom: 40px;
	}

	.tab_content_menu, .button_subscribe, #partners, #usefulldocuments, #media, #report{
		display: none !important;  
	}

	.list-accordion > li .accordiou_tab_item_content, .list-mots > li .mots_message{
		display: block !important; 
	}

	.tab_content{
		display: block !important; 
	}
	#node_event .node_base_container #node_event_content{
		width: 100% !important;
		padding: 0 !important;
		flex: none; 
	}
}