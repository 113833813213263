
#page_tbd{
	position: relative;
	background: #354053; 

	@include breakpoint(medium down){
		background: #fff; 
	}

	@include fillBackgroundRight(#fff); 


	#open_sidebar_mobile{
		display: none; 
		position: relative;
		z-index: 50;

		@include breakpoint(medium down){
			display: block;
			@include clearfix; 
			background: $muted-blue; 
			padding: 22px 46px; 
			color: #fff;

			@include breakpoint(small down){
				padding: 22px; 
			}

			span{
				float: left;
				font-size: 18px;
				font-weight: $fwSemiBold;  
				text-transform: uppercase;
			}

			i{
				float: right;
				@include rotate(0deg); 
				@include transition(0.3s); 

				&:after{
					@include font($zmdi-var-chevron-down); 
					font-size: 26px; 
					line-height: 14px;
					
				}
			}

			&.active{
				i{
					@include rotate(180deg); 
				}
			}
		}

		
	}

	.page_tbd_container{
		position: relative;
		z-index: 900;		
		
		@include clearfix; 


		
		@include breakpoint(xlarge up){
			@include container(1366px); 
		}

		@media screen and (max-width: 1366px) {
		
			@include container(1024px); 

			padding: 0px !important; 
		}

		@include breakpoint(medium down){
			width: 100%; 
			max-width: 100%; 

			#content_tbd{
				float: none !important; 
				margin: 0px auto; 
				max-width: 600px; 

				.inside{
					padding-left: 0 !important;
					padding-right: 0px !important;
				}
			}
		}

		@include breakpoint(small down){
			#content_tbd{
				padding-left: 20px;
				padding-right: 20px;
			}
		}

	

		#sidebar_tbd{
			float: left;
			background: #354053; 
			width: 280px;
			@include fillBackgroundLeftOutside(#fff); 
			

			@media screen and (max-width: 1366px) {
				width: 304px;
			}

			@include breakpoint(large up){
				display: block !important; 
			}

			@include breakpoint(medium down){
				width: 100%; 
				float: none; 
				
			}
		}

		#content_tbd{
			background: #fff;
			width: 1086px; 
			float: right;
			@include breakpoint(large up){
				min-height: 910px; 
			}

			.inside{
				padding: 55px;
				padding-bottom: 70px;
			}


			@media screen and (max-width: 1366px) {
				width: 720px;

				.inside{
					padding-right: 0;
				}
			}

			@include breakpoint(medium down){
				width: 100%; 
				.inside{
					padding-left: 20px;
					padding-right: 20px; 
				}
			}

		}



	}


}