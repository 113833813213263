
.paiements_table{

	width: 100%; 

	@include breakpoint(small down){
		tbody, tr, td{
			display: block; 
		}
	}

	thead{
		th{
			color: #fff; 
			font-size: 16px;
			font-weight: $fwBold; 
			background: $muted-blue; 
			padding: 25px 0px;

			&:first-child{
				border-left: solid 20px $muted-blue; 
			}
			&:last-child{
				border-right: solid 20px $muted-blue; 
				}
		}

		@include breakpoint(small down){
			display: none; 
		}
	}

	td, th{
		text-align: center;
		
		&.title{
			text-align: left;
			padding-left: 90px; 
		}

		&.price{
			padding-left: 55px;
			padding-right: 55px;
			white-space: nowrap; 
		}
	}

	tbody{

		tr{

			@include breakpoint(small down){

				border: solid 20px #ecedf2; 
				background: #fff; 
				padding: 15px; 
				@include clearfix; 

				&:not(:last-child){
					border-bottom: none;
				} 

				td{
					border: none !important;  
					padding: 0 !important; 
					

					&.qty, &.price{
						float: left;
						width: 50%; 

						&:before{
							content: attr(data-title) " :";
							color: $turquoise-blue;
							font-weight: $fwSemiBold; 
							margin-right: 10px;
						}
					}
				}
				
			}
			td{
				background: #fff; 
				padding-top: 24px;
				padding-bottom: 24px;
				border-bottom: solid 20px #ecedf2;
				font-size: 16px;

				&:first-child{
					border-left: solid 20px #ecedf2;
				}
				&:last-child{
					border-right: solid 20px #ecedf2;
				}

				.checkbox{

					@include breakpoint(small down){
						margin-bottom: 15px;
					}
					input{
						display: none; 
					}

					label{
						color: $muted-blue; 
						position: relative;
						cursor: pointer; 

						@include breakpoint(small down){
							padding-left: 30px;
							display: block;
						}

						&:before{
							content: "";
							background: #fff; 
							width: 16px;
							height: 16px;
							font-size: 14px; 
							color: #fff;
							border-radius: 2px; 
							border: solid 2px #858585;
							left: -50px;
							right: 0;
							position: absolute; 
							text-align: center;
							line-height: 14px; 

							@include breakpoint(small down){
								left: 0; 
								
							}

						}

						em{
							font-style: italic; 
						}
					}

					input:checked + label{

						&:before{
							border-color: $muted-blue;
							background: $muted-blue;
							@include font($zmdi-var-check); 
						}
					}
					
				}
			}
			@include breakpoint(medium up){
				&:first-child{
					td{
						border-top: solid 20px #ecedf2;
						
						&:first-child{
							position: relative;

							&:before{
								content: "";
								width: 20px;
								height: 20px;
								top: -20px;
								left: -20px;
								position: absolute; 
								background: #ecedf2;

								
							}
						}

						&:last-child{
							position: relative;

							&:before{
								content: "";
								width: 20px;
								height: 20px;
								top: -20px;
								right: -20px;
								position: absolute; 
								background: #ecedf2;

								
							}
						}
					}	
				}
			}	
		}
	}

}