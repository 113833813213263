#page_banner{
	width: 100%;
	height: 400px;
	background-size: cover;
	position: relative;
	background-position: center center;

  //z-index: 1000;

	#print_image{
		display: none;
	}

	@include breakpoint(small down){
		min-height: 320px;
		height: auto;
		padding-bottom: 60px;
	}

	#backList{
		@include backLink(#fff, #fff);
		z-index: 55;
		position: absolute;
		top: 60px;
		left: 40px;

		@include breakpoint(small down){
			top: 20px;
			left: 20px;
		}

	}


	.content{
		margin: 0px auto;
		padding-top: 105px;

		text-align: center;
		position: relative;
		z-index: 50;

			@include breakpoint(small down){
				padding-left: 20px;
				padding-right: 20px;
				padding-top: 75px;
			}

		h3{
			color: #fff;
			text-transform: uppercase;
			font-family: $ffP;
			font-size: 16px;
			margin-bottom: 20px;
		}


		h1{
			font-family: $ffT;
			font-size: 36px;
			font-weight: $fwBold;
			line-height: 54.0px;
			text-align: center;
			color: #ffffff;
			text-shadow: 0 3px 1px rgba(0, 0, 0, 0.16);

			@include breakpoint(small down){
				font-size: 24px;
				line-height: 36px;
			}


		}

		p{
			color: #fff;
			font-size: 16px;
			max-width: 490px;
			margin: 0px auto;
			line-height: 24px;
			margin-top: 7px;
			text-shadow: 0 3px 1px rgba(0, 0, 0, 0.16);

			@include breakpoint(small down){
				font-size: 14px;
				line-height: 21px;
			}

			&.location{
				margin-bottom: 13px;
				margin-top: 15px;
				&:before{
					@include font($zmdi-var-pin);
					margin-right: 4px;
				}
			}

			&.date{
				font-weight: $fwSemiBold;
			}
		}



		.bar{
			width: 65px;
			height: 5px;
			background-color: $greenish-yellow;
			margin: 0px auto;
			margin-top: 25px;
		}

		.button{
			margin-top: 25px;
			display: inline-block;
			height: 40px;
			border: solid 1px #ffffff;
			line-height: 40px;
			text-align: center;
			color: #fff;
			font-size: 16px;
			padding: 0px 16px;

			&:hover{
				background: #fff;
				color: $muted-blue;
			}

			&.button_subscribe{
				border: none;
				background: $turquoise-blue;
				padding: 0px 25px;

				&:hover{
					background: $muted-blue;
					color: #fff;
				}

				&:before{
					@include font($zmdi-var-check);
					margin-right: 8px;
					font-size: 16px;

				}
			}
		}
    .pre-event-login-block {
      @extend %login-popup-block;

      width: 100%;

      .popup-overlay {
        z-index: 7000;
      }

      .popup-content-wrapper {
        position: relative;
        margin: 0 auto;
        width: 450px;

        .popup-content {
          position: relative;

          .content {
            padding-top: 15px;
            h4 {
              margin: 0 0 10px;
            }

            .form-type-password-restore {
              text-align: left;

              .label_link {
                position: inherit;
              }
            }

            .form-type-checkbox.form-item-remember-me {
              text-align: left;
            }
          }
        }
      }
    }
	}

	&.commission, &.dossier, &.event{

		&:before{
			content: "";
			background: #000;
			opacity: 0.5;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1;
		}

	}

	&.commission{
		.content{
			max-width: 740px;
		}
	}

	&.dossier{
		.content{
			max-width: 785px;
		}
	}

	&.event{

	}

	&.form_register{
		background-image: url(../img/banner_inscription.png);

	}
}
