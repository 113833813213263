

.petition{

	&_teaser{
		
		.bg{
			display: block;
			background: #fff; 
			max-width: 300px; 
			margin: 0px auto;

			.img{
				
				@include imgHover; 
			
		
			}

			.detail{
				padding: 26px 45px 70px 30px; 
				border: solid 1px #dadbe2;
				border-top: 0px; 
				position: relative;

				.date{
					font-size: 12.6px;
					font-weight: normal;
					line-height: normal;
					color: $muted-blue;
					margin-bottom: 15px;
				}

				h3{
					
					font-size: 16px;
					font-weight: $fwSemiBold; 
					line-height: 24px;
					color: $black;
					min-height: 96px;
				}

				.button{
					position: absolute;
					bottom: 0;
					right: 0;
					padding: 0px 16px; 
					height: 45px; 
					line-height: 45px; 
					color: #fff; 
					font-size: 14px;
					font-weight: $fwSemiBold; 
					background: $muted-blue;
				}

				.end{
					font-size: 14px;
					color: $turquoise-blue;
					margin-top: 20px;

					&:before{
						@include font($zmdi-var-time);
						margin-right: 4px; 
					}
				}

			}

			&:hover{
				.img{
					@include imgHoverActive; 
				}
			}
		}
	}
}