.hamburger{
	display: block;
	position: relative;
	overflow: hidden;
	margin: 0;
	padding: 0;
	width: 25px;
	height: 25px; 
	font-size: 0;
	text-indent: -9999px;
	appearance: none; 
	box-shadow: none;
	border-radius: none;
	border: none;
	cursor: pointer;
	transition: background 0.3s; 
	background: none;
	outline: none; 
	z-index: 55;

	display: none; 



	span{
		display: block;
		position: relative;
		width: 100%; 
		height: 3px;
		margin: 0px auto; 
		background: #9b9b9b;
		margin-top: 10px;
		transition: background 0.3s;

		&:before,
		&:after {
		  position: absolute;
		  display: block;
		  left: 0;
		  width: 100%;
		  height: 3px;
		  background-color: #9b9b9b;
		  content: "";
		  transition-duration: 0.3s, 0.3s;
			transition-delay: 0.3s, 0s;
		}

		&:before {
		  top: -10px;
		    transition-property: top, transform;
		}

		&:after {
		  bottom: -10px;
		   transition-property: bottom, transform;
		}
	}

	&.is-active{
		 
		
		  span{
		  	background: none;
		  	


		  	&:before{
				 top: 0;
				transform: rotate(45deg);
				transition-delay: 0s, 0.3s;
					background-color: #9b9b9b; 
		  	}

		  	&:after{
				bottom: 0;
				transform: rotate(-45deg);
				transition-delay: 0s, 0.3s;
					background-color: #9b9b9b; 
		  	}
		  }
	}
}