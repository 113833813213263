#footer_wrapper{

	background: #2a3240; 
	color: #fff;

	.container{
		position: relative;

		.scroll_top{
			position: absolute;
			right: 0;
			top: 25px;
			width: 26px;
			height: 26px;
			text-align: center;
			border: solid 1px #717680; 
			line-height: 24px; 
			color: #fff; 
			font-size: 15px;
			cursor: pointer; 

			&:hover{
				background: #fff;
				color: $black; 
			}
			&:before{
				@include font($zmdi-var-chevron-up); 

			}
		}
	}

	#footer_top{
		padding: 40px 0 60px;
		@include clearfix; 
		border-bottom: solid 1px rgba(#fff, 0.11);

		.footer_block{
			img{
				display: block;
				max-width: 100%; 
				height: auto; 
			}
		}

		.footer_block{
			float: left;

			@include breakpoint(small down){
				float: none !important;  
				
				
				width: 210px; 
				margin: 0px auto !important; 

				&:not(#footerFive){
					margin-bottom: 37px !important; 
				} 

			}	

			@include breakpoint(large only){
				margin-right: 0 !important; 

				width: 25%; 
				padding-right: 20px;

				&#footerFive{
					width: 100%; 
					padding-top: 40px;
					clear: both; 
					text-align: center;
					padding-right: 0;
				}

			}

			@include breakpoint(medium only){
				margin-right: 0 !important; 

				width: 50%; 
				padding-right: 20px;
				height: 80px;

				&#footerOne, &#footerTwo{
					margin-bottom: 30px;
				}

				&#footerFive{
					width: 100%; 
					padding-top: 40px;
					clear: both; 
					text-align: center;
					padding-right: 0;
				}

			}

			h5{
				font-weight: $fwSemiBold; 
				font-size: 11px;
				
			}
		}

		#footerOne{
			margin-right: 50px; 
		}

		#footerTwo, #footerThree, #footerFour{
			padding-left: 20px; 
			border-left: solid 1px rgba(#fff, 0.11);
			margin-right: 50px;
		}

		#footerTwo{
			ul{
				margin-top: 11px;
				> li{
					&:not(:last-child){
						margin-bottom: 10px;
					}
					font-size: 11px;
					a{
						color: #fff; 
						font-weight: $fwSemiBold; 
						&:hover{
							text-decoration: underline;
						}
					}
					&:before{
						font-size: 11px;
						margin-right: 5px;
					}	

					&.email{
						&:before{
							@include font($zmdi-var-email); 
						}
					}

					&.phone{
						&:before{
							@include font($zmdi-var-phone); 
						}
					}
				}
			}
		}

		#footerThree{
			form{
				position: relative;
				margin-top: 20px;

				.form-item-mail{
					position: relative;
					min-height: 28px; 
					margin-top: 10px;

				}

				.form-text{
					width: 180px;  
					height: 28px; 
					padding-left: 8px; 
					color: #fff; 
					font-size: 11px;
					border: solid 1px #717680; 
					background: transparent; 
					width: 100%; 
					border-radius: 0px; 
					appearance: none; 
					padding-right: 30px;
					
					@include input-placeholder{
						color: #fff;
					}
				}

				.form-submit{
					position: absolute;
					width: 26px;
					height: 26px;
					right: 1px;
					/*	top: 1px;*/
					bottom: 1px; 
					font-size: 0;
					padding: 0;
					background: #5D636D;
					border: none; 
					cursor: pointer; 
					z-index: 555; 

					&:hover{
						background: $muted-blue;
					}
				}

				&:after{
					content: "";
					position: absolute;
					width: 26px;
					height: 26px;
					right: 1px;
/*					top: 1px;*/
					bottom: 1px;
					line-height: 26px; 
					color: #fff; 
					pointer-events: none; 
					font-size: 15px;
					text-align: center;
					z-index: 666; 
					
					@include font($zmdi-var-long-arrow-right); 
				}
			}
			.form-item-validation{
			    display: block;
			    width: 210px;
			    font-size: 12px;
				line-height: 15px;
				
				label{
					a{
						color: #fff; 
						&:hover{
							text-decoration: underline; 
						}
					}
				}
			}
		}

		#footerFour{
			ul.menu{
				@include clearfix; 
				margin-top: 25px;

				> li{
					float: left;
					&:not(:last-child){
						margin-right: 20px;
					}

					a{
						display: block;
						font-size: 0;
						color: #fff; 
						height: 18px;
						line-height: 18px; 

						&:hover{
							color: $turquoise-blue;
						}

						&:before{
							font-size: 22px; 
						}

						&.fa-facebook:before{
							@include font($zmdi-var-facebook); 
						}
						&.fa-twitter:before{
							@include font($zmdi-var-twitter); 
						}
						&.fa-linkedin:before{
							@include font($zmdi-var-linkedin); 
						}
						&.fa-youtube-play:before{
							font-size: 25px; 
							@include font($zmdi-var-youtube-play); 

						}
					}
				}
			}
		}

		#footerFour, #footerThree{
			h5{
				line-height: 15px; 
			}
		}

		#footerFive{
			padding-top: 15px;
		}
	}



	#footer_bottom{
		padding: 15px 0px;
		@include clearfix; 

		@include breakpoint(small up){
			text-align: center;
		}

		.left{
			@include breakpoint(medium up){
				float: left;
			}

			font-size: 10px;
			color: rgba(#fff, 0.6); 

			a{
				color: rgba(#fff, 0.6);

				&:hover{
					color: #fff;
				}
			}

			p{
				line-height: 20px; 
			}
		}

		.right{
			@include breakpoint(medium up){
				float: right;
			}

			@include breakpoint(small down){
				margin-top: 20px;
			}

			.menu{
				@include clearfix; 

				@include breakpoint(small up){
					display: inline-block;
				}
				> li{
					float: left;

					a{
						color: #fff; 
						font-size: 10px;

						&:hover{
							text-decoration: underline;
						}
					}

					&:not(:last-child){
						&:after{
							content:"|";
							margin: 0px 5px; 
							font-size: 10px;
						}
					}
				}
			}
		}
	}
}

body.logged-in{
	#footer_wrapper #footer_top #footerThree form .form-submit{
		font-size: 12px;
		color: #fff; 
		width: auto; 
		padding-left: 10px;
		padding-right: 25px;
		position: relative; 
		width: 100%;
	}
}