.list-galerie-page{
		
	> li{
		margin-bottom: 25px; 
	}
	
	@include breakpoint(xlarge up){
					
		@include row(25); 

		> li {

			@include grid-column(4, 25);

		}
	}



	@media screen and (max-width: 1366px) {
					
		@include row(25); 

		> li {

			@include grid-column(6,  25);

			

		}
	}

	@include breakpoint(small down){
		> li{

			width: 100%; 
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
	}
}