#section_home_avantages{
	
	background: $pale-grey; 

	.home_avantages_block{
		background: #fff; 
		text-align: center;
		padding-top: 65px;
		padding-bottom: 55px;
		position: relative;
		z-index: 100; 

		@include breakpoint(large up){
			margin-bottom: -155px;
		}

		@include breakpoint(medium only){
			margin-bottom: -90px; 
		}
			@include breakpoint(small down){
				padding-top: 25px;
				padding-bottom: 25px;
			}

		h2, h3{
			font-family: $ffDroid; 
			color: $muted-blue;
			font-weight: normal; 
			font-size: 22px;
			padding-left: 25px;
			padding-right: 25px;


			@include breakpoint(medium down){
				font-size: 15px;
			}
		}

		h2{
			font-size: 24px;
			font-weight: $fwBold; 
			margin-bottom: 8px;

			@include breakpoint(medium down){
				font-size: 20px;
			}
		}

		.avantage_link{
			text-align: center;
			
			a{
				@include buttonBig($mainColor, $secondColor, inline-block, 220px); 
			}
		}

		.avantage_carousel_block{
			margin-top: 80px;
			margin-bottom: 60px;
			position: relative;
			position: relative;

			@include breakpoint(small down){
				margin-bottom: 120px;
				margin-top: 30px;
			}
			&:before{
				content: "";
				position: absolute;
				height: 100%;
				left: 0;
				top: 0;
				background: #fff; 
				z-index: 50;
				width: 2px;
			}

			.owl-nav{
				> div{
					position: absolute;
					width: 64px;
					height: 64px;
					background-color: $turquoise-blue;
					text-align: center;
					line-height: 64px;
					border-radius: 50%; 
					position: absolute;
					top: 50%;
					margin-top: -32px; 
					color: #fff; 
					font-size: 0;
					z-index: 60;
					@include transition(0.3s); 
					@include scale(1); 

					@include breakpoint(small down){
						margin-top: 0;
						top: inherit;
						bottom: -90px; 
					}

					&:before{
						font-size: 40px; 
					}

					&:hover{
						background: $mainColor; 
						@include scale(1.1); 
					}

					&.owl-prev{
						left: -32px;

						@include breakpoint(small down){
							left: 75px; 
						}

						&:before{
							@include font($zmdi-var-chevron-left); 
						} 
					}

					&.owl-next{
						right: -32px;

						@include breakpoint(small down){
							right: 75px; 
						}

						&:before{
							@include font($zmdi-var-chevron-right); 
						}
					}
				}
			}

			.bg{
				display: block; 
				@include breakpoint(medium up){
					border-left: solid 2px #c6ff00;
				}
				i{
					display: flex;
					align-items: center; 
					justify-content: center; 
					margin: 0px auto; 
					width: 145px; 
					height: 145px;
					border-radius: 50%; 
					background-color: $pale-grey;
					margin-bottom: 30px;
					@include transition(0.3s); 

					@include breakpoint(small down){
						margin-bottom: 15px;
					}
					
					img{
						display: block; 
						width: auto; 
						@include transition(0.3s); 
						@include scale(1);
					}
				}

				h4{
					max-width: 255px; 
					margin: 0px auto; 
					font-size: 18px;
					font-weight: 600;
					line-height: 24.0px;
					color: $black;
				}

			}

			a.bg{
				&:hover{
					i{
						background: $mainColor; 
						img{
							@include scale(1.1);
						}
					}
				}
			}
			
		}

		.avantage_link{

		}
	}
}