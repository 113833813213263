@mixin catTeaser($bgColor:$tealish){

	height: 30px;
	line-height: 30px; 
	background-color: $bgColor;	
	padding-left: 13px;
	padding-right: 13px;
	font-size: 12px;
	font-weight: $fwSemiBold; 
	font-size: 12px;
	font-family: $ffP; 
	color: #fff; 
	z-index: 66;

}

@mixin fillBackgroundLeft($bgColor){

	position: relative;
	width: 100%; 
	overflow: hidden; 

	&:before{
		content: "";
		height: 100%; 
		width: 50%; 
		position: absolute;
		z-index: 1; 
		background: $bgColor; 
		top: 0;
		left: 0; 
	}
}


@mixin fillBackgroundRight($bgColor){

	position: relative;
	width: 100%; 
	overflow: hidden; 

	&:after{
		content: "";
		height: 100%; 
		width: 50%; 
		position: absolute;
		z-index: 1; 
		background: $bgColor; 
		right: 0;
		top: 0;
	}

}



@mixin fillBackgroundRightOutside($bgColor, $width:100%){

	&:after{
		content: "";
		height: 100%; 
		width: $width; 
		position: absolute;
		z-index: 1; 
		background: $bgColor; 
		right: -$width; 
		top: 0;
	}

}

@mixin fillBackgroundLeftOutside($bgColor, $width:100%){

	&:before{
		content: "";
		height: 100%; 
		width: $width; 
		position: absolute;
		z-index: 1; 
		background: $bgColor; 
		left: -$width;
		top: 0;
	}

}

@mixin backLink($arrowColor : $muted-blue, $textColor: #858585){
	font-size: 14px;
	font-weight: $fwSemiBold; 
	text-decoration: underline;
	color: $textColor; 
	padding-left: 18px;
	position: relative;

	&:hover{
		color: $muted-blue;
	}

	&:before{
		@include font($zmdi-var-long-arrow-left); 
		color: $arrowColor; 
		margin-right: 6px; 
		text-decoration: none;
		position: absolute;
		top: 0;
		left: 0px;  
	}

	@include breakpoint(small down){
		font-size: 12px;
	}
}


@mixin tooltip(){
		.tooltip{
			opacity: 0; 
			@include transition(0.3s); 
			height: 51px;
			position: absolute;
			top: 50px;
			line-height: 51px; 
			left: 0;
			background: #edeef2; 
			padding: 0px 18px; 
			font-size: 14px;
			color: #303030;
			white-space: nowrap;
			pointer-events: none; 

			&:after{
				content: "";
				position: absolute;
				left: 20px;
				top: -4px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 5px 4px 5px;
				border-color: transparent transparent #edeef2 transparent;
			}
				
		}

		&:hover{
			.tooltip{
				opacity: 1;
			}
		}
}