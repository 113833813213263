#summary{

	@include clearfix; 

	@include breakpoint(small down){
		display: none; 
	}

	

	&[data-count='5']{
		> li{
			width: 20%; 
		}
	}

	&[data-count='4']{
		> li{
			width: 25%; 
		}
	}

	&[data-count='3']{
		> li{
			width: 33.333%; 
		}
	}

	&[data-count='2']{
		> li{
			width: 50%; 
		}
	}
	&[data-count='1']{
		> li{
			width: 100%; 
		}
	}
	
	> li{
		display: none; 
		float: left;
		

		&:not(:last-child){
			border-right: solid 1px #2a4b7e; 
		}

		a{
			display: block;
			padding-top: 35px;
			padding-bottom: 50px;
			background: $muted-blue; 
			color: #fff; 
			text-align: center;
			font-size: 15px;
			font-weight: $fwSemiBold; 
			position: relative;


			&:hover{
				background: $turquoise-blue;
				&:after{
					bottom: 10px; 
				}
			}
			
			&:after{
				@include font($zmdi-var-chevron-down); 
				font-size: 21px;
				position: absolute;
				width: 100%; 
				text-align: center;
				bottom: 18px; 
				left: 0;
				@include transition(0.3s); 

			}
		}
	}


}