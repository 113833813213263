// Import setup

@import 'foundation';

@import "setup/normalize.scss";
@import "setup/variables.scss";

@import "setup/mixins.scss";
@import "setup/mixins/clearfix.scss";
@import "setup/mixins/triangle.scss";
@import "setup/grid.scss";

@import "setup/cssowl.scss";
@import "setup/sprites/accesrapide.scss";
@import "setup/sprites/mobill.scss";
@import "setup/sprites/pictos.scss";


@import "fonts.css";


// Import componants

@import "componants/animate.scss";
@import "componants/owlcarousel/owl.carousel.scss";
@import "componants/dialog/dialog.scss";
@import "componants/material-iconic/material-design-iconic-font.scss";
@import "componants/login-popup-block.scss";
@import "componants/pupload.scss";
@import "componants/datepicker.scss";
@import "componants/swipebox.scss";

// Import elements

@import "elements/button.scss";
@import "elements/forms.scss";
@import "elements/hamburger.scss";
@import "elements/titles.scss";
@import "elements/rte.scss";
@import "elements/messages.scss";
@import "elements/pager.scss";
@import "elements/owl-carousel.scss";
@import "elements/dialog.scss";
@import "elements/forms.scss";
@import "elements/img_hover.scss";
@import "elements/diagonale.scss";
@import "elements/misc.scss";
@import "elements/breadcrumbs.scss";
@import "elements/main_wrapper.scss";
@import "elements/tool-bar.scss";
@import "elements/tool-bar-horizontal.scss";
@import "elements/flag.scss";
@import "elements/filters.scss";
@import "elements/summary.scss";
@import "elements/body_expand.scss";
@import "elements/tab-content-menu.scss";
@import "elements/table_comittee.scss";
@import "elements/collapsible_block.scss";
@import "elements/form_steps.scss";
@import "elements/tables.scss";
@import "elements/alphabetical_listing_filter.scss";
@import "elements/loading.scss";
@import "elements/addtocal.scss";
@import "elements/cookiebanner.scss";
@import "elements/autocomplete.scss";
// Import main files

@import "global.scss";
@import "header-top.scss";
@import "header-main.scss";
@import "header-mobile.scss";
@import "footer.scss";
@import "home.scss";
@import "page.scss";
@import "page-banner.scss";
@import "forms.scss";


/* HOME SECTIONS */

@import "sections/section-home_banner.scss";
@import "sections/section-home_acces_rapide.scss";
@import "sections/section-home_listings.scss";
@import "sections/section-temoignage.scss";
@import "sections/section-home_avantages.scss";
@import "sections/section-home_chiffres_cles.scss";
@import "sections/section-home_last.scss";
@import "sections/section-bottom_page.scss";

/* PAGES */

@import "pages/page-list.scss";
@import "pages/page-formulaire.scss";
@import "pages/page-map.scss";
@import "pages/page-contact.scss";
@import "pages/page-404.scss";
@import "pages/page-login.scss";
/* BLOCKS */

@import "blocks/block-sidebar_formulaire.scss";


/* LISTINGS */

@import "listings/listings-evenements.scss";
@import "listings/listings-actualites.scss";
@import "listings/listings-commissions.scss";
@import "listings/listings-dossiers.scss";
@import "listings/listings-moyens_actions.scss";
@import "listings/listings-documents.scss";
@import "listings/listings-user.scss";
@import "listings/listings-partenaires.scss";
@import "listings/listings-medias.scss";
@import "listings/listings-program.scss";
@import "listings/listings-search.scss";
@import "listings/listings-gallery.scss";

@import "listings/list-mots.scss";
@import "listings/list-feeds.scss";
@import "listings/list-accordion.scss";



/* TEASERS */

@import "teasers/teasers-evenements.scss";
@import "teasers/teasers-actualites.scss";
@import "teasers/teasers-commissions.scss";
@import "teasers/teasers-dossiers.scss";
@import "teasers/teasers-moyens_actions.scss";
@import "teasers/teasers-documents.scss";
@import "teasers/teasers-user.scss";
@import "teasers/teasers-petition.scss";
@import "teasers/teasers-modele_lettre.scss";
@import "teasers/teasers-search.scss";
@import "teasers/teasers-gallery.scss";

/* NODES */

@import "nodes/node-evenements.scss";
@import "nodes/node-actualites.scss";
@import "nodes/node-commissions.scss";
@import "nodes/node-dossiers.scss";
@import "nodes/node-moyens_actions.scss";
@import "nodes/node-base.scss";
@import "nodes/node-user.scss";
@import "nodes/node-programs.scss";

/* TBD */

@import "tbd/tbd-global.scss";
@import "tbd/tbd-sidebar.scss";
@import "tbd/tbd-content.scss";


@import "print.scss";


@import "temp/toronto.scss";

