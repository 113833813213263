#page_map{

	background: #ecedf2; 
	padding-bottom: 55px; 

	@include breakpoint(small down){
		padding-bottom: 0;
	}

	#page_map_banner{
		 padding-top: 80px;
		 max-width: 550px; 
		 margin: 0px auto; 
		 text-align: center;
		 margin-bottom: 75px;

		 @include breakpoint(small down){
			padding: 40px 20px; 
			margin-bottom: 0;

		 }
		 

		 h1{
			font-size: 36px;
			font-weight: $fwBold; 
			color: $muted-blue;
			margin-bottom: 25px;
			font-family: $ffT; 

			@include breakpoint(small down){
				font-size: 24px;
			}
		 }



		 p{
		 	font-size: 18px;
		 	color: #3d3d3d;

		 	@include breakpoint(small down){
				font-size: 16px;
		 	}
		 }
	}
	

	#map{
		@include breakpoint(small down){
			display: none; 
		}
	}
	
}