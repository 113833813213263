#node_dossier{

	position: relative;

	.tool_bar{
		position: absolute;
		top: 25px;
		right: 0;
	}

	.node_dossier_container{
		@include container(943px); 
		background: #fff; 
		
		margin-top: -25px;
		position: relative;
		z-index: 555; 
		
		.rte{
			padding: 70px 100px; 
		}

	}

}