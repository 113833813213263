.table_comittee{
	width: 100%; 
	border: solid 1px  #e1e1e1; 
	border-top: none;
	table-layout: fixed;

	tr{
		background: #fff;               
		td{
			padding: 15px 18px; 
			color: $black-two;
			line-height: 20px; 

			@include breakpoint(small down){
				padding: 10px; 
			}

			&.membre{
				font-size: 16px;
				font-weight: $fwSemiBold; 
				padding-left: 30px;
				border-right: solid 1px #e1e1e1;

				@include breakpoint(small down){
					padding-left: 10px; 
					font-size: 14px;
				}
			}

			&.fonction{
				padding-left: 25px;
				font-size: 14px;
			}
		}

		&:nth-child(2n){
			background: #ecedf2; 
		}
	}                  	
}