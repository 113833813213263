

.actualite{

	&_teaser{
		position: relative;

		.fav{
			position: absolute;
			top: 16px;
			z-index: 555;
			right: 30px;
			@include flag(#fff); 
			display: none; 
			
			@include tooltip; 

			.tooltip{
				left: -13px;
				top: 34px;
			}
		}
		
		.bg{
			display: block;
			background: #fff; 
			max-width: 300px; 
			margin: 0px auto;

			.img{
				
				@include imgHover; 
				border-bottom: solid 3px $tealish;
				position: relative;

				.cat{
					@include catTeaser;
					position: absolute;
					bottom: -3px;
					left: 0; 
			
				}

				
			}

			.detail{
				padding: 26px 35px 26px 20px; 
				border: solid 1px #dadbe2;
				border-top: 0px; 

				.date{
					font-size: 12.6px;
					font-weight: normal;
					line-height: normal;
					color: $muted-blue;
					margin-bottom: 15px;
				}

				h3{
					min-height: 80px; 
					font-size: 18px;
					font-weight: $fwSemiBold; 
					line-height: 26.49px;
					color: $black;
				}

			}

			&:hover{
				.img{
					@include imgHoverActive; 
				}
			}
		}
	}


	&_teaser_small{
		.bg{
			display: block;
			background-color: $turquoise-blue;
			padding: 21px  30px;

			&:hover{
				background: $mainColor; 
			}

			.date{
				font-size: 12px;
				color: $greenish-yellow;
				margin-bottom: 8px;
			}

			h3{
				font-size: 14px;
				font-weight: $fwSemiBold; 
				line-height: 20.92px;
				color: #ffffff;
				min-height: 60px; 
			}
		}

		&:not(:last-child){
			border-bottom: solid 1px $viridian;
		}
	}

}

.page-account-my-favourites{
	.actualite_teaser .fav{
		display: block; 
	}
}