.list-annuaire{

	margin-bottom: -23px !important;

	@include breakpoint(large up){
					
		@include row(22); 

		> li {

			@include grid-column(6,  22);
			margin-bottom: 23px; 
			&:nth-child(2n+1){
				clear: both; 
			}

		}
	}

	@include breakpoint(medium down){
		> li{
			margin-bottom: 23px; 
		}
	}


}


.list-annuaire-page{
	@include clearfix; 
	margin-bottom: -23px !important;

	@include breakpoint(medium up){
					
		@include row(22); 



		> li {

			@include grid-column(6,  22);
			margin-bottom: 23px; 

			&:nth-child(2n+1){
				clear: both; 
			}


		}
	}

	

	> li{
		margin-bottom: 23px;
		.bg{
			border: solid 1px #dadbe2; 
		}
	}


}