

.collapsible_block{

		

	h3{
		background: $muted-blue; 
		padding: 0px 30px;
		position: relative;
		height: 50px;
		line-height: 50px; 
		color: #fff; 
		font-size: 22px;
		font-weight: $fwSemiBold; 
		cursor: pointer; 
		@include transition(0.3s);

		&:hover{
			background: $turquoise-blue;
		}

		&:after{
			height: 10px;
			line-height: 10px; 
			@include font($zmdi-var-chevron-up); 
			position: absolute;
			@include rotate(180deg); 
			top: 50%;
			margin-top: -5px;
			right: 30px;
			color: #fff; 
			font-size: 30px;
			@include transition(0.3s);
		}

		&.active{
	
			&:after{
				@include rotate(0deg); 
			}
			
		}

		&.filter_title{
			&:before{
				@include font($zmdi-var-filter-list); 
				font-size: 22px;
				margin-right: 8px; 
			}
		}
	}

	.collapsible_block_content{
		padding: 40px 30px 45px;
		background: #ecedf2; 
	}

	

	
}