.list-documents-bottom{
	> li{
		padding-top: 35px;
		border-top: solid 1px #e1e1e1;

		&:not(:last-child){
			padding-bottom: 30px; 
		}
	}
}



.list-documents-tdb{
	> li{

		&:not(:first-child){
			padding-top: 25px;
			border-top: solid 1px #e1e1e1;
		}

		&:not(:last-child){
			padding-bottom: 20px; 
		}
	}
}


.list-document-perso-tdb{
	margin-bottom: -23px !important;
	> li{
		margin-bottom: 23px; 
	}
	@include breakpoint(xlarge up){
					
		@include row(22); 

		> li {

			@include grid-column(6,  22);

			&:nth-child(2n+1){
				clear: left; 
			}

		}
	}





	@include breakpoint(medium down){
		> li{

			width: 100%; 
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
	}
}