#node_commission{

	position: relative;

		.tool_bar{
			position: absolute;
			top: 0;
			right: 0;
			z-index: 55; 
		}

	.node_commission_container{
		@include container(743px); 
		background: #fff; 
		padding-bottom: 70px;
		

		

				
		position: relative;


		.rte{
			padding-top: 65px;

			@include breakpoint(small down){
				padding-top: 90px;
			}
		}

		
	}

	.tool_bar ul:not(.atcb-list) > li.favorite a .tooltip{
		left: auto; 
		right: 55px; 
		top: 0; 

		&:after{
			left: auto; 
			right: -4px; 
			border-width: 5px 0 5px 4px;
			border-color: transparent transparent transparent #edeef2;
			top: 50%; 
			margin-top: -5px;
		}
	}

}


