

.header_main_share_block{

	.tool_bar_horizontal{
		
		@include clearfix; 

		> ul{
			> li{
				float: left;
				padding-right: 20px; 
						border-right: solid #e1e1e1;

				&:not(:last-child){
					margin-right: 20px;
				}

				&.favorite{
						.tooltip{
							display: none; 
						}

						a{

							display: block; 

							&:before{
								content: "";
								@include font($zmdi-var-star-outline); 
								font-size: 22px;
								display: inline-block;
								vertical-align: middle;
							}

							&.flagged, &.unflag-action{
								&:before{
									@include font($zmdi-var-star); 
								}
							}

						}

						.flag-throbber{
							display: none !important; 
						}
					}

			
				a{
					width: auto; 
					height: auto; 
					background: none; 
					color: #3d3d3d; 

					height: 30px;
					line-height: 30px; 

					&:hover{
						background: none; 

						&:before{
							color: $turquoise-blue;
						}
					}
					

					&.flag{
						font-size: 14px;
						font-weight: $fwSemiBold; 
						text-transform: uppercase;
						
					}

					&.flag, &.share_show{
						&:before{
							margin-right: 12px;
						}
					}



					&.share_show{

						&:before{
							content: "";
							@include font($zmdi-var-share); 
							font-size: 22px;
							display: inline-block;
							vertical-align: middle;
						}




						span{
							text-indent: 4px; 
							display: inline-block;
							vertical-align: middle;

							&.hide{
								max-width: 0px; 
								overflow: hidden;
								@include transition(0.3s);
								white-space: nowrap;
							}
						}
					}



					&:before{
						color: $muted-blue;
					}
				}

				&.share{
					&:hover{
						.share_show{
							span{
								max-width: 120px; 
							}
						}

						.share_list{
							max-width: 500px; 
						}
					}
				}

				span{
					font-size: 14px;
					font-weight: $fwSemiBold; 
					text-transform: uppercase;
				}

				.share_show{
					float: left;
				}

				.share_list{
					max-height: 100%;
					float: left; 
					max-width: 0px; 
					white-space: nowrap;
					height: 31px; 
					overflow: hidden;
					@include transition(0.3s); 

					> li{
					display: inline-block;
						margin-left: 20px;

						a{
							font-size: 0px; 

							&:before{
								font-size: 20px; 
							}
						}

						&.mail{
							margin-left: 50px;
						}

						&.twitter a{
							&:before{
								@include font($zmdi-var-twitter); 
							}

							&:hover{
								background: $colorTwitter; 
								color:#fff;  
							}
						}
						&.facebook a{
							&:before{
								@include font($zmdi-var-facebook); 
							}
							&:hover{
								background: $colorFacebook; 
								color:#fff;  
							}
						}
						&.linkedin a{
							&:before{
								@include font($zmdi-var-linkedin); 
							}

							&:hover{
								background: $colorLinkedin; 
								color:#fff;  
							}
						}
						&.mail a{
							&:before{
								@include font($zmdi-var-email); 
							}
						}
						
					}
				}
			}

			
		}
	}
}

body.logged-in{
	#header_main .container > .block.header_main_share_block{
		margin-left: 30px; 
	}
}