.document{

	&_teaser, &_library{
		
		.bg{
			.top{
				@include clearfix; 
				margin-bottom: 20px;

				.left{
					float: left;

				

					.cat{
						@include catTeaser; 
						float: left;
						margin-right: 15px; 
					}

					.lang{
						float: left;
						font-size: 12px;
						font-weight: $fwSemiBold; 
						color: $tealish;
						height: 30px;
						line-height: 30px; 
					}
				}

				.right{
					float: right;
					
					.flag-wrapper{
						@include flag; 

						.flag{

							&:hover{
								.tooltip{
									opacity: 1; 
								}
							}
							.tooltip{
								opacity: 0; 
								@include transition(0.3s); 
								height: 51px;
								position: absolute;
								top: -14px;
								line-height: 45px; 
								border: solid 1px #dadbe2;
								right: 15px;
								background: #fff; 
								padding: 0px 12px; 
								font-size: 14px;
								color: #858585;
								white-space: nowrap;
								pointer-events: none; 

								&:before{
									content: "";
									position: absolute;
									width: 0;
									height: 0;
									border-style: solid;
									border-width: 7px 0 7px 7px;
									border-color: transparent transparent transparent #858585;
									right: -7px;
									top: 50%; 
									margin-top: -7px;
									opacity: 0.6;
									
								}

								&:after{
									content: "";
									position: absolute;
									width: 0;
									height: 0;
									border-style: solid;
									border-width: 6px 0 6px 6px;
									border-color: transparent transparent transparent #fff;

								
									right: -6px;
									top: 50%; 
									margin-top: -6px;
									
								}
									
							}
						}
					}
				}
			}

			.detail{
				@include clearfix; 

				.left{
					float: left;
					max-width: 60%; 
					> a{
						text-decoration: underline;
						color: $secondColor !important; 

						&:hover{
							color: $mainColor !important; 
						}
					}

					@include breakpoint(medium down){
						float: none; 
					}
				
					h3{
						color: $muted-blue; 
						font-size: 16px;
						font-weight: $fwBold; 
						margin-bottom: 13px; 
						line-height: 22px; 
						padding-right: 20px;
						
					}

					p{
						font-size: 16px;
						line-height: 22px; 
					}
				}

				.right{
					float: right;

					@include breakpoint(medium down){
						float: none; 
						margin-top: 20px;
					}

					.button_preview{
						float: left;
						margin-right: 13px; 

						@include buttonPreview(); 

						@include breakpoint(small down){
							float: left;
							width: 48%; 
							margin-right: 0;
						}
					}

					.button_download{
						@include buttonDownload(); 

						@include breakpoint(small down){
							width: 48%; 
							
							float: right;

							small{
								display: none; 
							}
						}
					}
				}
			}
		}

	}

	&_menu_teaser{
		.bg{
			display: block;
			background: #fff; 
			border-left: solid 2px $turquoise-blue; 
			padding: 10px 15px;

			&:hover{
				background: $turquoise-blue;

				h4, .infos{
					color: #fff;
				}
			}
			
			h4{
				color: $muted-blue;
				font-size: 12px;
				font-weight: $fwSemiBold; 
				margin-bottom: 10px;
				@include transition(0.3s); 
			}

			.infos{
				color: #858585;
				font-size: 12px;
				@include transition(0.3s); 
			}
		}
	}
}

.document_perso{

	&_teaser{
		.bg{
			background: #fff; 
			padding: 23px; 
			padding-bottom: 30px;
			position: relative;

			&.with_cat{
				padding-top: 55px;
			}

			.cat{
				@include catTeaser; 
				position: absolute;
				top: 0;
				left: 0;
			}

			.fav{
				position: absolute;
					top: 20px;
					right: 15px;
					display: none; 
			}

				.flag-wrapper{
					
						@include flag; 

						.flag{

							&:hover{
								.tooltip{
									opacity: 1; 
								}
							}
							.tooltip{
								opacity: 0; 
								@include transition(0.3s); 
								height: 51px;
								position: absolute;
								top: -14px;
								line-height: 45px; 
								border: solid 1px #dadbe2;
								right: 30px; 
								background: #fff; 
								padding: 0px 12px; 
								font-size: 14px;
								color: #858585;
								white-space: nowrap;
								pointer-events: none; 

								&:before{
									content: "";
									position: absolute;
									width: 0;
									height: 0;
									border-style: solid;
									border-width: 7px 0 7px 7px;
									border-color: transparent transparent transparent #858585;
									right: -7px;
									top: 50%; 
									margin-top: -7px;
									opacity: 0.6;
									
								}

								&:after{
									content: "";
									position: absolute;
									width: 0;
									height: 0;
									border-style: solid;
									border-width: 6px 0 6px 6px;
									border-color: transparent transparent transparent #fff;

								
									right: -6px;
									top: 50%; 
									margin-top: -6px;
									
								}
									
							}
						}
					}

			h3{
				color: $muted-blue;
				font-size: 16px; 
				font-weight: $fwBold; 
				margin-bottom: 25px; 
				line-height: 22px; 
				word-break: break-word;
				padding-right: 20px;
			}

			p{
				font-size: 16px;
				min-height: 43px; 
			}

			.button_download{
				@include buttonDownload(); 

				@include breakpoint(medium down){
					width: 100%; 
					margin-top: 20px;
				}
			}


		}
	}
}


.page-account-my-favourites{
	.document_perso_teaser .fav{
		display: block; 
	}
}