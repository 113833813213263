
.section_home_listings{
	padding-top: 50px;
	.container{
		@include container(945px); 
	}

	.top_section{
		@include clearfix; 
		margin-bottom: 40px; 

		@include breakpoint(medium down){
			margin-bottom: 30px;
		}
		h2{

			@include breakpoint(medium up){
				float: left;
				width: 65px;
			}
	
			span{
				float: right;
				text-align: right;
				white-space: nowrap;
				padding-bottom: 20px;
				font-family: $ffT;
				font-size: 30px;
				font-weight: $fwBold;
				color: $muted-blue;

				@media screen and (max-width: 1100px) and (min-width: 1024px) {
					float: left;
					text-align: left;
				}

				@include breakpoint(small down){
					text-align: center;
					float: none; 
					display: block;
					font-size: 24px;
					padding-bottom: 15px;
				}
			}

			&:after{
				content: "";
				width: 100%; 
				height: 5px;
				background-color: $lemon-green;
				display: block;
				clear: both; 

				@include breakpoint(small down){
					width: 65px;
					margin: 0px auto; 
				}
			}
		}


		a{
			float: right;
			font-size: 14px;
			font-weight: $fwSemiBold;
			text-decoration: underline;
			color: $muted-blue;
			margin-top: 10px;

			@include breakpoint(small down){
				display: none; 
			}

			&:hover{
				color: $secondColor; 
			}
		}
	}

	.see_all_link_mobile{
		display: none; 
		font-size: 14px;
		font-weight: $fwSemiBold;
		text-decoration: underline;
		color: $muted-blue;
		margin-top: 40px;
		text-align: center;
		
	

		&:hover{
			color: $secondColor; 
		}
		
		@include breakpoint(small down){
			display: block;
		}

	}
}


#section_home_actualites{

	background-color: $pale-grey;
	padding-bottom: 30px;

	

	.hp_news_row{

		@include breakpoint(large up){
			
			@include row(22); 

			.left{

				@include grid-column(8,  22);
			}

			.right{
				@include grid-column(4,  22);
			}

		}

		@include breakpoint(medium down){
			.left{
				margin-bottom: 22px;
			}
		}

		@include breakpoint(small down){
			.right{
				max-width: 300px; 
				margin: 0px auto; 
			}
		}
	}
	
}

#section_home_evenements{

	@include breakpoint(small down) {
		margin-bottom: 35px;
	}

	.hp_event_row{

		@include breakpoint(large up){
			
			@include row(30); 

			.left{

				@include grid-column(5,  30);
			}

			.right{
				@include grid-column(7,  30);
			}

		}

		@include breakpoint(medium down){
			.left{
				
				max-width: 380px; 
				margin: 0px auto;
				margin-bottom: 30px;		
			}
		}

		@include breakpoint(small down){
			.right{
			
			}
		}
	}
	
}

#section_home_dossiers{
	background-color: $pale-grey;
	padding-bottom: 85px;
}
