#sidebar_formulaire{

	 h2{
		color: $muted-blue;
		font-size: 20px;
		font-weight: $fwBold; 
		margin-bottom: 50px;
		text-transform: uppercase;
		line-height: 26px; 

		@include breakpoint(large up){
			pointer-events: none;
		}
	}

	@include breakpoint(medium  down){
		padding-bottom: 0 !important;
		padding-top: 40px !important;

		h2{
			margin-bottom: 40px;
			padding-right: 30px; 
			position: relative;

			&:after{
				@include font($zmdi-var-chevron-down); 
				position: absolute;
				top: 50%;
				margin-top: -13px;
				right: 0;
				font-size: 30px;
				@include rotate(0deg); 
				@include transition(0.3s);
			}

			&.active{

				&:after{
					@include rotate(180deg); 
				}
			}


		}
		.block_content{
			display: none; 
			padding: 10px; 
			padding-bottom: 35px; 

			
		}
	}

	@include breakpoint(large up){
		.block_content{
			display: block !important; 
		}
	}

	@include breakpoint(small  down){
		padding-left: 20px !important; 
		padding-right: 20px !important;
	}
	

	#sidebar_formulaire_text{
		ul{

			padding-left: 20px;
			border-left: dashed 1px #9cceda; 

			li{
				position: relative;

				&:first-child{
					position: relative;
					top: -8px;
					margin-bottom: 27px !important;
				}
				&:not(:last-child){
					margin-bottom: 35px;
				}

				font-size: 16px;
				line-height: 24px; 
				color: #636363; 

				strong{
					font-weight: $fwBold; 

					em{
						margin-right: 5px;
						font-size: 18px;
						color: $mainColor; 
						font-style: normal;
					}
				}

				a{
					color: $secondColor; 

					&:hover{
						text-decoration: underline;
					}
				}
				

				&:before{
					content: "";
					position: absolute;
					left: -24px;
					top: 8px;
					width: 7px;
					height: 7px;
					border-radius: 50%; 
					background:  $turquoise-blue; 
				}
			}
		}
	}
	

	#sidebar_formulaire_cart{
		display: none; 
		h3{
			font-size: 16px;
			color: #636363; 
			font-weight: $fwBold; 
			margin-bottom: 15px; 
		}

		ul{
			li{
				line-height: 15px; 
				&:not(:last-child){
					margin-bottom: 12px; 
				}

				@include clearfix; 

				span{
					float: left;
				}

				.delete_item{
					float: right;
					&:after{
						@include font($zmdi-var-close);
						font-size: 20px;
						color: #343434;
						height: 15px;
						line-height: 15px;

						&:hover{
							color: $red; 
						}
					}
				}

				font-size: 14px; 
				color: #636363; 
			}
		}

		.cart_items{
			margin-top: 25px; 
			padding-top: 25px;
			border-top: solid 1px #cacaca; 
		}

		.cart_total{
			margin-top: 40px;
			padding-top: 20px; 
			@include clearfix; 
			border-top: solid 2px $turquoise-blue; 
			font-size: 20px;
			font-weight: $fwBold; 
			color: $turquoise-blue;
			.label{
				float: left;
			}

			.price{
				float: right;
			}
		}

		.cart_buttons{
			margin-top: 85px; 

			a{
				display: block; 

				&:not(:last-child){
					margin-bottom: 15px;
				}

				text-align: center;
				height: 60px;
				line-height: 60px; 
				background: #dadbe2;
				font-size: 16px; 
				font-weight: $fwSemiBold; 
				color: #3d3d3d; 

				&:hover{
					background: $turquoise-blue; 
					color: #fff; 
				}

			}
		}
	}

}