.node_program{
	.go_back_link{
		margin-bottom: 30px;
		display: inline-block;
		height: 45px;
		line-height: 45px; 
		background: #edeef2; 
		color: #858585;

		padding-left: 30px;
		padding-right: 15px; 
		font-size: 14px;
		position: relative;
		text-decoration: underline;
		font-weight: $fwSemiBold; 

		&:hover{
			color: $muted-blue;
		}

		&:before{
			@include font($zmdi-var-chevron-left);
			color: $muted-blue;
			height: 45px; 
			line-height: 48px; 
			position: absolute;
			top: 0;
			left: 15px; 

		}

	}
	.top_presentation{
		@include clearfix; 
		background: $muted-blue; 
		padding: 50px 40px;       
		display: flex;
		align-items: center; 
		justify-content: center; 
		margin-bottom: 55px;

		@include breakpoint(medium down){
			display: block; 
		}

		@include breakpoint(small down){
			padding: 30px;
		}

		h2{
			font-family: $ffT; 
			color: #fff; 
			font-size: 26px;
			font-weight: $fwBold; 
			line-height: 39px; 
			border-right: solid 1px rgba(#fff, 0.29); 
			padding-right: 40px;

			@include breakpoint(medium down){
				padding-right: 0;
				border-bottom: solid 1px rgba(#fff, 0.29); 
				padding-bottom: 30px;
				border-right: 0; 
				margin-bottom: 30px;
			}

			@include breakpoint(small down){
				padding-bottom: 15px;
				margin-bottom: 15px;
				font-size: 20px;
				line-height: 25px; 
			}
		}        

		.infos{
			color: #fff; 
			
			padding-left: 30px;
			@include breakpoint(medium down){
				padding-left: 0;
			}
			li{
				padding-left: 20px; 
				position: relative;
				line-height: 20px; 
				&:not(:last-child){
					margin-bottom: 20px; 
				}
				font-size: 16px; 

				i{
					font-style: italic; 
				}

				&:before{
					width: 20px; 
					position: absolute;
					left: 0;
					top: 0;
				}
			

				&.date{
					&:before{
						@include font($zmdi-var-calendar-alt); 

					}
				}

				&.location{
					&:before{
						@include font($zmdi-var-pin); 
						
					}
				}
			}
		}
	}

	.block_event{
		margin-bottom: 0 !important;
	}

	.rte{
		padding-bottom: 0;
		margin-bottom: 0;
		p{
			font-size: 18px;
				line-height: 30px;
		}
		ul{
			padding-left: 0;
			> li{
				padding-left: 0;
				font-size: 18px;
				line-height: 30px;

				&:before{
					display: inline-block;
					position: relative;
					top: -4px;
					margin-right: 10px;
				} 
			}
		}
	}

	.block_spearkers{
		&:not(:last-child){
			margin-bottom: 40px; 
		}

		> h3{
			margin-bottom: 30px;
			height: 25px;
			line-height: 25px; 
			padding-left: 15px;
			border-left: solid 4px $lemon-green; 
			color: $muted-blue;
			font-size: 22px; 
			font-weight: $fwBold; 
			margin-bottom: 30px;
		}
	}
}