.list-commissions-page{

	
	> li{
		margin-bottom: 23px; 
	}
	
	@include breakpoint(xlarge up){
					
		@include row(22); 

		> li {

			@include grid-column(4,  22);

		}
	}



	@media screen and (max-width: 1366px) {
					
		@include row(22); 

		> li {

			@include grid-column(6,  22);

			

		}
	}

	@include breakpoint(small down){
		> li{

			width: 100%; 
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
	}

}


.list-mycommissions-page{

	
	> li{
		margin-bottom: 23px; 
	}
	
	@include breakpoint(large up){
					
		@include row(22); 

		> li {

			@include grid-column(4,  22);

		}
	}



	@include breakpoint(medium down){
					
		@include row(22); 

		> li {

			@include grid-column(6,  22);


		}
	}

	@include breakpoint(small down){
		> li{

			width: 100%; 
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
	}

}

.list-commissions-tbd{
	margin-bottom: -23px !important;
	> li{
		margin-bottom: 23px; 
	}
	@include breakpoint(xlarge up){
					
		@include row(22); 

		> li {

			@include grid-column(4,  22);

		}
	}



	@media screen and (max-width: 1366px) {
					
		@include row(22); 

		> li {

			@include grid-column(6,  22);

			

		}
	}

	@include breakpoint(small down){
		> li{

			width: 100%; 
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
	}
}