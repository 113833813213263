//colors
$color_gravel_approx: #42454a;
$black: #000;
$color_tapa_approx: #737373;
$color_pumice_approx: #c5c5c5;
$color_alto_approx: #ddd;
$color_wild_sand_approx: #f5f5f5;
$color_bon_jour_approx: #dfdfdf;
$color_gallery_approx: #eee;
$color_celeste_approx: #cdcdcd;
$white: #fff;
$color_frost_approx: #ecf3dc;
$color_silver_chalice_approx: #aaa;
$color_sulu_approx: #cdeb8b;
$color_lochmara_approx: #0074bd;

//fonts
$font_0: sans-serif;
$font_1: Lucida Grande;
$font_2: Verdana;

//urls
$url_0: url(images/up_arrow_disabled.png);
$url_1: url(images/add.png);
$url_2: url(images/transp50.png);

$url_4: url(images/delete.gif);
$url_5: url(images/error.gif);
$url_6: url(images/done.gif);
$url_7: url(images/up_arrow.png);

//@extend-elements
//original selectors
//.plupload_disabled, a.plupload_disabled:hover
%extend_1 {
  cursor: default;
  color: $color_tapa_approx;
  background: transparent $url_0 no-repeat 0 center;
  border-color: $color_pumice_approx;
}

//original selectors
//.plupload_filelist_header, .plupload_filelist_footer
%extend_2 {
  background: $color_bon_jour_approx;
  color: $color_gravel_approx;
  padding: 6px 8px;
}

//original selectors
//.plupload_clearer, .plupload_progress_bar
%extend_3 {
  display: block;
  font-size: 0;
  line-height: 0;
}

//original selectors
//.plupload_button, .plupload_button:hover
%extend_4 {
  color: $color_lochmara_approx;
  padding-bottom: 0;
  margin-bottom: 0;
}






.plupload_wrapper {
  font: normal 11px $font_2, $font_0;
  width: 100%;
  font-family: $font_1, $font_2, $font_0;
}
.plupload_container {
  background: $url_2;
  input {
    border: 1px solid $color_alto_approx;
    font: normal 11px $font_2, $font_0;
    width: 98%;
    font-family: $font_1, $font_2, $font_0;
  }
}
.plupload_scroll {
  .plupload_filelist {
    min-height: 70px;
   
    background: none;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .plupload_filelist_header .plupload_file_action {
    margin-right: 17px;
  }
  .plupload_filelist_footer .plupload_file_action {
    margin-right: 17px;
  }
}
.plupload_filelist {
  li {
   
    
    padding: 10px 10px;
    font-size: 16px;

    &:nth-child(2n){
      background: $pale-grey; 
    }
  }
  .plupload_file_name {
    width: 90%;
  }
}
.plupload_filelist_header {
  display: none ; 
  
}
.plupload_filelist_footer {
  
  .plupload_file_name{
    float: none; 

  }

  .plupload_file_action{
    display: none; }

  .plupload_buttons{
    @include clearfix; 
    padding-top: 10px;
    display: block !important; 

    a{
      float: left;
      width: 49%; 
      height: 40px; 
      line-height: 40px; 
      color: #fff; 
      font-size: 16px; 
      color: #fff;
      text-align: center;
      border-radius: 0px; 
      padding: 0px; 
      border: solid 1px; 

      &:before{
        margin-right: 10px;
      }

      &.plupload_add{
        background: $turquoise-blue; 
        border-color:  $turquoise-blue; 

         &:before{
          @include font($zmdi-var-plus); 
          font-size: 16px;
        }

         &:hover{
            color: $turquoise-blue; 
            background: #fff; 
          }

      }

      &.plupload_start{

        background: $muted-blue; 
        border-color: $muted-blue;

        &:before{
          @include font($zmdi-var-upload); 
          font-size: 16px;
        }

         &:hover{
            color: $muted-blue;
            background: #fff; 
          }
        
        &.plupload_disabled{
          pointer-events: none; 
          opacity: 0.5; 
        }
      }


      &:last-child{
        float: right; 
      }
    }
  }
}
.plupload_file_name {
  float: left;
  overflow: hidden;

}
.plupload_file_status {
  color: $color_tapa_approx;
  float: right;
  width: 80px;
  text-align: right;
  display: none; 
  span {
    color: $color_gravel_approx;
  }
}
.plupload_file_size {
 display: none; 
}
.plupload_progress {
  float: right;
  width: 80px;
  display: none;
  display: none; 
}
.plupload_file_action {
  text-align: right;
  float: right;
  width: 16px;
  height: 16px;
  margin-left: 15px;
 
  * {
    display: none;
    width: 16px;
    height: 16px;
  }
}
li {
  
  &.plupload_done {
    color: $color_silver_chalice_approx;
    a {
    
      cursor: default;
      
       &:before{
        @include font($zmdi-var-check-circle); 
        font-size: 16px;
        color: $green; 
      }

    }
  }
  &.plupload_droptext {
    background: $pale-grey;
    text-align: center;
    vertical-align: middle;
    border: 0;
    line-height: 50px;
    list-style-type: none;
    font-size: 16px;
    font-weight: $fwBold; 
    color: $muted-blue;
  }
  &.plupload_delete a {
    &:before{
      @include font($zmdi-var-delete); 
      font-size: 16px;
      color: $red; 
    }
  }
  &.plupload_failed a {
     &:before{
          @include font($zmdi-var-alert-circle); 
          font-size: 16px;
          color: $red; 
      }    
      cursor: default;
  }
}
.plupload_upload_status {
  display: none !important;
}
.plupload_progress_container {
  margin-top: 3px;
  border: 1px solid $color_celeste_approx;
  background: $white;
  padding: 1px;
  display: none; 
}
.plupload_progress_bar {
  width: 0;
  height: 7px;
  background: $color_sulu_approx;
  @extend %extend_3;
  display: none; 
}
.plupload_clear {
  clear: both;
}
.plupload_clearer {
  clear: both;
  @extend %extend_3;
}
.plupload {
     border: solid 1px #bfbfbf; 
     padding: 10px; 
}
.plupload_header {
  display: none;
}
