#sidebar_filter {

    >form,
    .form {
        padding-left: 46px;
        padding-right: 46px;

        @include breakpoint(small down) {
            padding-left: 22px;
            padding-right: 22px;
        }

        .form-wrapper,
        .block-facetapi {
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: solid 1px #dadbe2;
            padding-left: 46px;
            padding-right: 46px;
            margin-left: -46px;
            margin-right: -46px;

            @include breakpoint(small down) {
                padding-left: 22px;
                padding-right: 22px;
                margin-left: -22px;
                margin-right: -22px;
            }

            >legend {
                text-transform: uppercase;
                display: block;
                margin-bottom: 20px;
                color: $muted-blue;
                font-size: 16px;
                font-weight: $fwSemiBold;
            }

            .form-item{
                &:not(:last-child){
                    margin-bottom: 30px;
                }
            }

            .form-item,
            .block_inside {
                position: relative;
                >label,
                >h2 {
                    text-transform: uppercase;
                    display: block;
                    margin-bottom: 20px;
                    color: $muted-blue;
                    font-size: 16px;
                    font-weight: $fwSemiBold;
                }

                select,
                input[type=text] {
                    display: block;
                    width: 100%;
                    appearance: none;
                    background: #fff;
                    height: 45px;
                    border: none;
                    outline: none;
                    padding-left: 15px;
                    border-radius: 0px;
                    cursor: pointer;
                    color: #858585;
                    font-size: 13px;
                    border-radius: 0px;
                }
            }

            .autocomplete-results {
                position: absolute;
                width: 100%;

                z-index: 9999;
                li {
                    padding: 10px 20px;
                    background: $turquoise-blue;
                    color: #fff;
                    font-size: 13px;
                    cursor: pointer;
                    @include transition(0.3s);

                    &:hover {
                        background: $muted-blue;
                    }
                }
            }

            &#edit-thematic {

                >.form-item {
                    label {
                        position: relative;
                        cursor: pointer;

                        &:hover {
                            color: $turquoise-blue;
                        }
                        &:after {
                            @include font($zmdi-var-chevron-up);
                            position: absolute;
                            right: 0;
                            top: 0;
                            @include rotate(0);
                            @include transition(0.3s);
                        }

                        &.active {
                            &:after {
                                @include rotate(180deg);
                            }
                        }
                    }
                }
            }

            &#edit-thematic,
            &#edit-date {

                .fieldset-wrapper {

                    >.form-item,
                    >.container-inline-date {

                        &:not(:last-child) {
                            margin-bottom: 16px;
                        }
                        label {
                            text-transform: capitalize;
                            margin-bottom: 9px;
                            font-size: 14px;
                            font-weight: $fwSemiBold;
                        }
                    }
                }
            }

            &#edit-date {
                .form-type-textfield {

                    position: relative;

                    &:after {
                        @include font($zmdi-var-calendar-alt);
                        color: $tealish;
                        font-size: 20px;
                        height: 45px;
                        line-height: 45px;
                        position: absolute;
                        top: 0;
                        right: 15px;
                        pointer-events: none;
                    }
                }
            }
        }



        .form-submit,
        .reset_link a {
            height: 50px;
            line-height: 50px;
            width: 100%;
            display: block;
            color: #fff;
            background: $muted-blue;
            padding: 0;
            border: none;
            text-align: center;
            font-size: 16px;
            font-weight: $fwSemiBold;
            font-family: $ffP;
            cursor: pointer;


            &:hover {
                background: $turquoise-blue;
            }



            &#edit-reinit {
                background: #dadbe2;
                color: $black-two;
                margin-top: 25px;



                &:hover {
                    background: $black-two;
                    color: #dadbe2;
                }
            }
        }

        .reset_wrapper {
            position: relative;
            &:before {
                @include font($zmdi-var-close);
                font-size: 14px;
                height: 50px;
                line-height: 50px;
                position: absolute;
                top: 0;
                left: 40px;
                pointer-events: none;
                @include transition(0.3s);
            }

            &:hover {
                &:before {
                    color: #fff;
                }
            }
        }

        .reset_link a {
            background: #dadbe2;
            color: $black-two;
            margin-top: 25px;

            &:before {
                @include font($zmdi-var-close);
                font-size: 14px;
                margin-right: 7px;
            }

            &:hover {
                background: $black-two;
                color: #dadbe2;
            }
        }
    }
}


.top_filter_container {

    max-width: 100%;
    margin: 0px auto;
    padding: 23px 0px;

    @include breakpoint(small down) {
        border-top: solid 1px #fff;
        margin-top: 10px;
    }

    form {
        text-align: center;
        position: relative;
        padding-right: 42px;
        display: inline-block;

        @include breakpoint(small down) {
            display: block;
            max-width: 90%;
            margin: 0px auto;
            padding-right: 0px;
        }

        #autocomplete,
        #autocomplete-member {
            position: absolute;

            top: 40px;
            left: 0;
            padding-left: 22px;
            margin-left: 1px;
            background: #fff;
            line-height: 30px;
            padding-top: 15px;
            cursor: pointer;
            padding-right: 20px;
            padding-bottom: 15px;
            z-index: 5555;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);

            li {
                &:first-child {
                    display: none;
                }
            }
        }

        #autocomplete-member {
            width: 720px;
            right: 42px;
            margin-top: 5px;
            left: auto;
            display: none;

            @media only screen and (max-width: 1200px) {
                width: 500px;
            }

            @include breakpoint(medium down) {
                width: 290px;
            }


            @include breakpoint(small down) {
                width: auto;
                left: 0;
                position: relative;
                top: 0;
            }

            &.active {
                display: block;
            }

            li:first-child {
                display: block;
            }
        }

        .form-item {
            label {
                display: inline-block !important;
                vertical-align: middle;
                font-size: 18px;
                color: #fff;
                font-weight: $fwSemiBold;
                margin-right: 30px;

                @include breakpoint(small down) {
                    margin-bottom: 15px;
                    margin-right: 0;
                }
            }
            input {
                display: inline-block;
                background: #fff;
                height: 42px;
                padding: 0;
                padding-left: 22px;
                font-size: 14px;
                max-width: 100%;
                width: 720px;
                border-radius: 0px;
                border: none;

                @media only screen and (max-width: 1200px) {
                    width: 500px;
                }

                @include input-placeholder {
                    color: #858585;
                }

                &:focus {
                    @include input-placeholder {
                        color: #d2cfcf;
                    }
                }

                @include breakpoint(medium down) {
                    width: 290px;
                }

                @include breakpoint(small down) {
                    width: 100%;
                }
            }
        }

        .form-actions {
            position: absolute;
            bottom: 0;
            right: 0;

            @include breakpoint(small down) {
                width: 100%;
                left: 0;
                position: relative;
                margin-top: 5px;

                input {
                    width: 100% !important;
                }

                &:after {
                    right: 50% !important;
                    margin-right: -10px;
                }
            }

            &:after {
                content: "";
                @include pictos-loupeBlanche;
                position: absolute;
                top: 10px;
                right: 11px;
                pointer-events: none;
            }

            input {
                font-size: 0;
                width: 42px;
                height: 42px;
                background: $turquoise-blue;
                padding: 0px;
                appearance: none;
                border: none;

                &:hover {
                    background: $dark-slate-blue;
                }
            }
        }
    }
}

.page_list_search {

    .top_filter_container {

        padding: 60px 0px;

        form .form-item label {
            font-size: 35px;
            font-weight: $fwBold;
            font-family: $ffT;
        }
    }
}