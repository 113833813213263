
#page_list{
	position: relative;
	background: #ecedf2;

	.apachesolr-browse-blocks{
		display: none;
	}

	@include breakpoint(medium down){
		background: #fff;
	}

	@include fillBackgroundRight(#fff);

	#top_list_page{
		background: $muted-blue;
		text-align: center;
		position: relative;
		z-index: 55;

		@include breakpoint(small down){

				overflow: scroll;
				min-height: 60px;
		}



		.submenu-bar{
			display: inline-block;
			font-size: 0;
			@include clearfix;

			@include breakpoint(small down){
				display: flex;
				padding-left: 20px;

			}

			> li{
				float: left;

				@include breakpoint(small down){
					float: none;
					display: block;
					white-space: nowrap;
				}

				&:not(:last-child){
					margin-right: 40px;
				}

				a{
					height: 60px;
					line-height: 60px;
					font-weight: $fwSemiBold;
					color: #fff;
					font-size: 14px;
					text-transform: uppercase;
					display: block;
					margin-bottom: -2px;
					position: relative;

					&:after, &:before{
						content: "";
						width: 20px;
						height: 100%;
						position: absolute;
						top: 0;
						right: -20px;
						background:  $tealish-two;
						opacity: 0;
						@include transition(0.2s);
					}

					&:before{
						right: auto;
						left: -20px;
					}


					&:not(.active):hover{
						background:  $tealish-two;

						&:after, &:before{
							opacity: 1;
						}
					}

					&.active{
						font-weight: $fwBold;
						border-bottom: solid 3px $tealish-two;
					}
				}
			}
		}
	}

	#top_list_page + ul{
		display: none;
	}

	#open_filter_mobile{
		display: none;
		position: relative;
		z-index: 50;

		@include breakpoint(medium down){
			display: block;
			@include clearfix;
			background: $muted-blue;
			padding: 22px 46px;
			color: #fff;

			@include breakpoint(small down){
				padding: 22px;
			}

			span{
				float: left;
				font-size: 18px;
				font-weight: $fwSemiBold;
				text-transform: uppercase;
			}

			i{
				float: right;
				@include rotate(0deg);
				@include transition(0.3s);

				&:after{
					@include font($zmdi-var-chevron-down);
					font-size: 26px;
					line-height: 14px;

				}
			}

			&.active{
				i{
					@include rotate(180deg);
				}
			}
		}


	}

	.page_list_container{
		position: relative;
		z-index: 50;

		@include clearfix;

		@include breakpoint(xlarge up){
			@include container(1366px);
		}

		@media screen and (max-width: 1366px) {

			@include container(1024px);

			padding: 0px !important;
		}



		#sidebar_filter{
			float: left;
			background: #ecedf2;
			width: 330px;
			padding-top: 55px;
			padding-bottom: 30px;

			@media screen and (max-width: 1366px) {
				width: 304px;
			}

			@include breakpoint(large up){
				display: block !important;
			}

			@include breakpoint(medium down){
				width: 100%;
				padding-top: 30px;
				display: none;
			}

			.see_previous_container{
				text-align: center;
				padding-top: 35px;

				.see_previous{
					display: inline-block;
					@include seePrevious;
				}
			}
		}

		#content_list{
			background: #fff;
			width: 1036px;
			float: right;
			padding: 45px;
			padding-bottom: 55px;
			min-height: 520px;

			@include breakpoint(medium down){
				padding-top: 35px;
				padding-left: 0;
				padding-right: 0;
			}

			@include breakpoint(small down){
				padding-left: 20px;
				padding-right: 20px;
			}


			&.content_list_commissions{
				min-height: 635px;
			}

			&.content_list_annuaire{
				min-height: 1210px;
			}

			&.content_list_search{
				min-height: 1300px;
			}

			&.content_list_event{
				min-height: 800px;
			}



			@media screen and (max-width: 1366px) {
				width: 720px;
			}

			@include breakpoint(medium down){
				width: 100%;
			}



		}



	}

	&.page_list_mycommissions{
		 &:after{
			display: none;
		}

		background: #ecedf2 !important;

		.page_list_container{
			@include container(945px)

			#content_list{
				background: none;
				float: none;
				padding-left: 0;
				padding-right: 0;
				width: 100% !important;

				#top_listing{

					#results_text{
						float: none;
						text-align: center;

					}
				}
			}
		}
	}

}

#no_result_section{

	background: #fff;
	padding: 50px 0px;
	position: relative;
	z-index: 55;

	.no_result_section_container{
		@include container(950px);
		color : #858585;

		h2{
			font-size: 24px;
			font-style: italic;
			font-family: $ffT;
			margin-bottom: 40px;
			font-weight: $fwBold;
		}

		ul{
			li{

				padding-left: 13px;
				position: relative;
				font-size: 18px;
				line-height: 22px;

				a{
					color: $viridian;
					font-weight: $fwBold;
					text-decoration: underline;

					&:hover{
						color: $muted-blue;
					}
				}
				em{
					font-style: italic;
				}
				&:before{
					content: "";
					width: 5px;
					height: 5px;
					border-radius: 50%;
					background: $aqua-blue;
					position: absolute;
					top: 9px;
					left: 0;

				}

				&:not(:last-child){
					margin-bottom: 20px;
				}
			}
		}
	}
}

#results_text{

		height: 36px;
		line-height: 36px;
		font-family: $ffT;
		font-size: 18px;
		font-weight: $fwBold;
		font-style: italic;
		color: #858585;
		
		@include breakpoint(small down){
			font-size: 15px;
			text-align: center;
			float: none !important;
			height: auto; 
			line-height: 28px;  
		}

	}


#top_listing{
	@include clearfix;
	margin-bottom: 55px;

	@include breakpoint(small down){
		margin: 0px auto;
		margin-bottom: 35px;
		max-width: 300px;

	}

	#results_text{
		float: left;

	}

	#sorting-by{
		float: right;

		position: relative;

		&:before{
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 4.5px 0 4.5px;
			border-color: $muted-blue transparent transparent transparent;
			position: absolute;
			top: 16px;
			right: 13px;
			pointer: none;
		}

		select{
			height: 35px;
			padding-left: 17px;
			padding-right: 30px;
			font-size: 14px;
			border-radius: 0;
			background: #fff;
			border: solid 1px #ecedf2;
			color: #3d3d3d;
			outline: none;
			display: block;
			appearance: none;
		}
	}
}
