.dialog,
.dialog__overlay {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.dialog {
	position: fixed;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
	pointer-events: none;
	z-index: 1;
}

.dialog__overlay {
	position: absolute;
	z-index: 1;
	background: rgba(0, 0, 0, 0.4);
	opacity: 0;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
	-webkit-backface-visibility: hidden;
}

.dialog--open{
 	.dialog__overlay {
		opacity: 1;
		pointer-events: auto;
	}

	
}

.dialog__content {
	width: 50%;
	max-width: 560px;
	min-width: 290px;
	background: #fff;
	padding: 4em;
	text-align: center;
	position: relative;
	z-index: 1000;
	opacity: 0;

	@include breakpoint(medium down){
		width: 80%; 
	}

	@include breakpoint(medium down){
		width: 90%; 
	}

	max-height: 90%;
    overflow: auto;
}

.dialog--open .dialog__content {
	pointer-events: auto;
}

@import "dialog-sally.scss"; 

/*
@import "dialog-susan.scss"; 
@import "dialog-sally.scss"; 
@import "dialog-annie.scss"; 
@import "dialog-cathy.scss"; 
@import "dialog-dean.scss"; 
@import "dialog-don.scss"; 
@import "dialog-donna.scss"; 
@import "dialog-henry.scss"; 
@import "dialog-jamie.scss"; 
@import "dialog-jim.scss"; 
@import "dialog-ken.scss"; 
@import "dialog-laura.scss"; 
@import "dialog-ricky.scss"; 
@import "dialog-sally.scss"; 
@import "dialog-sandra.scss"; 
@import "dialog-susan.scss"; 
@import "dialog-val.scss"; 
@import "dialog-wilma.scss";
*/

