#page_login{

	
	background: #ecedf2;
	position: relative;

	@include breakpoint(large up){

		@include fillBackgroundRight($tealish-two); 

	}

	.page_login_section{
		
		float: left;
		width: 50%; 
		padding-top: 75px;
		padding-bottom: 80px;

		@include breakpoint(medium down){
			width: 100%; 
			float: none; 
		}

		@include breakpoint(small down){
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 40px;
			padding-bottom: 40px;
		}



		.inside{
			max-width: 350px; 

			@include breakpoint(large up){
				min-height: 390px;
			}
			
			@include breakpoint(medium down){
				margin: 0px auto; 
			}

			h2{
				font-family: $ffT; 
				font-size: 24px;
				margin-bottom: 35px;
				font-weight: $fwBold; 
				line-height: 33px; 
			}
		}

		&#page_login_left{
			background: #ecedf2;
			position: relative;
			z-index: 55; 
			.inside{

				@include breakpoint(large up){
					float: right; 
					margin-right: 180px; 
				}

				@include breakpoint(large only){
					margin-right: 100px; 
				}


				h2{
					color: $muted-blue;
				}
			}
		}

		&#page_login_right{
			background: $tealish-two; 
			position: relative;
			z-index: 55; 
			.inside{

				@include breakpoint(large up){
					float: left; 
					margin-left: 180px; 
				}
				color: #fff; 

				@include breakpoint(large only){
					margin-left: 100px; 
				}

				

				h2{
					color: #fff;
				}
			}
		}

		.text{
			color: #fff; 
			p{
				margin-bottom: 25px; 
				font-size: 16px;
				line-height: 24px; 
				color: #fff; 
			}

			ul{
				li{
					color: #fff;
					font-size: 16px;
					&:not(:last-child){
						margin-bottom: 15px; 
					}
					&:before{
						content: "·";
						margin-right: 8px;
					}
				}
			}
		}

		.button{
			height: 55px;
			background-color: $muted-blue;
			line-height: 55px; 
			border: none; 
			padding: 0;
			display: block;
			color: #fff; 
			font-size: 16px; 
			text-align: center;
			width: 100%; 
			cursor: pointer; 
			margin-top: 60px;
			
			&:hover{
				background: $turquoise-blue;
			}
		}
	}


	form{
		
			position: relative;

			.description{
				display: none; 
			}

			.form-item {
				position: relative;
				margin-bottom: 35px; 

				&.form-item-pass{
					margin-bottom: 25px;
				}

				&.form-item-remember-me{
					margin-bottom: 30px;
				}
			}

			&:not(:last-child){
				margin-bottom: 30px;
			}

			.placeholder{
				display: none; 
			}

			.label_link{
				position: absolute;
				right: 0;
				top: 0;
				font-size: 12px;
				font-weight: 600;
				color: #3d3d3d;
				text-decoration: underline;

				&:hover{
					color: $muted-blue;
				}
			}

			label{
				font-size: 14px;
				font-weight: $fwBold;
				color: $black;
				margin-bottom: 10px; 
				display: block;
			}

			input.form-text{
				height: 40px;
				width: 100%; 
				border: none; 
				background: #fff; 
				padding-left: 15px; 
				display: block;
				font-size: 14px;
				color: $black; 

				@include input-placeholder{
					color: #858585; 
				}

				&:focus{
					@include input-placeholder{
						color: $pale-grey; 
					}
				}
			}
		}

		.form-type-checkbox{
			input{
				display: none; 
			}

			label{
				font-size: 14px;
				color: #3d3d3d;
				position: relative;
				height: 16px;
				line-height: 16px; 
				padding-left: 23px;
				display: block;
				cursor: pointer; 

				&:before{
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 16px;
					height: 16px;
					border: solid 1px #858585;
					font-size: 14px;
					text-align: center;
					color: $tealish-two;
				}
			}

			input:checked + label{

				&:before{
					@include font($zmdi-var-check); 
				}
			}
		}

		.form-actions{
			margin-top: 30px;

			.form-submit{
				height: 55px;
				background-color: $muted-blue;
				line-height: 55px; 
				border: none; 
				padding: 0;
				display: block;
				color: #fff; 
				font-size: 16px; 
				text-align: center;
				width: 100%; 
				cursor: pointer; 
				
				&:hover{
					background: $turquoise-blue;
				}
			}
		}
	
}