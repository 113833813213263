/* COULEURS */

$black: #333333;
$black-two: #303030;

$green: 	#4ad585; 
$red: 		#fb5762;


$muted-blue: #365fa0;
$turquoise-blue: #00a1b4;
$tealish: #23a0b7;
$lemon-green: #bbf002;
$pale-grey: #ecedf2;
$greenish-yellow: #c6ff00;
$tealish-two: #249db6;
$dark-slate-blue: #244373;
$silver: #d7d8de;
$brownish-grey: #646464;

$pale-grey-two: #edeef2;

$viridian: #218291;
$aqua-blue: #00c9e1;



$greyBG : 	$pale-grey; 

$mainColor: $muted-blue; 
$secondColor: $turquoise-blue;

$bodyBG : #fff;

/* COULEURS RESEAUX SOCIAUX */

$colorFacebook : 	#3b5998; 
$colorTwitter : 	#00aced;
$colorInstagram : 	#125688; 
$colorYoutube : 	#bb0000; 
$colorGoogle : 		#dd4b39; 
$colorVimeo : 		#4bf; 
$colorLinkedin : 	#0077b5; 
$colorYoutube : 	#dd2c28; 
$colorWeibo : 		#fd8149; 
$colorPinterest: 	#cb2026; 
$colorSkype : 		#00aff0; 

/* FONT WEIGHT */

$fwThin: 		100;
$fwExtraLight : 200; 
$fwLight : 		300; 
$fwNormal : 	400;               
$fwMedium : 	500;
$fwSemiBold : 	600;
$fwBold : 		700;  
$fwExtraBold : 	800; 
$fwUltraBold : 	900;



/* FONT FAMILY */

$ffT: 'Merriweather', serif;
$ffP: 'Open Sans', sans-serif;
$ffDroid : 'Droid Serif', sans-serif;
$ffIcon : 'fontawesome';





