#header{
	@include breakpoint(medium down){
		height: 156px;
	}

	@include breakpoint(small down){
		height: 120px; 
	}
}


#header_mobile{
	display: none; 
	
	width: 100%; 
	position: absolute;
	top: 36px;
	left: 0;
	z-index: 9554;

	.headroom--unpinned{
		z-index: 8888;
	}

	@include breakpoint(small down){
		top: 0;
	}

	&:not(.headroom--top){
		position: fixed; 
		top: 0;

		.top{
			padding-top: 7px;
			padding-bottom: 5px; 

			#logo_mobile{
				img{
					height: 48px; 
					width: auto; 
					image-rendering: -webkit-optimize-contrast;
					image-rendering: auto;
				}
			}

			.hamburger{
				margin-top: 4px; 
			}

			.scroll_top{
				display: block; 
			}
		}
	}

	&.headroom {
	    will-change: transform;
	    transition: transform 200ms linear;
	}

	


	&.headroom--pinned {
	    transform: translateY(0%);
	}
	&.headroom--unpinned {
	    transform: translateY(-100%);
	}

	@include breakpoint(medium down){
		display: block;
	}
	
	.top{
		background: #fff; 
		padding: 20px 40px; 
		border-bottom: solid 1px #d0d1d6;
		position: relative;

		@include breakpoint(small down){
			padding: 20px; 
		}

		@include clearfix; 
		#logo_mobile{
			float: left;
		}

		.hamburger{
			float: right;
			display: block;
			margin-top: 28px;
		}

		.scroll_top{
			display: none; 
			position: absolute;
			top:16px; 
			color: $tealish-two;
		    right: 100px;
			
		 

			&:after{
				@include font($zmdi-var-long-arrow-up);
				font-size: 30px;

			}
		}
	}
}

#header_mobile_hover{

	display: none; 
	position: fixed;
	width: 100%; 

	bottom: 0; 
	overflow: auto; 
	z-index: 9999;


		

	@include breakpoint(medium down){
		top: 156px; 
	}

	@include breakpoint(small down){
		top: 120px;  
	}

	.main{
		background: #fff; 

		.block_content{
			> .menu{
				@include clearfix; 

				> li{
					
					border-bottom: solid 1px #d0d1d6;

					@media screen and (min-width: 800px) {
						padding-left: 15px;
						padding-right: 0px;
						float: left;
						width: 33.333%;

						> a, > .nolink{
							font-size: 17px;

							b{
								font-size: 16px;
							}
						}
					}

					&.active-trail{
						background: #ecedf2; 

						> a, > .nolink{
							color: $tealish-two;
						}
					}
					
					> a,  > .nolink{
						display: block;
						padding-left: 14px; 
						font-weight: bold;
						font-size: 18px;
						font-family: $ffT; 
						line-height: 24px;
						position: relative;
						color: $muted-blue;
						padding: 30px;
						padding-left: 55px;
						padding-right: 30px;


						b{
							text-transform: uppercase;
							display: block;
							font-family: $ffP; 
						}
					}

					> .menu{
						display: none; 
					}


					&.expand{
						position: relative;

						> a, > .nolink{
							&:before{
								@include font($zmdi-var-long-arrow-left);
								font-size: 24px;
								position: absolute;
								left: 20px; 
								top: 30px;
							}
						}
					}

					
				
					> ul{
														
							width: 100%;
							

							> li{
								position: relative;


								&:not(:last-child){
									border-bottom: solid 1px #d0d1d8;
								}

								> a, > .nolink{
									padding: 23px; 
									padding-left: 55px;
									display: block;
									font-size: 15px;
									font-weight: $fwSemiBold; 
									background: rgba(#ecedf2, 0.97);
									color: $black; 

									&:hover{
										color: $muted-blue;
									}
								}
								/* THIRD LEVEL */


								&.expanded{

									> a{
										pointer-events: none; 
									}

									&:after{
										@include font($zmdi-var-chevron-down); 
										position: absolute;
										top: 15px;
										right: 15px;
										position: absolute;
										color: $muted-blue;
										font-size: 24px;
										@include transition(0.3s); 
									}
									
									> ul{
										overflow: hidden;
										max-height: 0px; 
										@include transition(0.3s); 

										> li{
											&:not(:last-child){
												border-bottom: solid 1px #d0d1d8;
											}

											> a{
												padding: 20px 25px; 
												padding-left: 65px;
												color: #646464; 
												font-size: 14px;
												display: block;
												background: #d7d8de; 

												&:hover{
													color: $muted-blue; 
												}
											}
										}
									}

									&:hover{
										
										&:after{
											@include rotate(180deg); 
										}

										> a{
											color: $muted-blue; 
										}

										> ul{
											max-height: 500px; 
										}
									}
								}
							}
						}
				}
			}
		}

		.user_menu{
			padding: 25px; 

			a{
				display: block;
				height: 60px; 
				line-height: 60px; 
				color: #fff; 
				text-transform: uppercase;
				font-size: 16px;
				font-weight: $fwSemiBold; 
				text-align: center;
				

				&.join{
					background: $tealish-two; 
				}

				&.space{
					background: #254475; 

					&:after{
						@include font($zmdi-var-chevron-down);
						margin-left: 10px; 
						color: $lemon-green; 
					}
				}

				&:not(:last-child){
					margin-bottom: 25px;
				}
			}

		}
	}

	.bar{
		display: none; 
		background: #274573; 
		@include breakpoint(small down){
			display: block;
		}

		padding: 20px; 

		.header_top_block_menu, .header_top_block_lang{
			.block_content{
				> .menu, .language-switcher-locale-url{
					display: flex;
				    flex-flow: row wrap;
					> li{
						flex: 3 0px;
						display: block;
						margin: 2px; 
						position: relative;
						&.first:not(.en){
							flex: 1 100%;
						}

						> a, > span{
							display: block;
							height: 36px;
							line-height: 36px; 
							color: #fff; 
							font-size: 12px;
							text-align: center;
							background: #365fa0;

							

							&.icon{
								&:before{
									content: "";
									display: inline-block;
									vertical-align: middle;
									margin-right: 8px; 
									margin-top: -2px;
								}

								&.barreaux{
									padding-right: 5px;
									&:before{
										@include pictos-barreaux; 
									}
								}

								&.partenaires{
									
									
									&:before{
										@include pictos-partenaires; 
									}
								}

								&.presse{
									padding-left: 5px;
									&:before{
										@include pictos-presse; 
									}
								}
							}

							&:hover{
								color: $greenish-yellow;
							}
						}

						&.expanded{

							> .menu{
								position: absolute;
								left: 0; 
								top: 36px;
								z-index: 999;
								width: 100%; 
								overflow: hidden;
								max-height: 0px;
								@include transition(0.3s); 

								&:after{
									content: ""; 
									width: 2px; 
									height: 100%;
									position: absolute; 
									background: $turquoise-blue;
									top: 0;
									left: 0;
								}

								> li{
										background: $dark-slate-blue;
									&:first-child{
										padding-top: 5px;
									}

									&:last-child{
										padding-bottom: 5px;
									}

									a{
										font-size: 12px; 
										color: #fff;
										background: $dark-slate-blue;
										padding: 10px 15px;   
										display: block;
										text-align: right; 
										&:hover{
											color: $greenish-yellow;
										}
									}
								}

								
							}

							&:hover{
								> a, > span{
									color: #fff;
									background: $dark-slate-blue;
									&:after{
										content: ""; 
										width: 2px; 
										height: 100%;
										position: absolute; 
										background: $turquoise-blue;
										top: 0;
										left: 0;
									}
								}

								> .menu{
									max-height: 200px; 
								}
							}
						}
					}
				}
			}

		}

		.search-form{
			position: relative;
			overflow: hidden;
			border-radius: 22px; 
			margin-top: 20px;

			&:after{
				height: 44px;
				width: 60px;
				text-align: center;
				line-height: 44px;  
				color: #fff; 

				@include font($zmdi-var-search); 

				font-size: 30px;
				position: absolute;
				top: 0;
				right: 0;
				pointer-events: none; 
			}
			
			.form-text{
				height: 44px;
				padding: 0px; 
				padding-left: 20px;
				border: none; 
				width: 100%; 
				appearance: none; 

			}

			.form-submit{
				height: 100%;
				width: 60px; 
				border: none; 
				font-size: 0;
				position: absolute;
				top: 0;
				right: 0;
				background: $tealish; 
			}
		}
	}
}


#header_mobile.headroom--not-top + #header_mobile_hover{
	top: 63px; 
}

	
	
