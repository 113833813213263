.ac_results {
	
	overflow: hidden;
	z-index: 99999;
	border-left: solid 2px $lemon-green;
	padding-top: 10px;
	background: #fff; 
}

.ac_results ul {
	width: 100%;
	list-style-position: outside;
	list-style: none;
	padding: 0;
	margin: 0;
}

.ac_results li {

	&:first-child{
		display: none !important; 
	}

	
	cursor: pointer; 
	@include transition(0.3s);

	&:hover{
		background:$muted-blue;

		color: #fff !important;

		.count{
			color: #fff !important; 
		}
	}

	padding-left: 22px; 
	
	background: #fff; 
	line-height: 30px; 
	padding-top: 10px;
	cursor: pointer; 
	padding-right: 20px;
	padding-bottom: 10px;
	z-index: 9999; 
					
	display: block !important;
	clear: both; 
	@include clearfix; 
			
}

.ac_loading {
	background: white;
}
