/* EN VERSION */


    body.page-node-23763,  body.page-node-32024,   body.page-node-32025{

        .document_teaser .bg .detail .right .button_preview{
            display: none; 
        }


        #header_top .left,
        #header_top #block-menu-menu-header-top,
        #header_top .header_top_block_search,
        #header_top .header_top_block_account,
        #breadcrumbs,
        #header_mobile_hover .main_menu,
        #header_mobile_hover .user_menu,
        #header_mobile_hover .bar #block-menu-menu-header-top,
        #header_mobile_hover .bar .search-form
        {
          display: none !important;
        }

        @include breakpoint(medium only){
            .hamburger{
                display: none !important;  
            }
        }

        #logo_mobile,
        .list-annuaire-page .bg,
        .list-annuaire .bg,
        #footer_wrapper #footerOne
        {
            pointer-events: none;
            cursor: default;
        }
        .list-annuaire a.send_mail,
        .list-annuaire-page a.send_mail
        {
            pointer-events: all;
            cursor: pointer;
        }

        #footer_wrapper #footerThree,
        #footer_wrapper .email
        {
          display: none;
        }

        #header_main{
            height: auto; 

            .inside{
                padding: 10px 0px;
            }
             .container > .block.header_main_menu_block, .header_main_button_block{
                display: none; 
            }

            &.stickyTrigger{
                 .container > .block.header_main_logo_block a img{
                    height: 40px; 
                    width: auto; 
                 }
            }
          
            .container > .block.header_main_logo_block{
                width: 100%; 
                padding-top: 0;

                img{
                    width: 190px; 
                    height: auto; 
                    margin: 0px auto ; 
                     -webkit-backface-visibility: hidden; 
    -ms-transform: translateZ(0); /* IE 9 */
    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
    transform: translateZ(0);
                }
            }
        }            

    }


   

