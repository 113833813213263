.list-program_date{
	


	 h3{
		font-family: $ffT; 
		font-size: 24px;
		font-weight: $fwBold;
		color: $muted-blue; 
		padding-bottom: 20px;
		border-bottom: 1px solid $muted-blue;
		margin-bottom: 25px;

		@include breakpoint(medium down){
			text-align: center;
	
		}
	
	}

	ul{
		&:not(:last-child){
			margin-bottom: 50px; 
		}
	}

}

.list-program{
	> li{

		.ajax-progress {
			display: none;
		}

		&:not(:last-child){
			margin-bottom: 10px; 
		}

		.bg{
			display: block; 

			padding-left: 120px; 
			position: relative;
			@include transition(0.3s); 
			background: #ecedf2; 

			@include breakpoint(small down){
				padding-left: 0; 
				padding-top: 40px;
			}

			&:hover{
				background: $muted-blue;

				 *{
					color: #fff !important; 
					@include transition(0.3s); 
				}
			}


			.time{
				position: absolute;
				width: 120px; 
				height: 100%;
				display: flex;
				align-items: center; 
				justify-content: center; 
				background: $muted-blue; 
				left: 0;
				top: 0;

				@include breakpoint(small down){
					height:  40px; 
					width: 100%; 
					line-height: 40px; 
					top: 0;
				}

				span{
					display: block;
					color: #fff; 
					font-size: 16px;
				}
			}

			.detail{
				position: relative;
				padding: 20px 25px; 
				padding-right: 80px;

				h4{
					font-size: 18px;
					font-weight: $fwSemiBold; 
					line-height: 24px; 
					margin-bottom: 15px;
					color: #303030;

					@include breakpoint(medium down){
						font-size: 16px;
						line-height: 20px; 
					}
				}

				.location{
					color: #858585; 
					font-size: 14px; 
					
					&:before{
						@include font($zmdi-var-pin); 
						font-size: 14px;
						margin-right: 5px;
						color: $turquoise-blue;

					}

					@include breakpoint(small down){
						line-height: 19px; 
					}
				}

				.arrow{
					color: $turquoise-blue;
					height: 16px;
					line-height: 16px; 
					position: absolute; 
					top: 50%;
					margin-top: -8px;
					right: 30px; 

					&:before{
						@include font($zmdi-var-long-arrow-right); 
						font-size: 24px;
					}
				}
			}
		}
	}
}
