.dialog--open{
	z-index: 9999;
}

.dialog__content{
	
	position: relative;
	text-align: left;
	max-width: 750px; 
	padding: 60px; 

	@include breakpoint(small down){
		padding: 30px 60px; 
	}

	.closeDialog{
		position: absolute;
		top: 25px;
		right: 20px;
		cursor: pointer; 
		color: #858585; 

		&:hover{
			color: $muted-blue;
		}

		&:after{
			@include font($zmdi-var-close); 
			font-size: 24px;
		}
	}

	h2{
		font-family: $ffT; 
		font-size: 24px; 
		text-align: center;
		margin-bottom: 40px;

		font-weight: $fwBold; 
		color: $muted-blue;
		
	}

	h3{
		font-family: $ffT; 
		font-size: 18px; 
		font-weight: $fwBold; 
		margin-bottom: 30px;
		color: $muted-blue;
		
	}

	.text{
		color: #3d3d3d;
		font-size: 16px;
		line-height: 24px; 

		strong{
			font-weight: $fwBold; 
		}

		&.center{
			text-align: center;
			
		}
	}

	.content{
		
	}

	.button_container{
		margin-top: 50px; 
		text-align: center;
		padding: 0;
		
		.button{
			@include button($muted-blue, $turquoise-blue, auto, inline-block); 

			&:not(:last-child){
				margin-right: 20px; 
			}

			&.close_dialog{
				@include button(#ecedf2, #3d3d3d, auto, inline-block); 
				color: #3d3d3d;

				&:hover{
					color: #ecedf2;
				}
			}
		}

		
	}

	.dialog_bg{
		
	}
}

