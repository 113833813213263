.teaser_search{
	
	.bg{
		.top{
			@include clearfix; 
			margin-bottom: 20px;

			

			.node_type{
				@include catTeaser; 
				float: left;
				margin-right: 15px; 
			}

			.lang{
				float: left;
				font-size: 12px;
				font-weight: $fwSemiBold; 
				color: $tealish;
				height: 30px;
				line-height: 30px; 
			}
		}

			h3{
				color: $muted-blue;
				font-size: 16px; 
				font-weight: $fwBold; 
				margin-bottom: 25px; 
			}

			p{
				font-size: 16px;
				
				color: #303030;
				line-height: 28px; 
			}
			
	}

}