.list-dossiers-hp{

	@include breakpoint(large up){
					
		@include row(22); 

		> li {

			@include grid-column(4,  22);

		}
	}

	@include breakpoint(medium down){
		> li{
			&:not(:last-child){
				margin-bottom: 30px;
			}
		}
	}

}