#section_home_chiffres_cles{
	background: url(../img/bg_chiffres_cles.png);
	background-position:  center center;
	background-size: cover;

	padding-top: 240px;
	padding-bottom: 90px; 
	position: relative;
	z-index: 50; 

	@include breakpoint(medium down){
		padding-top: 120px;
		padding-bottom: 70px;
	}

	@include breakpoint(small down){
		padding-bottom: 35px;
		padding-top: 35px;
	}

	.container{
		@include clearfix; 

		h3{
			float: left;
			
			padding-top: 20px; 

			font-family: $ffT;
			font-size: 28px;
			font-weight: bold;
			line-height: 40.0px;
			color: #ffffff;

			@include breakpoint(xlarge up){
				padding-left: 50px;
				width: 310px;
			}

			@include breakpoint(large only){
				width: 200px; 
			}

			@include breakpoint(medium down){
				text-align: center;
				margin-bottom: 40px;
				float: none; 
			}

			@include breakpoint(small down){
				font-size: 24px;
				line-height: 35px; 
			}
		}

		.chiffres_container{
			float: right;
			width: 935px;

			@include breakpoint(large only){
				width: 670px;  
			}

			@include breakpoint(medium down){
				float: none; 
				width: 100%; 
			}

			.list-chiffre{
				@include clearfix; 

				@include breakpoint(medium only){
					@include row(20px); 
				}

				> li{
					width: 33.33%; 
					float: left;

					@include breakpoint(medium only){
						text-align: center;
						padding: 0px 10px; 
						
					}

					@include breakpoint(small down){
						width: 100%; 
						float: none; 
						text-align: center;
						&:not(:last-child){
							margin-bottom: 30px; 
						}
					}

					strong{
						display: block;
						font-family: $ffDroid;
						font-size: 84.2px;
						font-weight: $fwBold;
						color: $greenish-yellow;
						margin-bottom: 30px;

						@include breakpoint(medium down){
							font-size: 72px;
							margin-bottom: 15px;
						}
					}

					small{
						display: block;
						font-size: 15.7px;
						color: #ffffff;
						max-width: 180px; 
						line-height: 21px; 

						@include breakpoint(small down){
							font-size: 14px;
							margin: 0px auto; 
						}
					}
				}
			}
		}
	}
}