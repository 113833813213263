.list-mots{
	
	&.w_margin_top{
		margin-top: 50px;
	}

	position: relative;

	padding-top: 340px; 

	@include breakpoint(medium down){
		padding-top: 0;
	}

	> li{

		&:first-child{
			.block_persone{
				border-right: solid 1px #dadbe2;
			}
		}

		.block_persone{
			position: absolute;
			top: 0;
			height: 340px;
			width: 50%; 
			background: #ecedf2; 
			@include transition(0.4s);
			cursor: pointer; 

			@include breakpoint(medium down){
				position: relative;
				width: 100%; 
				
			}

			@include breakpoint(small down){
				height: auto; 
			}

			&.full{
				width: 100%; 
				border-right: 0;
			}

			.inside{
				padding-top: 35px;
				text-align: center;
				
				@include breakpoint(small down){
					padding: 20px; 
					@include clearfix; 
					text-align: left;
				}
			
				

				.img{
					width: 150px; 
					margin: 0px auto; 
					overflow: hidden;
					border-radius: 50%; 
					margin-bottom: 30px;

					@include breakpoint(small down){
						margin-bottom: 0;
						float: left;
						width: 75px; 
						margin-right: 20px;
					}

					img{
						display: block;
						width: 100%; 
						height: auto; 
						@include scale(1)
						@include transition(0.4s);
						border-radius: 50%; 
					}
				}

				h3{
					color: $muted-blue;
					font-size: 20px;
					font-weight: $fwBold; 
					margin-bottom: 12px; 
					@include transition(0.4s);
					span{
						text-transform: uppercase;
					}

					@include breakpoint(small down){
						font-size: 16px;
						margin-top: 16px;
					}
				}

				h4{
					font-size: 16px;
					font-weight: normal;
					color: #3d3d3d; 
					@include transition(0.4s);

					@include breakpoint(small down){
						font-size: 14px;
					}
				}

				i{
					display: block; 
					margin: 0px auto; 
					@include transition(0.3s);

					@include breakpoint(small down){
						display: none; 
					}

					&.bar{
						margin-top: 20px; 
						height: 4px; 
						width: 60px; 
						background: $turquoise-blue; 
						

					}

					&.arrow{
						max-height: 0px; 
						overflow: hidden;
						 height: 23px;
						 line-height: 23px; 
						 color: #fff; 
						 @include rotate(180deg);  
						 position: relative;
						 &:before{
						 	@include font($zmdi-var-long-arrow-up); 
						 	font-size: 30px;

						 }                   
					}
				}
			}

			
		}

		.mots_message{
			display: none; 
			.rte{
				border: solid 2px $muted-blue;
				padding: 50px 40px; 

				@include breakpoint(small down){
					padding: 25px 20px; 
				}
				
			}
		}

		&:first-child{
			.block_persone{
				left: 0;
			}
		}
		&:last-child{
			.block_persone{
				right: 0;
			}
		}

		&.active, &:hover{
			.block_persone{
				background: $muted-blue; 
				border-color : $muted-blue; 

				.inside{
					.img{
						img{
							@include scale(1.1); 
						}
					}

					h3, h4{
						color: #fff; 
					}
				}
			}
		}

		&.active{
			.block_persone{
				

				.inside{
					.bar{
						width: 0;
					}

					.arrow{
						max-height: 25px; 
					}
				}
			}
		}
	}

	

}