.list-actualites-hp{

	@include breakpoint(medium up){
					
		@include row(22); 

		> li {

			@include grid-column(6,  22);

		}
	}

	@include breakpoint(small down){
		> li{
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
	}


}


.list-actualites-bottom{

	margin-bottom: -23px !important;

	> li{
		margin-bottom: 23px; 
	}
	@include breakpoint(large up){
					
		@include row(22); 

		> li {

			@include grid-column(4,  22);

		}
	}

	@include breakpoint(medium only){
					
		@include row(22); 

		> li {

			@include grid-column(6,  22);

			&:last-child{
				display: none; 
			}

		}
	}

	@include breakpoint(small down){
		max-width: 300px;
	}

}



.list-actualites-page{

	@include clearfix; 
	margin-bottom: -23px !important;

	> li{
		margin-bottom: 23px; 
	}
	@include breakpoint(xlarge up){
					
		@include row(22); 

		> li {

			@include grid-column(4,  22);

			&:nth-child(3n+1){
				clear: both; 
			}

		}
	}



	@media screen and (max-width: 1366px) {
					
		@include row(22); 

		> li {

			@include grid-column(6,  22);

			&:nth-child(3n+1){
				clear: inherit; 
			}

			&:nth-child(2n+1){
				clear: both; 
			}

			

		}
	}

	@include breakpoint(small down){
		> li{

			width: 100%; 
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
	}
}


.list-actualites-tdb{
	margin-bottom: -23px !important;
	> li{
		margin-bottom: 23px; 
	}
	@include breakpoint(xlarge up){
					
		@include row(22); 

		> li {

			@include grid-column(4,  22);

		}
	}



	@media screen and (max-width: 1366px) {
					
		@include row(22); 

		> li {

			@include grid-column(6,  22);

			

		}
	}

	@include breakpoint(small down){
		> li{

			width: 100%; 
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
	}
}