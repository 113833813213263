#section_home_last{

	position: relative;
	
	@include fillBackgroundLeft($turquoise-blue); 
	@include fillBackgroundRight($muted-blue); 

	.container{
		z-index: 10; 
		position: relative;
		.left{
			float: left; 
			width: 40%;
			background-color: $turquoise-blue;
			padding-right: 105px; 
			padding-left: 20px; 

			@include breakpoint(large down){
				float: none; 
				width: 100%; 
				position: relative;
				@include fillBackgroundRightOutside($turquoise-blue);
				padding-left: 0 !important;
				padding-right: 0 !important;
			}
		}

		.right{
			float: right;
			width: 60%;
			background-color: $muted-blue;
			padding-right: 55px;

			@include breakpoint(large down){
				float: none; 
				width: 100%; 
				position: relative;
				@include fillBackgroundLeftOutside($muted-blue);
				padding-left: 0 !important;
				padding-right: 0 !important;
			}

			

			.block{
				padding-left: 220px; 
				position: relative;

				@include breakpoint(large down){
					padding-left: 100px;
				}

				@include breakpoint(small down){
					padding-left: 0;
					text-align: center;
					
				}

				h3{
					font-family: $ffP;
					font-size: 24px;
					font-weight: $fwSemiBold; 
					line-height: 33.0px;
					color: #ffffff;
					margin-bottom: 25px;

					position: relative;

					@include breakpoint(small down){
						font-size: 18px;
						margin-bottom: 20px; 
					}

					&:before{
						content: "";
						position: absolute;
						top: 0;
						left: -90px;
						top: 7px;

						@include breakpoint(small down){
							display: block;
							position: relative;
							left: 0;
							top: 0;
							margin: 0px auto; 
							margin-bottom: 16px; 
						}

					}
				}

				&.block_newsletter{
					padding-bottom: 60px;
					border-bottom: solid 1px rgba(#fff, 0.2); 

					@include breakpoint(small down){
						padding-bottom: 30px;
					}
					h3{
						&:before{
							@include pictos-homeNews; 
						}
					}

					&:after{
						content: "";
						width: 200%;
						position: absolute;
						bottom: -1px; 
						height: 1px;
						background: rgba(#fff, 0.2);  
						right: -200%;
					}

					.form-type-checkbox{
						input{
							display: none; 
						}

						label{
							font-size: 11px;
							line-height: 14px; 
							color: #fff;
							position: relative;
							
							padding-left: 23px;
							display: block;
							cursor: pointer; 

							a{
								color: #fff; 
								text-decoration: underline;

								&:hover{
									color: $secondColor; 
								}
							}

							&:before{
								content: "";
								position: absolute;
								top: 2px; 
								left: 0;
								width: 16px;
								height: 16px;
								border: solid 1px #858585;
								font-size: 14px;
								text-align: center;
								color: $tealish-two;
								background: #fff; 
							}
						}

						input:checked + label{

							&:before{
								@include font($zmdi-var-check); 
							}
						}
					}

					form{
						margin-top: 25px; 
						position: relative;
						padding-right: 62px;
					

						.form-text{
							width: 100%; 
							height: 45px; 
							padding-left: 22px;
							background: #fff; 
							border: none; 
							font-size: 16px;
							color: $black; 
							border-top-left-radius: 22px; 
							border-bottom-left-radius: 22px; 


							@include input-placeholder{
								color: #858585; 
							}

							&:focus{
								@include input-placeholder{
									color: $pale-grey; 
								}
							}
						
						}

						.form-item-validation{
							margin-bottom: 20px;
						}

						.form-submit{
							position: absolute;
/*							top: 0px; */
							bottom: 0px;
							right: 0;
							width: 62px;
/*							height: 100%;*/
							height: 45px;
							cursor: pointer; 
							background: #45c8d8;
							border: none; 
							font-size: 0;
							padding: 0;
							border-top-right-radius: 22px; 
							border-bottom-right-radius: 22px; 

							&:hover{
								background: $dark-slate-blue; 
							}
						}

						&:after{
							@include font($zmdi-var-mail-send); 
							pointer-events: none; 
							position: absolute;
							/*top: 0px; */
							bottom: 0px;
							right: 0;
							width: 62px;
							/*height: 100%;*/			
							height: 45px;						
							display: flex;
							align-items: center;
							justify-content: center; 
							color: #fff; 
							font-size: 20px;

						}
					}
				}

				&.block_contact{
					padding-top: 60px;

					@include breakpoint(small down){
							padding-top: 30px;
					}
					h3{
						&:before{
							@include pictos-homeContact; 
						}
					}

					.button_container{
						a{
							@include buttonBorder(#fff, $muted-blue) 
						}
					}
				}
			
			}
		}

		.left{
			.content{
				h2{
					height: 50px;
					line-height: 50px; 
					@include clearfix; 
					margin-bottom: 35px; 
					span{	
						font-size: 28px;
						font-weight: bold;
						color: #ffffff;
						font-family: $ffT; 
						float: left;
					}
					a{
						@include buttonBorder(#fff, $muted-blue); 
						float: right;
					}
				}
			}
		}

		.left, .right{
			padding-top: 65px;
			padding-bottom: 60px; 
			color: #fff; 

			@include breakpoint(small down){
				padding: 30px 0px;
			}
		}
	}
}

.logged-in{

	#section_home_last .container .right .block.block_newsletter form{

		@include clearfix; 

		 .form-submit{
		
			position: relative;
			@include buttonBorder(#fff, #fff); 
			border-radius: 0;

			&:hover{
				color: $muted-blue;
			}
		}
	
	}

}