.teaser_album{

	.bg{
		display: block;
		max-width: 300px; 
		margin: 0px auto; 

		.img{	
			@include imgHover; 	
		}

		&:hover{
			.img{
				@include imgHoverActive; 
			}
		}

		.bottom{
			height: 60px;
			background: $turquoise-blue; 
			padding: 12px; 
			position: relative;

			h3{
				background: #fff; 
				padding: 17px 12px; 
				font-weight: $fwSemiBold; 
				color: $black;
				font-size: 16px;
				line-height: 24px; 
				position: absolute;
				left: 12px;
				right: 12px;
				bottom: 12px; 
				z-index: 50;
			}
		}
	}

}