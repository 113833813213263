

.user_teaser{

	.bg{
		background: #fff; 
		padding: 30px 20px;
		@include clearfix; 
		position: relative;

		@media screen and (max-width: 1366px) {
			text-align: center;
			padding-bottom: 40px;
			
		}

		i.icon_officiel{
			background: url(../img/officielBlue.png); 
			width: 12px;
			height: 17px;
			position: absolute;
			right: 15px;
			top: 15px;
			background-size: 12px;
			background-repeat: no-repeat; 
		}

		.img{
			float: left;
			overflow: hidden;
			border-radius: 50%; 
			margin-right: 22px;
			width: 120px; 
			display: block;
			z-index: 10; 
			-webkit-backface-visibility: hidden;
			-moz-backface-visibility: hidden;
			-webkit-transform: translate3d(0, 0, 0);
			-moz-transform: translate3d(0, 0, 0);

			@media screen and (max-width: 1366px) {
				float: none; 
				
				margin-right: auto;
				margin-left: auto; 
			}
			
			img{
				display: block;
				@include scale(1); 
				@include transition(0.3s); 
				width: 100%; 
				height: auto; 
			}
		}

		&:hover{
			.img{
				img{
					@include scale(1.1); 
				}
			}
		}

		.detail{
			display: block;
			@media screen and (min-width: 1367px) {
				float: left; 
				width: 275px; 
			}
			h3{
				color: $muted-blue; 
				font-size: 13.9px;
				font-weight: bold;
				line-height: 20.86px;
				margin-bottom: 20px;
				padding-right: 0px;
				padding-top: 10px;
				min-height: 50px;

				@media screen and (max-width: 1366px) {
					padding: 20px; 
					padding-bottom: 0;
				}

				a{
					color:  $muted-blue;
				}
			}

			p{
				font-size: 13.9px;
				color: $black-two;
				padding-right: 60px;
				line-height: 20px; 
				min-height: 60px; 

				a{
					font-size: 13.9px;
					color: $black-two;
				}

				@media screen and (max-width: 1366px) {
					padding-left: 20px;
					padding-right: 20px;
					padding-top: 0;
				}
			}
		}
		

		.send_mail{
			display: block;
			position: absolute;
			bottom: 0;
			right: 0;
			width: 37px;
			height: 37px;
			font-size: 0;
			background-color: $tealish;

			&:hover{
				background: $dark-slate-blue;
			}

			&:after{
				display: block; 
				text-align: center;
				line-height: 37px; 
				color: #fff; 
				font-size: 17.9px;
				color: #ffffff;
				@include font($zmdi-var-email); 
			}
		}

	}
}