// Generated with Spriteowl (compiler version 1.3.1)
//
// Please define the location for your image source:
//
// $pictos-sheet-image: url("../sprites/pictos.png");
// $pictos-sheet-image-2x: url("../sprites/pictos@2x.png");
//
// --- Layers --------------------------------------------
//
// - pictos/
//   - edit
//   - deconnexion
//   - membreOfficiel
//   - loupeBleu
//   - loupeBlanche
//   - youtube
//   - tarifVert
//   - contactVert
//   - internationalVert
//   - partenaires
//   - presse
//   - barreaux
//   - tarif
//   - contacts
//   - international
//   - calendar
//   - homeNews
//   - homeContact
//
$pictos-sheet-width: 251px !default;
$pictos-sheet-height: 280px !default;
$pictos-sheet-image: url("../sprites/pictos.png") !default;
$pictos-sheet-image-2x: url("../sprites/pictos@2x.png") !default;
$pictos-sheet: $pictos-sheet-width $pictos-sheet-height $pictos-sheet-image $pictos-sheet-image-2x pictos !default;

@mixin pictos-element() {
  background-image: $pictos-sheet-image;
  background-repeat: no-repeat;
  @media only screen and (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
    background-image: $pictos-sheet-image-2x;
    background-size: $pictos-sheet-width $pictos-sheet-height;
  }
}
%pictos-element {
  @include pictos-element();
}

$pictos-edit-x: -1px !default;
$pictos-edit-y: -199px !default;
$pictos-edit-width: 20px !default;
$pictos-edit-height: 16px !default;
$pictos-edit: $pictos-edit-x $pictos-edit-y $pictos-edit-width $pictos-edit-height pictos-edit !default;

@mixin pictos-edit-width() {
  width: $pictos-edit-width;
}
%pictos-edit-width {
  @include pictos-edit-width();
}
@mixin pictos-edit-height() {
  height: $pictos-edit-height;
}
%pictos-edit-height {
  @include pictos-edit-height();
}
@mixin pictos-edit-size() {
  @include pictos-edit-width();
  @include pictos-edit-height();
}
%pictos-edit-size {
  @include pictos-edit-size();
}
@mixin pictos-edit-position() {
  background-position: $pictos-edit-x $pictos-edit-y;
}
%pictos-edit-position {
  @include pictos-edit-position();
}
@mixin pictos-edit-adjust() {
  @include pictos-edit-size();
  @include pictos-edit-position();
}
%pictos-edit-adjust {
  @include pictos-edit-adjust();
}
@mixin pictos-edit() {
  @include pictos-edit-adjust();
  @extend %pictos-element;
}
%pictos-edit {
  @include pictos-edit();
}

$pictos-deconnexion-x: -3px !default;
$pictos-deconnexion-y: -9px !default;
$pictos-deconnexion-width: 10px !default;
$pictos-deconnexion-height: 12px !default;
$pictos-deconnexion: $pictos-deconnexion-x $pictos-deconnexion-y $pictos-deconnexion-width $pictos-deconnexion-height pictos-deconnexion !default;

@mixin pictos-deconnexion-width() {
  width: $pictos-deconnexion-width;
}
%pictos-deconnexion-width {
  @include pictos-deconnexion-width();
}
@mixin pictos-deconnexion-height() {
  height: $pictos-deconnexion-height;
}
%pictos-deconnexion-height {
  @include pictos-deconnexion-height();
}
@mixin pictos-deconnexion-size() {
  @include pictos-deconnexion-width();
  @include pictos-deconnexion-height();
}
%pictos-deconnexion-size {
  @include pictos-deconnexion-size();
}
@mixin pictos-deconnexion-position() {
  background-position: $pictos-deconnexion-x $pictos-deconnexion-y;
}
%pictos-deconnexion-position {
  @include pictos-deconnexion-position();
}
@mixin pictos-deconnexion-adjust() {
  @include pictos-deconnexion-size();
  @include pictos-deconnexion-position();
}
%pictos-deconnexion-adjust {
  @include pictos-deconnexion-adjust();
}
@mixin pictos-deconnexion() {
  @include pictos-deconnexion-adjust();
  @extend %pictos-element;
}
%pictos-deconnexion {
  @include pictos-deconnexion();
}

$pictos-membreOfficiel-x: -2px !default;
$pictos-membreOfficiel-y: -33px !default;
$pictos-membreOfficiel-width: 12px !default;
$pictos-membreOfficiel-height: 17px !default;
$pictos-membreOfficiel: $pictos-membreOfficiel-x $pictos-membreOfficiel-y $pictos-membreOfficiel-width $pictos-membreOfficiel-height pictos-membreOfficiel !default;

@mixin pictos-membreOfficiel-width() {
  width: $pictos-membreOfficiel-width;
}
%pictos-membreOfficiel-width {
  @include pictos-membreOfficiel-width();
}
@mixin pictos-membreOfficiel-height() {
  height: $pictos-membreOfficiel-height;
}
%pictos-membreOfficiel-height {
  @include pictos-membreOfficiel-height();
}
@mixin pictos-membreOfficiel-size() {
  @include pictos-membreOfficiel-width();
  @include pictos-membreOfficiel-height();
}
%pictos-membreOfficiel-size {
  @include pictos-membreOfficiel-size();
}
@mixin pictos-membreOfficiel-position() {
  background-position: $pictos-membreOfficiel-x $pictos-membreOfficiel-y;
}
%pictos-membreOfficiel-position {
  @include pictos-membreOfficiel-position();
}
@mixin pictos-membreOfficiel-adjust() {
  @include pictos-membreOfficiel-size();
  @include pictos-membreOfficiel-position();
}
%pictos-membreOfficiel-adjust {
  @include pictos-membreOfficiel-adjust();
}
@mixin pictos-membreOfficiel() {
  @include pictos-membreOfficiel-adjust();
  @extend %pictos-element;
}
%pictos-membreOfficiel {
  @include pictos-membreOfficiel();
}

$pictos-loupeBleu-x: --3px !default;
$pictos-loupeBleu-y: -86px !default;
$pictos-loupeBleu-width: 21px !default;
$pictos-loupeBleu-height: 20px !default;
$pictos-loupeBleu: $pictos-loupeBleu-x $pictos-loupeBleu-y $pictos-loupeBleu-width $pictos-loupeBleu-height pictos-loupeBleu !default;

@mixin pictos-loupeBleu-width() {
  width: $pictos-loupeBleu-width;
}
%pictos-loupeBleu-width {
  @include pictos-loupeBleu-width();
}
@mixin pictos-loupeBleu-height() {
  height: $pictos-loupeBleu-height;
}
%pictos-loupeBleu-height {
  @include pictos-loupeBleu-height();
}
@mixin pictos-loupeBleu-size() {
  @include pictos-loupeBleu-width();
  @include pictos-loupeBleu-height();
}
%pictos-loupeBleu-size {
  @include pictos-loupeBleu-size();
}
@mixin pictos-loupeBleu-position() {
  background-position: $pictos-loupeBleu-x $pictos-loupeBleu-y;
}
%pictos-loupeBleu-position {
  @include pictos-loupeBleu-position();
}
@mixin pictos-loupeBleu-adjust() {
  @include pictos-loupeBleu-size();
  @include pictos-loupeBleu-position();
}
%pictos-loupeBleu-adjust {
  @include pictos-loupeBleu-adjust();
}
@mixin pictos-loupeBleu() {
  @include pictos-loupeBleu-adjust();
  @extend %pictos-element;
}
%pictos-loupeBleu {
  @include pictos-loupeBleu();
}

$pictos-loupeBlanche-x: --3px !default;
$pictos-loupeBlanche-y: -61px !default;
$pictos-loupeBlanche-width: 21px !default;
$pictos-loupeBlanche-height: 21px !default;
$pictos-loupeBlanche: $pictos-loupeBlanche-x $pictos-loupeBlanche-y $pictos-loupeBlanche-width $pictos-loupeBlanche-height pictos-loupeBlanche !default;

@mixin pictos-loupeBlanche-width() {
  width: $pictos-loupeBlanche-width;
}
%pictos-loupeBlanche-width {
  @include pictos-loupeBlanche-width();
}
@mixin pictos-loupeBlanche-height() {
  height: $pictos-loupeBlanche-height;
}
%pictos-loupeBlanche-height {
  @include pictos-loupeBlanche-height();
}
@mixin pictos-loupeBlanche-size() {
  @include pictos-loupeBlanche-width();
  @include pictos-loupeBlanche-height();
}
%pictos-loupeBlanche-size {
  @include pictos-loupeBlanche-size();
}
@mixin pictos-loupeBlanche-position() {
  background-position: $pictos-loupeBlanche-x $pictos-loupeBlanche-y;
}
%pictos-loupeBlanche-position {
  @include pictos-loupeBlanche-position();
}
@mixin pictos-loupeBlanche-adjust() {
  @include pictos-loupeBlanche-size();
  @include pictos-loupeBlanche-position();
}
%pictos-loupeBlanche-adjust {
  @include pictos-loupeBlanche-adjust();
}
@mixin pictos-loupeBlanche() {
  @include pictos-loupeBlanche-adjust();
  @extend %pictos-element;
}
%pictos-loupeBlanche {
  @include pictos-loupeBlanche();
}

$pictos-youtube-x: -31px !default;
$pictos-youtube-y: -10px !default;
$pictos-youtube-width: 21px !default;
$pictos-youtube-height: 17px !default;
$pictos-youtube: $pictos-youtube-x $pictos-youtube-y $pictos-youtube-width $pictos-youtube-height pictos-youtube !default;

@mixin pictos-youtube-width() {
  width: $pictos-youtube-width;
}
%pictos-youtube-width {
  @include pictos-youtube-width();
}
@mixin pictos-youtube-height() {
  height: $pictos-youtube-height;
}
%pictos-youtube-height {
  @include pictos-youtube-height();
}
@mixin pictos-youtube-size() {
  @include pictos-youtube-width();
  @include pictos-youtube-height();
}
%pictos-youtube-size {
  @include pictos-youtube-size();
}
@mixin pictos-youtube-position() {
  background-position: $pictos-youtube-x $pictos-youtube-y;
}
%pictos-youtube-position {
  @include pictos-youtube-position();
}
@mixin pictos-youtube-adjust() {
  @include pictos-youtube-size();
  @include pictos-youtube-position();
}
%pictos-youtube-adjust {
  @include pictos-youtube-adjust();
}
@mixin pictos-youtube() {
  @include pictos-youtube-adjust();
  @extend %pictos-element;
}
%pictos-youtube {
  @include pictos-youtube();
}

$pictos-tarifVert-x: -175px !default;
$pictos-tarifVert-y: -69px !default;
$pictos-tarifVert-width: 76px !default;
$pictos-tarifVert-height: 61px !default;
$pictos-tarifVert: $pictos-tarifVert-x $pictos-tarifVert-y $pictos-tarifVert-width $pictos-tarifVert-height pictos-tarifVert !default;

@mixin pictos-tarifVert-width() {
  width: $pictos-tarifVert-width;
}
%pictos-tarifVert-width {
  @include pictos-tarifVert-width();
}
@mixin pictos-tarifVert-height() {
  height: $pictos-tarifVert-height;
}
%pictos-tarifVert-height {
  @include pictos-tarifVert-height();
}
@mixin pictos-tarifVert-size() {
  @include pictos-tarifVert-width();
  @include pictos-tarifVert-height();
}
%pictos-tarifVert-size {
  @include pictos-tarifVert-size();
}
@mixin pictos-tarifVert-position() {
  background-position: $pictos-tarifVert-x $pictos-tarifVert-y;
}
%pictos-tarifVert-position {
  @include pictos-tarifVert-position();
}
@mixin pictos-tarifVert-adjust() {
  @include pictos-tarifVert-size();
  @include pictos-tarifVert-position();
}
%pictos-tarifVert-adjust {
  @include pictos-tarifVert-adjust();
}
@mixin pictos-tarifVert() {
  @include pictos-tarifVert-adjust();
  @extend %pictos-element;
}
%pictos-tarifVert {
  @include pictos-tarifVert();
}

$pictos-contactVert-x: -103px !default;
$pictos-contactVert-y: -215px !default;
$pictos-contactVert-width: 65px !default;
$pictos-contactVert-height: 65px !default;
$pictos-contactVert: $pictos-contactVert-x $pictos-contactVert-y $pictos-contactVert-width $pictos-contactVert-height pictos-contactVert !default;

@mixin pictos-contactVert-width() {
  width: $pictos-contactVert-width;
}
%pictos-contactVert-width {
  @include pictos-contactVert-width();
}
@mixin pictos-contactVert-height() {
  height: $pictos-contactVert-height;
}
%pictos-contactVert-height {
  @include pictos-contactVert-height();
}
@mixin pictos-contactVert-size() {
  @include pictos-contactVert-width();
  @include pictos-contactVert-height();
}
%pictos-contactVert-size {
  @include pictos-contactVert-size();
}
@mixin pictos-contactVert-position() {
  background-position: $pictos-contactVert-x $pictos-contactVert-y;
}
%pictos-contactVert-position {
  @include pictos-contactVert-position();
}
@mixin pictos-contactVert-adjust() {
  @include pictos-contactVert-size();
  @include pictos-contactVert-position();
}
%pictos-contactVert-adjust {
  @include pictos-contactVert-adjust();
}
@mixin pictos-contactVert() {
  @include pictos-contactVert-adjust();
  @extend %pictos-element;
}
%pictos-contactVert {
  @include pictos-contactVert();
}

$pictos-internationalVert-x: -102px !default;
$pictos-internationalVert-y: -70px !default;
$pictos-internationalVert-width: 62px !default;
$pictos-internationalVert-height: 60px !default;
$pictos-internationalVert: $pictos-internationalVert-x $pictos-internationalVert-y $pictos-internationalVert-width $pictos-internationalVert-height pictos-internationalVert !default;

@mixin pictos-internationalVert-width() {
  width: $pictos-internationalVert-width;
}
%pictos-internationalVert-width {
  @include pictos-internationalVert-width();
}
@mixin pictos-internationalVert-height() {
  height: $pictos-internationalVert-height;
}
%pictos-internationalVert-height {
  @include pictos-internationalVert-height();
}
@mixin pictos-internationalVert-size() {
  @include pictos-internationalVert-width();
  @include pictos-internationalVert-height();
}
%pictos-internationalVert-size {
  @include pictos-internationalVert-size();
}
@mixin pictos-internationalVert-position() {
  background-position: $pictos-internationalVert-x $pictos-internationalVert-y;
}
%pictos-internationalVert-position {
  @include pictos-internationalVert-position();
}
@mixin pictos-internationalVert-adjust() {
  @include pictos-internationalVert-size();
  @include pictos-internationalVert-position();
}
%pictos-internationalVert-adjust {
  @include pictos-internationalVert-adjust();
}
@mixin pictos-internationalVert() {
  @include pictos-internationalVert-adjust();
  @extend %pictos-element;
}
%pictos-internationalVert {
  @include pictos-internationalVert();
}

$pictos-partenaires-x: -1px !default;
$pictos-partenaires-y: -164px !default;
$pictos-partenaires-width: 20px !default;
$pictos-partenaires-height: 18px !default;
$pictos-partenaires: $pictos-partenaires-x $pictos-partenaires-y $pictos-partenaires-width $pictos-partenaires-height pictos-partenaires !default;

@mixin pictos-partenaires-width() {
  width: $pictos-partenaires-width;
}
%pictos-partenaires-width {
  @include pictos-partenaires-width();
}
@mixin pictos-partenaires-height() {
  height: $pictos-partenaires-height;
}
%pictos-partenaires-height {
  @include pictos-partenaires-height();
}
@mixin pictos-partenaires-size() {
  @include pictos-partenaires-width();
  @include pictos-partenaires-height();
}
%pictos-partenaires-size {
  @include pictos-partenaires-size();
}
@mixin pictos-partenaires-position() {
  background-position: $pictos-partenaires-x $pictos-partenaires-y;
}
%pictos-partenaires-position {
  @include pictos-partenaires-position();
}
@mixin pictos-partenaires-adjust() {
  @include pictos-partenaires-size();
  @include pictos-partenaires-position();
}
%pictos-partenaires-adjust {
  @include pictos-partenaires-adjust();
}
@mixin pictos-partenaires() {
  @include pictos-partenaires-adjust();
  @extend %pictos-element;
}
%pictos-partenaires {
  @include pictos-partenaires();
}

$pictos-presse-x: -1px !default;
$pictos-presse-y: -139px !default;
$pictos-presse-width: 16px !default;
$pictos-presse-height: 16px !default;
$pictos-presse: $pictos-presse-x $pictos-presse-y $pictos-presse-width $pictos-presse-height pictos-presse !default;

@mixin pictos-presse-width() {
  width: $pictos-presse-width;
}
%pictos-presse-width {
  @include pictos-presse-width();
}
@mixin pictos-presse-height() {
  height: $pictos-presse-height;
}
%pictos-presse-height {
  @include pictos-presse-height();
}
@mixin pictos-presse-size() {
  @include pictos-presse-width();
  @include pictos-presse-height();
}
%pictos-presse-size {
  @include pictos-presse-size();
}
@mixin pictos-presse-position() {
  background-position: $pictos-presse-x $pictos-presse-y;
}
%pictos-presse-position {
  @include pictos-presse-position();
}
@mixin pictos-presse-adjust() {
  @include pictos-presse-size();
  @include pictos-presse-position();
}
%pictos-presse-adjust {
  @include pictos-presse-adjust();
}
@mixin pictos-presse() {
  @include pictos-presse-adjust();
  @extend %pictos-element;
}
%pictos-presse {
  @include pictos-presse();
}

$pictos-barreaux-x: -1px !default;
$pictos-barreaux-y: -113px !default;
$pictos-barreaux-width: 16px !default;
$pictos-barreaux-height: 16px !default;
$pictos-barreaux: $pictos-barreaux-x $pictos-barreaux-y $pictos-barreaux-width $pictos-barreaux-height pictos-barreaux !default;

@mixin pictos-barreaux-width() {
  width: $pictos-barreaux-width;
}
%pictos-barreaux-width {
  @include pictos-barreaux-width();
}
@mixin pictos-barreaux-height() {
  height: $pictos-barreaux-height;
}
%pictos-barreaux-height {
  @include pictos-barreaux-height();
}
@mixin pictos-barreaux-size() {
  @include pictos-barreaux-width();
  @include pictos-barreaux-height();
}
%pictos-barreaux-size {
  @include pictos-barreaux-size();
}
@mixin pictos-barreaux-position() {
  background-position: $pictos-barreaux-x $pictos-barreaux-y;
}
%pictos-barreaux-position {
  @include pictos-barreaux-position();
}
@mixin pictos-barreaux-adjust() {
  @include pictos-barreaux-size();
  @include pictos-barreaux-position();
}
%pictos-barreaux-adjust {
  @include pictos-barreaux-adjust();
}
@mixin pictos-barreaux() {
  @include pictos-barreaux-adjust();
  @extend %pictos-element;
}
%pictos-barreaux {
  @include pictos-barreaux();
}

$pictos-tarif-x: -174px !default;
$pictos-tarif-y: --1px !default;
$pictos-tarif-width: 76px !default;
$pictos-tarif-height: 61px !default;
$pictos-tarif: $pictos-tarif-x $pictos-tarif-y $pictos-tarif-width $pictos-tarif-height pictos-tarif !default;

@mixin pictos-tarif-width() {
  width: $pictos-tarif-width;
}
%pictos-tarif-width {
  @include pictos-tarif-width();
}
@mixin pictos-tarif-height() {
  height: $pictos-tarif-height;
}
%pictos-tarif-height {
  @include pictos-tarif-height();
}
@mixin pictos-tarif-size() {
  @include pictos-tarif-width();
  @include pictos-tarif-height();
}
%pictos-tarif-size {
  @include pictos-tarif-size();
}
@mixin pictos-tarif-position() {
  background-position: $pictos-tarif-x $pictos-tarif-y;
}
%pictos-tarif-position {
  @include pictos-tarif-position();
}
@mixin pictos-tarif-adjust() {
  @include pictos-tarif-size();
  @include pictos-tarif-position();
}
%pictos-tarif-adjust {
  @include pictos-tarif-adjust();
}
@mixin pictos-tarif() {
  @include pictos-tarif-adjust();
  @extend %pictos-element;
}
%pictos-tarif {
  @include pictos-tarif();
}

$pictos-contacts-x: -102px !default;
$pictos-contacts-y: -140px !default;
$pictos-contacts-width: 65px !default;
$pictos-contacts-height: 65px !default;
$pictos-contacts: $pictos-contacts-x $pictos-contacts-y $pictos-contacts-width $pictos-contacts-height pictos-contacts !default;

@mixin pictos-contacts-width() {
  width: $pictos-contacts-width;
}
%pictos-contacts-width {
  @include pictos-contacts-width();
}
@mixin pictos-contacts-height() {
  height: $pictos-contacts-height;
}
%pictos-contacts-height {
  @include pictos-contacts-height();
}
@mixin pictos-contacts-size() {
  @include pictos-contacts-width();
  @include pictos-contacts-height();
}
%pictos-contacts-size {
  @include pictos-contacts-size();
}
@mixin pictos-contacts-position() {
  background-position: $pictos-contacts-x $pictos-contacts-y;
}
%pictos-contacts-position {
  @include pictos-contacts-position();
}
@mixin pictos-contacts-adjust() {
  @include pictos-contacts-size();
  @include pictos-contacts-position();
}
%pictos-contacts-adjust {
  @include pictos-contacts-adjust();
}
@mixin pictos-contacts() {
  @include pictos-contacts-adjust();
  @extend %pictos-element;
}
%pictos-contacts {
  @include pictos-contacts();
}

$pictos-international-x: -102px !default;
$pictos-international-y: 0 !default;
$pictos-international-width: 62px !default;
$pictos-international-height: 60px !default;
$pictos-international: $pictos-international-x $pictos-international-y $pictos-international-width $pictos-international-height pictos-international !default;

@mixin pictos-international-width() {
  width: $pictos-international-width;
}
%pictos-international-width {
  @include pictos-international-width();
}
@mixin pictos-international-height() {
  height: $pictos-international-height;
}
%pictos-international-height {
  @include pictos-international-height();
}
@mixin pictos-international-size() {
  @include pictos-international-width();
  @include pictos-international-height();
}
%pictos-international-size {
  @include pictos-international-size();
}
@mixin pictos-international-position() {
  background-position: $pictos-international-x $pictos-international-y;
}
%pictos-international-position {
  @include pictos-international-position();
}
@mixin pictos-international-adjust() {
  @include pictos-international-size();
  @include pictos-international-position();
}
%pictos-international-adjust {
  @include pictos-international-adjust();
}
@mixin pictos-international() {
  @include pictos-international-adjust();
  @extend %pictos-element;
}
%pictos-international {
  @include pictos-international();
}

$pictos-calendar-x: -36px !default;
$pictos-calendar-y: -161px !default;
$pictos-calendar-width: 24px !default;
$pictos-calendar-height: 26px !default;
$pictos-calendar: $pictos-calendar-x $pictos-calendar-y $pictos-calendar-width $pictos-calendar-height pictos-calendar !default;

@mixin pictos-calendar-width() {
  width: $pictos-calendar-width;
}
%pictos-calendar-width {
  @include pictos-calendar-width();
}
@mixin pictos-calendar-height() {
  height: $pictos-calendar-height;
}
%pictos-calendar-height {
  @include pictos-calendar-height();
}
@mixin pictos-calendar-size() {
  @include pictos-calendar-width();
  @include pictos-calendar-height();
}
%pictos-calendar-size {
  @include pictos-calendar-size();
}
@mixin pictos-calendar-position() {
  background-position: $pictos-calendar-x $pictos-calendar-y;
}
%pictos-calendar-position {
  @include pictos-calendar-position();
}
@mixin pictos-calendar-adjust() {
  @include pictos-calendar-size();
  @include pictos-calendar-position();
}
%pictos-calendar-adjust {
  @include pictos-calendar-adjust();
}
@mixin pictos-calendar() {
  @include pictos-calendar-adjust();
  @extend %pictos-element;
}
%pictos-calendar {
  @include pictos-calendar();
}

$pictos-homeNews-x: -35px !default;
$pictos-homeNews-y: -47px !default;
$pictos-homeNews-width: 49px !default;
$pictos-homeNews-height: 39px !default;
$pictos-homeNews: $pictos-homeNews-x $pictos-homeNews-y $pictos-homeNews-width $pictos-homeNews-height pictos-homeNews !default;

@mixin pictos-homeNews-width() {
  width: $pictos-homeNews-width;
}
%pictos-homeNews-width {
  @include pictos-homeNews-width();
}
@mixin pictos-homeNews-height() {
  height: $pictos-homeNews-height;
}
%pictos-homeNews-height {
  @include pictos-homeNews-height();
}
@mixin pictos-homeNews-size() {
  @include pictos-homeNews-width();
  @include pictos-homeNews-height();
}
%pictos-homeNews-size {
  @include pictos-homeNews-size();
}
@mixin pictos-homeNews-position() {
  background-position: $pictos-homeNews-x $pictos-homeNews-y;
}
%pictos-homeNews-position {
  @include pictos-homeNews-position();
}
@mixin pictos-homeNews-adjust() {
  @include pictos-homeNews-size();
  @include pictos-homeNews-position();
}
%pictos-homeNews-adjust {
  @include pictos-homeNews-adjust();
}
@mixin pictos-homeNews() {
  @include pictos-homeNews-adjust();
  @extend %pictos-element;
}
%pictos-homeNews {
  @include pictos-homeNews();
}

$pictos-homeContact-x: -35px !default;
$pictos-homeContact-y: -96px !default;
$pictos-homeContact-width: 46px !default;
$pictos-homeContact-height: 46px !default;
$pictos-homeContact: $pictos-homeContact-x $pictos-homeContact-y $pictos-homeContact-width $pictos-homeContact-height pictos-homeContact !default;

@mixin pictos-homeContact-width() {
  width: $pictos-homeContact-width;
}
%pictos-homeContact-width {
  @include pictos-homeContact-width();
}
@mixin pictos-homeContact-height() {
  height: $pictos-homeContact-height;
}
%pictos-homeContact-height {
  @include pictos-homeContact-height();
}
@mixin pictos-homeContact-size() {
  @include pictos-homeContact-width();
  @include pictos-homeContact-height();
}
%pictos-homeContact-size {
  @include pictos-homeContact-size();
}
@mixin pictos-homeContact-position() {
  background-position: $pictos-homeContact-x $pictos-homeContact-y;
}
%pictos-homeContact-position {
  @include pictos-homeContact-position();
}
@mixin pictos-homeContact-adjust() {
  @include pictos-homeContact-size();
  @include pictos-homeContact-position();
}
%pictos-homeContact-adjust {
  @include pictos-homeContact-adjust();
}
@mixin pictos-homeContact() {
  @include pictos-homeContact-adjust();
  @extend %pictos-element;
}
%pictos-homeContact {
  @include pictos-homeContact();
}

