#sidebar_tbd{

	@include breakpoint(medium down){
		

		&.active{

			.top{

				.block-views{

					&:after{
						@include rotate(180deg); 
					}
				}
				
			}

			.bottom{
				max-height: 1000px; 
			}
		}

	}

	.top{
		padding-top: 50px; 
		padding-bottom: 50px; 
		padding-left: 30px;
		padding-right: 30px;

	
		text-align: center;

		@include clearfix; 
		

		@include breakpoint(medium down){
			padding: 40px;
			text-align: center;
			
			

			.block-views{

				display: flex;
				align-items: center; 
				justify-content: center; 


				&:after{
					@include font($zmdi-var-chevron-down); 
					color: #dadbe2;
					display: block;
					margin-left: 40px;
					font-size: 30px;
					@include transition(0.3s); 
					@include rotate(0deg); 

				}
			}

			
			  
		}

		@include breakpoint(small down){
			padding: 20px; 
			text-align: left;
		

			.block-views{
				
				display: block;
				.block_content{
					float: left;
				}

				&:after{
					float: right;
					height: 60px;
					line-height: 60px; 
				}
				
			}

			
		}

		.photo_profil{
			margin-bottom: 25px;
			img{
				display: block; 
				border-radius: 50%; 
				margin: 0px auto; 
				max-width: 100%; 
				height: auto; 

				width: 120px; 


				@include breakpoint(medium down){
					width: 90px; 

				}

				@include breakpoint(small down){
						width: 60px; 
				}
			}
			
			@include breakpoint(medium down){
				display: inline-block; 
				vertical-align: middle;
				margin-right: 40px; 
				margin-bottom: 0;

			}

			@include breakpoint(small down){
				margin-right: 20px;
			}	

		}

		.nom_profil{
			font-weight: $fwSemiBold; 
			font-size: 14px;
			color: #dadbe2;



			@include breakpoint(medium down){
				font-size: 16px;
					display: inline-block; 
				vertical-align: middle;
			}

			&:after{
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 6px 4.5px 0 4.5px;
				border-color: #dadbe2 transparent transparent transparent;
				display: inline-block;
				vertical-align: middle;
				margin-left: 10px; 
				position: relative;
				top: -1px;

				@include breakpoint(medium down){
					display: none; 
				}

			}
		}


		&:hover{
			.deconnexion_profil{
				max-height: 50px; 
			}
		}

		.deconnexion_profil{
			overflow: hidden;
			max-height: 0px; 
			@include transition(0.3s); 

			@include breakpoint(medium down){
				display: none; 
			}


			a{
				font-size: 14px;
				color: #dadbe2;
				text-decoration: underline;
				position: relative;
				margin-top: 25px;
				display: inline-block;
				margin-right: 17px;

				&:hover{
					color: $turquoise-blue;
				}

				&:after{
					content: "";
					@include pictos-deconnexion;
					position: absolute;
					right: -17px; 
					top: 0;
					top: 2px;
				}
			}
		}


	}

	.bottom{

		

		@include breakpoint(medium down){
			@include transition(0.5s); 
			max-height: 0px; 
			overflow: hidden;
		}
		.block{
			border-top: solid 1px #20252f;
			padding-top: 20px;
			margin-bottom: 20px; 

			h2{
				font-size: 14px;
				color: #dadbe2; 
				font-weight: normal;
				margin-bottom: 20px; 
				font-weight: normal;
				padding-left: 40px; 
			}

			.menu{
				> li{
					a{
						display: block; 
						height: 50px; 
						line-height: 50px; 
						font-size: 14px;
						font-weight: $fwSemiBold; 
						text-transform: uppercase; 
						color: #dadbe2; 
						position: relative;
						padding-left: 40px; 

						&:hover{
							color: $turquoise-blue;
						}

						&:before{
							display: inline-block; 
							vertical-align: middle;
							width: 30px; 
							font-size: 20px;
							position: relative;
							margin-top: -2px;

						}

						&.active{
							background: #2a3240; 
							color: #fff; 

							&:after{
								content: "";
								width: 3px;
								height: 100%;
								position: absolute;
								top: 0;
								left: 0;
								background: #b3e614; 
							}
						}

						&.home:before{
							@include font($zmdi-var-home); 
						}
						&.informations:before{
							@include font($zmdi-var-account-circle); 
						}
						&.login:before{
							@include font($zmdi-var-lock); 
						}
						&.commissions:before{
							@include font($zmdi-var-case); 
						}
						&.committee:before{
							@include font($zmdi-var-flag); 
						}
						&.events:before{
							@include font($zmdi-var-calendar-alt); 
						}
						&.documents:before{
							@include font($zmdi-var-file-text); 
						}
						&.favorites:before{
							@include font($zmdi-var-star-outline); 
						}
						&.library:before{
							@include font($zmdi-var-book); 
						}
						&.directory:before{
							@include font($zmdi-var-account-box-phone); 
						}
					}
				}
			}
		}

		.deconnexion_profil_mobile{
			display: none; 
			
			@include breakpoint(medium down){
				display: block;
			}
			
			a{
				display: block;
				width: 100%; 
				background: #2a3240; 
				height: 50px;
				line-height: 50px; 
				text-align: center;
				font-size: 14px;
				color: #dadbe2;
				text-decoration: underline;
				
				&:before{
					content: "";
					@include pictos-deconnexion;
					display: inline-block;
					position: relative;
					top: 2px;
					margin-right: 5px;
				}
			}
		
		}
	}

}