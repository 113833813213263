
#page_formulaire{
	position: relative;
	background: #ecedf2; 

	@include breakpoint(medium down){
		background: #fff; 
	}

	@include fillBackgroundLeft(#fff); 




	.page_formulaire_container{
		position: relative;
		z-index: 50;		
		
		@include clearfix; 
		
		@include breakpoint(xlarge up){
			@include container(1366px); 
		}

		@media screen and (max-width: 1366px) {
		
			@include container(1024px); 

			padding: 0px !important; 
		}

		@include breakpoint(medium down) {
			max-width: 100%; 
		}

	

		#sidebar_formulaire{
			float: right;
			background: #ecedf2; 
			width: 377px;
			
			padding: 85px 45px;

			@media screen and (max-width: 1366px) {
				width: 304px;
			}

			@include breakpoint(large up){
				display: block ; 
			}

			@include breakpoint(medium down){
				width: 100%; 
				padding-top: 30px;
				
			}
		}

		#content_formulaire{
			background: #fff;
			width: 989px; 
			float: left;
			padding: 85px 120px;
			padding-bottom: 55px;
			min-height: 520px; 
			padding-top: 40px;



			@media screen and (max-width: 1366px) {
				width: 720px;
				padding-left: 45px;
				padding-right: 45px;
			}

			@include breakpoint(medium down) {
				padding-left: 20px;
				padding-right: 20px;
			}

			

			@include breakpoint(medium down){
				width: 100%; 
			}

		}

	}
	

	#section_home_avantages{
		background: $turquoise-blue; 
		position: relative;
		z-index: 555;
		padding-top: 50px;
		padding-bottom: 50px;
	
		.home_avantages_block{
			margin-bottom: 0; 
			padding-bottom: 30px;
		}

		.home_avantages_block .avantage_carousel_block .owl-nav > div{
			background: $muted-blue; 
		}
	}

}


#step_confirmation{
	position: relative;
	z-index: 333;
	background: #ecedf2; 
	padding-top: 90px;
	padding-bottom: 100px;
	display: none; 

	.step_confirmation_container{
		@include container(880px);

		.recap{
			background: #fff; 
			padding: 60px 65px; 

			@include breakpoint(small down){
				padding: 30px 25px; 
			}


			h2{
				font-family: $ffT; 
				font-size: 24px;
				font-weight: $fwBold; 
				color: $muted-blue;
				padding-bottom: 18px;
				border-bottom: 1px solid $muted-blue;
				margin-bottom: 35px;
				width: 100%; 
				position: relative;
				z-index: 555;
				@include breakpoint(medium down){
					text-align: center;
			
				}

				.print{
					position: absolute;
					right: 0;
					top: 5px;
					font-family: $ffP; 
					font-size: 14px;

					color: $muted-blue;
					text-decoration: underline;
					font-size: 14px;
					font-weight: normal;

					@include breakpoint(small down){
						position: relative;
						display: block;
						padding: 10px; 
						margin-top: 10px;
						background: $muted-blue; 
						color: #fff; 
						text-decoration: none; 
					}

					&:before{
						@include font($zmdi-var-print); 
						position: absolute;
						left: -20px; 
						top: 0;

						@include breakpoint(small down){
							position: relative;
							left: auto; 
							margin-right: 5px;
						}
					}

					&:hover{
						color: $turquoise-blue; 
					}
				}
			}

			.block_recap{

				&:not(:last-child){
					margin-bottom: 35px; 
					padding-bottom: 30px;
					border-bottom: solid 1px #cacaca; 
				}

				> h3{
					color: $muted-blue;
					margin-bottom: 17px; 
					font-weight: $fwSemiBold; 
					font-size: 16px;
				}

				ul{
					li{
						&:not(:last-child){
							margin-bottom: 10px;
						}

						font-size: 17px; 
						color: #3d3d3d; 
						@include clearfix; 

						.price{
							float: right;
							font-weight: $fwSemiBold; 
						}

					}
				}
			}
		}

		.total_recap{
			background: $turquoise-blue; 
			@include clearfix; 
			padding: 25px 65px; 
			font-size: 20px;
			font-weight: $fwBold; 
			color: #fff; 
			margin-bottom: 55px; 
			.label{
				float: left;
			}

			.price{
				float: right;
			}
		}

		.step_actions{
			margin-top: 60px;
		}
	}
}

.row_cgv{

	@include breakpoint(medium up){
		@include clearfix; 
		margin: 0px -15px; 
	}

	> .cgv_block{
		@include breakpoint(medium up){
			width: 50%; 
			padding: 0px 15px; 
			float: left; 
		}

		@include breakpoint(small down){
			margin-bottom: 20px;
		}

		.inside{
			background: #fff; 
			height: 250px;
			overflow: auto; 
			padding: 15px; 

			p{
				font-size: 13px; 
				line-height: 17px;

				&:not(:last-child){
					margin-bottom: 15px; 
				}
			}
		}
	}
}