
.section_bottom_page{

	padding: 70px 0px;

	@include breakpoint(small down){
		padding: 45px 0px;
	}
	.container{
		@include container(945px);
	}

	.owl-carousel .owl-stage-outer{
		overflow: initial;
	}

	h2{
		margin-bottom: 20px;
		font-family: $ffT;
		font-size: 26px;
		font-weight: $fwBold;
		color: $muted-blue;
		@include clearfix; 

		@include breakpoint(small down){
			text-align: left; 
		}

		a{
			float: right;
			font-size: 14px;
			font-weight: $fwSemiBold;
			text-decoration: underline;
			color: $muted-blue;
			margin-top: 10px;
			font-family: $ffP;

			@include breakpoint(small down){
				display: none; 
			}

			&:hover{
				color: $secondColor; 
			}
		}
	}

	&#section_bottom_page_actualites{
		background-color: $pale-grey;
	}

	&#section_bottom_page_evenements{
		background-color: #dadbe2;
	}

	&#section_bottom_page_annuaire{
		background-color: $muted-blue; 

		h2{
			color: #fff; 
			margin-bottom: 30px; 

			a{
				color: #fff;
			}
		}
	}

	&#section_bottom_page_user_members, &#section_bottom_page_user_commissions{
		background: #dadbe2; 
		padding-top: 115px;

		.commission_teaser .fav a.unflag-action:after{
			display: none; 
		}
	}

	&#section_bottom_page_user_publication{
	 	background: #fff; 
	}


	.see_all_link_mobile{
		display: none; 
		font-size: 14px;
		font-weight: $fwSemiBold;
		text-decoration: underline;
		color: $muted-blue;
		margin-top: 40px;
		text-align: left;
		
	

		&:hover{
			color: $secondColor; 
		}
		
		@include breakpoint(small down){
			display: block;
		}

	}
}



.node-type-commission{
	.section_bottom_page{
		h2{
			text-transform: uppercase;
			font-family: $ffP; 

			a{
				text-transform: none; 
			}
		}
	}
}