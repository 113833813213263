.tab_content_menu{

	> ul{
		border: solid 1px #e1e1e1;
		width: 240px; 
		z-index: 555; 
		background: #fff; 
		overflow: auto; 
		
		@include breakpoint(small down){
			width: 100%; 

			padding-top: 46px; 
			position: relative;
			max-height: 46px; 
			overflow: hidden;
			top: 0 !important;
			@include transition(0.3s); 

			&.open{
				max-height: 1000px;  
			}

			> li.item-active{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%; 

				a{
					position: relative;
					&:after{
						@include font($zmdi-var-chevron-down); 
						height: 46px;
						line-height: 46px; 
						position: absolute;
						top: 0;
						right: 20px; 
					}
				}
			}
		}
		

		&.sticky{
			@include breakpoint(xlarge up){
				position: fixed;
			}
		}

		> li{
			&:not(:last-child){
				border-bottom: solid 1px #e1e1e1;
			}

			.hidden_content{
				display: none; 
			}

			> a{
				display: block;
				padding: 13px 17px; 
				color: $black; 
				font-size: 16px;
				font-weight: $fwSemiBold; 
				line-height: 20px; 
				@include breakpoint(medium only){
					padding-left: 5px; 
					padding-right: 5px;
					font-size: 14px;
				}

				&:hover{
					background: #edeef2;
				}

				&.active{
					background: #edeef2;
					color: $muted-blue;
				}
			}

			&.accordion{
				> a{
					position: relative;
					&:after{
						@include font($zmdi-var-chevron-up); 
						color: $turquoise-blue; 
						font-size: 16px;
						height: 20px; 
						line-height: 20px; 
						position: absolute;
						right: 20px; 
						top: 50%; 
						margin-top: -10px; 
						@include rotate(180deg); 
						@include transition(0.3s); 
					}

					&.active{
						&:after{
							@include rotate(0deg); 
						}
					}
				}

			
				.hidden_content{
					padding: 20px; 
					background: #e3e4e8; 
					display: none; 
					max-height: 200px; 
					overflow: auto; 

					@media screen and (max-height: 870px ) {
						max-height:  150px; 
					}

					> p{
						font-size: 12px; 
						padding-top: 10px;
						margin-bottom: 10px;
						color: #303030; 
					}

					ul.list_accreditations{
						@include clearfix; 
						> li{
							font-size: 12px;
							color: #303030;
							line-height: 17px; 
							font-weight: $fwSemiBold;
							word-break: break-word; 

							p{
								display: inline; 
							}

							&:before{
								content: "• ";
								color: $turquoise-blue;
								float: left; 
								font-size: 15px;
								margin-right: 5px;
							}
							
							span{
								color: $muted-blue;
								font-weight: normal;
							}

							&:not(:last-child){
								margin-bottom: 13px; 
							}
						}
					}

					ul.list_documents{

						> li{
							&:not(:last-child){
								margin-bottom: 10px;
							}
						}

					}

					.block_libre_sommaire{
						h2{
							text-transform: uppercase;
							font-size: 14px;
							font-weight: $fwSemiBold; 
							margin-bottom: 15px;
							color: $muted-blue; 
						}

						p{
							font-size: 12px;
							line-height: 15px; 
							&:not(:last-child){
								margin-bottom: 10px;
							}
						}

						ul{
							> li{
								font-size: 12px;
								&:before{
								content: "• ";
								color: $turquoise-blue;
							}
								&:not(:last-child){
									margin-bottom: 10px;
								}
							}
						}
					}
				}
			}
		}

		@media screen and #{breakpoint(medium)} and #{breakpoint(large down)} {

			&:not(.event_node_menu){

				width: 100%; 
				table-layout: fixed;
				display: table; 

				> li{
					
					display: table-cell; 
					border-bottom: none !important; 

					a{
						text-align: center;
						
					}
				}

			}
		}


	}
}