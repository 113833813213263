#node_event{

	
	position: relative;

	.tool_bar{
		position: absolute;
		top: 0px; 
		right: 0;
		z-index: 55; 
	}

	.map_gabarits{
		height: 600px;
		width: 100%; 

		@include breakpoint(medium down){
			height: 400px;
		}

		@include breakpoint(small down){
			height: 250px;
		}
	}

	.node_base_container{
		@include container(1262px); 
		background: #fff; 
		position: relative;

		@include clearfix; 

		@media screen and #{breakpoint(medium)} and #{breakpoint(large down)} {
			max-width: 90%; 
			padding-left: 260px; 
		}

		@include breakpoint(medium only){
			padding-left: 200px;
		}

		@include breakpoint(small down){
			padding-top: 60px;
		}
		
		#node_event_content{
			padding-top: 50px;
			width: 1022px; 
			float: right;
			padding: 40px; 
			padding-bottom: 0;

			@media screen and #{breakpoint(medium)} and #{breakpoint(large down)} {
				float: none; 
				width: 100%; 
				padding-left: 20px;
				padding-right: 20px;
			}

			@include breakpoint(small down){
				width: 100%; 
				padding-left: 0px;
				padding-right: 0px;
				float: none; 
				padding-top: 0;
			}
		}

	
		.tab_content_menu{
			width: 240px; 
			
			position: relative;
			margin-top: 35px; 
			float: left;
			z-index: 555;
			margin-bottom: 50px;

			@media screen and #{breakpoint(medium)} and #{breakpoint(large down)} {
				position: absolute;
				top: 0;
				left: 0;
			}	

			@include breakpoint(medium only){
				width: 190px; 

				> ul{
					width: 190px; 
				}
			}

			@include breakpoint(small down){
				float: none; 
				width: 100%; 
				position: relative;
			}
			
		}

		.tab_content{
			&:not(.active){
				display: none; 
			}
		}
	}


	.block_event{
		h2{
			font-size: 26px;
			text-transform: uppercase;
			font-weight: $fwBold; 
			color: $muted-blue;
			margin-bottom: 35px; 
		}

		&:not(:last-child), &:not(.full_bg){
			margin-bottom: 50px;
			padding-bottom: 50px; 
			border-bottom: solid 1px #e1e1e1;
		}

		&.no_border{
			border-bottom: none; 
		}

		&.full_bg{
			position: relative;

			padding: 50px 0;

			&.grey{
				background: #edeef2; 
				@include fillBackgroundRightOutside(#edeef2); 
				@include fillBackgroundLeftOutside(#edeef2); 
			}
		
			&.greyDark{
				background: #dadbe2; 
				@include fillBackgroundRightOutside(#dadbe2); 
				@include fillBackgroundLeftOutside(#dadbe2); 

			}
		}

		#top_listing{
			padding: 0;
			margin-top: 65px; 
			max-width: 100%; 
		}
	}

	.rte{
		margin-bottom: 50px;
	}

	#program_results{
		padding-top: 45px;

		@include breakpoint(small down){
			padding-top: 20px;
		}
		p.results_text{
			
			font-family: $ffT;
			font-size: 18px;
			font-weight: $fwBold; 
			font-style: italic;
			color: #858585;
			display: block;
			margin-bottom: 45px;
		}
	}

	#partners{
		

		.full_bg{
			padding-bottom: 75px; 
		}
	}

	.tool_bar ul > li.print{
		display: none; 
	}
	.tool_bar ul > li.favorite a .tooltip, .tool_bar ul > li .tooltip{
		left: auto; 
		right: 57px;
		top: 0;
		
		@include breakpoint(small down){
			top: -60px; 
			right: 0px !important; 
		}


		&:after{
			right: -7px;
			top: 50%;
			margin-top: -8px;
			left: auto; 
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 8px 0 8px 7px;
			border-color: transparent transparent transparent #edeef2;

			@include breakpoint(small down){
				@include rotate(90deg); 
				right: 21px !important;
				margin-top: 0 !important;
				top: 46px;
			}

		}
	}

	 .tool_bar ul > li .tooltip{
			right: 60px;
	 }
}

#participants_list_container{
	margin-top: 50px;
	#results_text{
		float: none; 
		margin-bottom: 40px;
	}

	#top_listing{
		margin-top: 0;
	}
}

#program_results{
	#top_listing{
		margin-top: 0 !important;
	}
}