.list-media_links{

	margin-bottom: 50px !important;

	@include breakpoint(large up){
					
		@include row(30); 

		> li {

			@include grid-column(6,  30);

		}
	}

	@include breakpoint(medium down){
		> li{
			&:not(:last-child){
				margin-bottom: 30px;
			}
		}
	}
}


.list-media_links{

	> li{
		.bg{
			position: relative;
			overflow: hidden;
			display: block;

			&:hover{
				.hover{
					background: rgba(#000, 0.5); 
				}

				img{
					@include scale(1.2); 
					@include transition(0.3s); 
				}
			}

			img{
				display: block;
				width: 100%; 
				height: auto; 
				@include scale(1); 
				@include transition(0.3s); 
			}

			.hover{
				position: absolute;
				width: 100%; 
				height: 100%;
				top: 0;
				left: 0;
				background: rgba(#000, 0.3); 
				display: flex;
				align-items: center; 
				justify-content: center; 
				@include transition(0.3s); 

				.button{
					@include button($muted-blue, $turquoise-blue, auto, inline-block); 

					&:before{
						@include font($zmdi-var-collection-image)
						font-size: 18px;
						margin-right: 8px;
					}
				}
			}



		}
	}
}