@mixin button($bgColor, $bgColorHover, $width, $display) {


	height: 50px;
	line-height: 50px; 
	width: $width; 
	display: $display; 
	color: #fff;
	background: $bgColor; 
	padding: 0 25px;
	text-align: center;
	font-size: 16px;
	font-weight: $fwSemiBold; 
	font-family: $ffP; 

	&:hover {
		background: $bgColorHover; 
		text-shadow: none; 
	}
}



@mixin buttonBorder($textColor, $bgTextHover, $display:inline-block, $width:auto, $bgColor:transparent) {
	
	height: 50px;
	line-height: 50px; 
	width: $width; 
	display: $display; 
	color: $textColor;
	background: $bgColor; 
	padding: 0 30px;
	text-align: center;
	font-size: 16px;
	font-weight: $fwSemiBold; 
	font-family: $ffP; 
	border: solid 1px $textColor; 


	&:hover {
		background: $textColor; 
		color: $bgTextHover; 
		text-shadow: none; 
	}
}



@mixin buttonBig($bgColor, $bgColorHover, $display:inline-block, $width:auto) {
	
	height: 62px;
	line-height: 62px; 
	width: $width; 
	display: $display; 
	color: #fff;
	background: $bgColor; 
	padding: 0 30px;
	text-align: center;
	font-size: 18px;
	font-weight: $fwSemiBold; 
	font-family: $ffP; 


	&:hover {
		background: $bgColorHover;
	}
}

@mixin buttonPreview($bgColor: $pale-grey, $bgColorHover : $muted-blue, $display:inline-block, $width:auto) {
	height: 45px;
	line-height: 45px; 
	width: $width; 
	display: $display;
	padding: 0px 20px;

	color: $bgColorHover;
	background: $bgColor; 
	text-align: center;
	font-size: 16px;
	font-weight: normal; 
	font-family: $ffP; 

	&:before{
		@include font($zmdi-var-eye); 
		margin-right: 7px; 
	}



	&:hover {
		background: $bgColorHover; 
		color: $bgColor; 
	
	}
}


@mixin buttonDownload($bgColor:$muted-blue , $bgColorHover : $pale-grey, $display:inline-block, $width:auto) {
	height: 45px;
	line-height: 45px; 
	width: $width; 
	display: $display;
	padding: 0px 20px;

	color: $bgColorHover;
	background: $bgColor; 
	text-align: center;
	font-size: 14px;
	font-weight: normal; 
	font-family: $ffP; 

	small{
		font-size: 12px;
		font-weight: $fwLight; 
		font-style: italic; 
	}

	&:before{
		@include font($zmdi-var-download); 
		margin-right: 7px; 
		position: relative;
		top: 1px;
	}



	&:hover {
		background: $bgColorHover; 
		color: $bgColor; 
	
	}
}


@mixin buttonAddField{
	display: block;
	border: dashed 2px #dadbe2;
	text-align: center;
	height: 45px;
	line-height: 45px; 
	font-size: 16px; 
	color: #3d3d3d;
	background: #fff; 

	&:before{
		@include font($zmdi-var-plus-circle); 
		color: $turquoise-blue; 
		font-size: 16px;
		margin-right: 12px;
	}

	&:hover{
		color: #fff;

		&:before{
			color: #fff; 
		}

		background:  $turquoise-blue; 
	}

}

@mixin seePrevious(){
	margin: 0px auto; 
	text-decoration: underline;
	position: relative;
	color: $black; 
	margin-left: 30px;
	font-size: 16px;

	&:before{
		content: "";
		@include font($zmdi-var-time-restore-setting); 
		color: $muted-blue;
		position: absolute;
		left: -25px;
		font-size: 18px;
	}

	&:hover{
		color: $muted-blue;
	}
}

