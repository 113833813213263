#header_top{
	height: 36px;
	background-color: $muted-blue;
	position: relative;
	width: 100%;
	@include clearfix;
	z-index: 9555;

	@include breakpoint(small down){
		display: none;
	}

	.left{
		float: left;
	}

	.right{
		float: right;
	}

	.block{
		float: left;
	}

		.header_top_block_menu{
			.block_content > .menu, .block_content > .tabs{
				> li{
					float: left;
					position: relative;

					> a, > span{
						display: block;
						height: 36px;
						line-height: 36px;
						color: #fff;
						font-size: 12px;
						padding: 0px 15px;

						&.about{
							border-left: solid 1px #274573;
							border-right: solid 1px #274573;

						}

						&.icon{
							&:before{
								content: "";
								display: inline-block;
								vertical-align: middle;
								margin-right: 8px;
								margin-top: -2px;
							}

							&.barreaux{
								padding-right: 5px;
								&:before{
									@include pictos-barreaux;
								}
							}

							&.partenaires{


								&:before{
									@include pictos-partenaires;
								}
							}

							&.presse{
								padding-left: 5px;
								&:before{
									@include pictos-presse;
								}
							}
						}

						&:hover{
							color: $greenish-yellow;
						}
					}

					&.expanded{

						> .menu{
							position: absolute;
							left: 0;
							top: 36px;
							z-index: 999;
							width: 100%;
							overflow: hidden;
							max-height: 0px;
							@include transition(0.3s);

							&:after{
								content: "";
								width: 2px;
								height: 100%;
								position: absolute;
								background: $turquoise-blue;
								top: 0;
								left: 0;
							}

							> li{
									background: $dark-slate-blue;
								&:first-child{
									padding-top: 5px;
								}

								&:last-child{
									padding-bottom: 5px;
								}

								a{
									font-size: 12px;
									color: #fff;
									background: $dark-slate-blue;
									padding: 10px 15px;
									display: block;
									text-align: right;
									&:hover{
										color: $greenish-yellow;
									}
								}
							}


						}

						&:hover{
							> a, > span{
								color: #fff;
								background: $dark-slate-blue;
								&:after{
									content: "";
									width: 2px;
									height: 100%;
									position: absolute;
									background: $turquoise-blue;
									top: 0;
									left: 0;
								}
							}

							> .menu{
								max-height: 500px;
							}
						}
					}
				}
			}
		}

		.header_top_block_lang{
			border-left: solid 1px #274573;
			border-right: solid 1px #274573;
			width: 60px;
			height: 36px;
			position: relative;

			&:after{
				@include font($zmdi-var-chevron-down);
				position: absolute;
				top: 11px;
    			right: 10px;
				color: $greenish-yellow;
				font-size: 14px;
				@include transition(0.3s);
			}

			&:hover{
				.language-switcher-locale-url{
					max-height: 150px;
				}

				&:after{
					@include rotate(180deg);
				}
			}

			.language-switcher-locale-url{
				padding-top: 36px;
				overflow: hidden;
				max-height: 36px;
				@include transition(0.3s);
				li{
					a, span{
						display: block;
						width: 60px;
						display: block;

						font-size: 12px;
						line-height: 36px;
						text-align: center;
						text-transform: uppercase;
						color: $black;
						background: $greyBG;
					}

					&:not(.active):not(:last-child){
						border-bottom: solid 1px rgba(179, 178, 178, 0.57);
					}

					&:not(.active){
						a{
							&:hover{
								background: $silver;
								color: $muted-blue;
							}
						}
					}

					&.active{
						position: absolute;
						top: 0;
						left: 0;
						cursor: pointer;

						a{
							color: #fff;
							background: none;
							text-align: left;
							padding-left: 15px;
							pointer-events: none;
						}
					}
				}
			}
		}

		.header_top_block_search{
			width: 47px;
			height: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
			@include transition(0.3s);
			cursor: pointer;

			i{
				@include pictos-loupeBlanche;
				display: block;
			}

			&:hover, &.active{
				background:  $greenish-yellow;

				i{
					@include pictos-loupeBleu;
				}
			}
		}

		.header_top_block_account{
			a.mySpace{
				display: block;
				background:  $dark-slate-blue;
				width: 145px;
				line-height: 36px;
				height: 36px;
				text-transform: uppercase;
				color: #fff;
				font-size: 13px;
				font-weight: $fwSemiBold;
				text-align: center;
				overflow: hidden;
				z-index: 8810;
				position: relative;

					&:hover{
						color: $greenish-yellow;
					}

				&.notLoggedIn{
					&:after{
						@include font($zmdi-var-chevron-down);
						display: inline-block;
						vertical-align: middle;
						color: $greenish-yellow;
						font-size: 15px;
						@include transition(0.3s);
						margin-left: 10px;
						margin-top: -1px;
					}



					&.active{
						&:after{
							@include rotate(180deg);
						}
					}
				}

				&.loggedIn{
						&:before{
						@include font($zmdi-var-account);
						display: inline-block;
						vertical-align: middle;
						color: $greenish-yellow;
						font-size: 15px;
						@include transition(0.3s);
						margin-right: 10px;
						margin-top: -1px;
					}
				}
			}

			#open_login_form{
				position: relative;

        .header_top_login_block {
          @extend %login-popup-block;
        }
			}
		}
}

.page-user-login{
	#header_top .header_top_block_account a.mySpace{
		display: none;
	}
}


body.i18n-es{
	#header_main .container > .block.header_main_menu_block .block_content > .menu > li:not(:last-child) > a{
		padding-right: 60px;
	}

	#header_main.sticky .container > .block.header_main_menu_block .block_content > .menu > li{
		width: auto;

		a{
			padding-right: 25px;
		}
	}

	@include breakpoint(large only){
		#header_main .container > .block.header_main_menu_block .block_content > .menu > li > a{
			font-size: 17px !important;

		}
	}
}

body.adminimal-menu{
	#header_main.sticky .inside{
		top: 28px;
	}
}

