.owl-dots{

	@include clearfix; 

	.owl-dot{

		float: left;

		&:not(:last-child){
			margin-right: 20px; 
		}

		width: 9px;
		height: 9px;
		display: flex; 
		justify-content: center; 
		align-items: center; 


		span{
			width: 7px; 
			height: 7px;
			display: block; 
			border-radius: 50%;
			opacity: 0.5; 
			@include transition(0.3s);
			background: $black; 
		}


		&.active{

			span{
				width: 9px; 
				height: 9px;
				opacity: 1; 
				
			}

		}
	}


}