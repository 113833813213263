/*** FIX ***/

.page-account-my-documents
  #page_tbd
  .form-item
  .field-widget-options-select
  .form-type-multiple-fieldtext
  > label {
  display: none;
}

/*** Fin de fix ***/

#page_formulaire,
form.form_filter,
#page_contact_formulaire,
#form_account_edit,
.node-rapport-form {

  .field-name-field-fichier {
    .file-widget {
      .file-icon {
        display: none;
      }

      a {
		color: $tealish;
		font-size: 14px;

        &:hover {
          color: $muted-blue;
        }
      }

      .file-size {
        font-size: 12px;
        font-style: italic;
        margin-left: 10px;
        margin-right: 10px;
        color: $brownish-grey;
	  }

	  input[name=field_fichier_und_0_remove_button]{
		  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAASCAYAAABrXO8xAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgTWFjaW50b3NoIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjA2QTMyMTY1Mjg1QjExRTk4NzBFQjhGRTUxQzU0QzlGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjA2QTMyMTY2Mjg1QjExRTk4NzBFQjhGRTUxQzU0QzlGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MDZBMzIxNjMyODVCMTFFOTg3MEVCOEZFNTFDNTRDOUYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDZBMzIxNjQyODVCMTFFOTg3MEVCOEZFNTFDNTRDOUYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6wA5h0AAABXklEQVR42pSTTytFURTFz71u/g08BvQiRjIgUwMDEx9AmSAlUT4ARj6BkZKilIGBYihRkoEZczMlGRASnkvhca1d69xWpzfg1O+edTp77b3PO+dFWZa5cJRHp9cwdYEIXCfbG5NhTPQ1MtWGeQxUg2/QC8aDuCNwDGLwA/YSfCbAHCgBK/8BDgJjO5hhB7W2NuMKuABFZsyYNabJ64jaEm9Fdkac6QSLAfe3cYczF33WS84LYJd6HSxSn4F56nMn7TxL8D61JdgU4zJ1ap+EiyfOPZKslb+yL9BN/agVvbEOvPirkv13XlceG7ZaL/rfxlTaU2OhktG3V+A9hRUtcQt1qZKxUYxVsm/BzdQPLthwshm2+sqk+Z3HmgWjQ65IW/0EDdT3uRFPKBXjlSS7lSo11G/6ABz/NoNgletZOfsSGAI3KFLWVm0Mgx0+dntiTaATnII+cAj6ffCvAAMAVYxdH0sYTKgAAAAASUVORK5CYII='); 
		  width: 14px; 
		  height: 18px;
		  border: none; 
		  font-size: 0;
		  appearance: none; 
		  display: inline-block;
	vertical-align: middle;
	cursor: pointer; 

	  }
	  

    }
  }

  #field-barreau-values {
    clear: both;
  }

  .vertical-tabs-list,
  .filter-wrapper {
    display: none;
  }

  .mandatory_text {
    color: #858585;
    font-size: 16px;
    font-style: italic;
    margin-bottom: 30px;

    span {
      color: $muted-blue;
    }
  }

  .fields_block {
    &:not(:last-child) {
      margin-bottom: 80px;
    }

    > legend {
      display: block;

      font-family: $ffT;
      font-size: 24px;
      font-weight: $fwBold;
      color: $muted-blue;
      padding-bottom: 18px;
      border-bottom: 1px solid $muted-blue;
      margin-bottom: 35px;
      width: 100%;

      em {
        font-style: italic;
        font-size: 18px;
        font-weight: normal;
      }
    }
  }

  label,
  .field_block_legend_as_label legend,
  .date-combo legend {
    display: block;
    color: $muted-blue;
    margin-bottom: 10px;
    font-weight: $fwSemiBold;

    small {
      font-size: 12px;

      @include breakpoint(small down) {
        display: block;
        margin-top: 5px;
      }
    }

    em {
      font-weight: normal;
      font-style: italic;
    }
  }

  .label_small {
    label {
      font-size: 16px;
      font-weight: $fwNormal;
    }
  }

  .field_row {
  }

  .form-text,
  .form-select,
  .select2-container--default
    .select2-selection--single
    .select2-selection__rendered,
  .select2-selection--multiple,
  .form-textarea {
    display: block;
    width: 100%;
    padding-left: 15px;
    height: 45px;
    border: solid 1px #bfbfbf;
    outline: none;
    color: #3d3d3d;
    font-size: 16px;
    appearance: none;
    background: #fff;
    border-radius: 0;
    outline: none;

    &.error {
      border-color: $red;
    }
  }

  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  .select2-selection--multiple {
    line-height: 28px;
    padding-right: 50px;
    height: auto;

    min-height: 45px;
  }

  .form-text,
  .form-textarea {
    @include input-placeholder {
      color: #858585;
    }

    &:focus {
      @include input-placeholder {
        color: #bbb7b7;
      }
    }
  }

  .form-textarea {
    height: 215px;
    padding-top: 15px;
    resize: none;
  }

  .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 45px;
  }

  .select2-selection__rendered {
    padding: 0px;
    @include clearfix;

    > li {
      background-color: transparent;
      border: none;
      cursor: default;
      float: left;
      padding: 0;
      margin: 0;

      margin-right: 10px;
    }
  }

  .select2-container {
    width: 100% !important;
  }

  .select2-container .select2-selection--single {
    height: auto;
    border: none;
    border-radius: 0px;

    &:focus {
      outline: none;
    }
  }

  .select2-selection__arrow {
    display: none;
  }

  .form-type-select {
    position: relative;

    &:after {
      @include font($zmdi-var-chevron-down);
      font-size: 20px;
      color: $turquoise-blue;
      height: 45px;
      line-height: 45px;
      position: absolute;
      bottom: 0;
      right: 25px;
      pointer-events: none;
    }
    select {
      padding-right: 35px;

      &[disabled] {
        background: #ecedf2 !important;
      }
    }
  }

  .form-type-multiple-fieldtext {
    @include clearfix;

    .messages {
      margin-bottom: 15px;
      &.error {
        color: $red;
        font-style: italic;
      }
    }

    td {
      margin: 0 !important;
    }
    tbody {
      tr {
        float: left;
        width: 49%;
        margin-bottom: 20px;
        @include breakpoint(small down) {
          width: 100%;
          float: none;
        }
        .ajax-new-content {
          margin: 0 !important;
        }

        &:nth-child(2n) {
          float: right;
        }
      }
    }

    .clearfix {
      float: right;
      width: 49%;

      @include breakpoint(small down) {
        width: 100%;
        float: none;
      }
    }
  }

  .form-type-plupload,
  #edit-field-fichier {
    .description {
      font-size: 14px;
      font-style: italic;
      margin-top: 10px;
      color: #716d6d;
      font-weight: normal;
      line-height: 20px;

      strong {
        font-weight: $fwSemiBold;
      }
    }
  }

  #edit-field-fichier {
    .droppable {
      width: 100%;
      background: #fff;
      border: solid 1px #bfbfbf;

      .droppable-browse-button {
        color: $mainColor;
      }
    }
  }

  .form-type-textfield {
    position: relative;

    #autocomplete {
      position: absolute;

      top: 40px;
      left: 0;
      padding-left: 22px;
      margin-left: 1px;
      background: #fff;

      padding-top: 15px;
      cursor: pointer;
      padding-right: 20px;
      padding-bottom: 15px;
      z-index: 5555;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);

      li {
        @include transition(0.3s);
        &:hover {
          color: $muted-blue;
        }

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }

  .form-item {
    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }

  .force_margin {
    .form-item {
      margin-bottom: 35px !important;
    }
  }

  #edit-locale,
  #edit-timezone,
  #edit-field-crm-id,
  #edit-field-crm-last-update {
    display: none;
  }

  #account_edit {
    .description {
      display: none;
    }

    .form-item-pass {
      .password-strength-title,
      .password-suggestions,
      .password-strength-text,
      div.password-confirm {
        display: none !important;
      }
    }
  }

  .special_checkbox_radio {
    margin-bottom: 35px;

    .form-radios,
    .form-checkboxes {
      @include clearfix;

      margin-bottom: -15px;
      .form-item {
        float: left;
        margin-bottom: 15px;
        /*	width: 175px; */

        @include breakpoint(small down) {
          width: 100%;
          margin-right: 0;
        }

        &:not(:last-child) {
          margin-right: 15px;
        }

        input {
          display: none;
        }

        label {
          display: block;
          margin-bottom: 0;
          width: 100%;
          height: 45px;
          line-height: 45px;
          text-align: center;
          font-size: 16px;
          color: #3d3d3d;
          background: #ecedf2;
          font-weight: normal;
          cursor: pointer;
          user-select: none;
          padding-left: 25px;
          padding-right: 25px;
        }

        input:checked + label {
          color: #fff;
          background: $turquoise-blue;

          &:before {
            @include font($zmdi-var-check) font-size: 16px;
            margin-right: 7px;
          }
        }
      }
    }

    &.two_cols {
      .form-radios,
      .form-checkboxes {
        .form-item {
          width: 49%;
          margin-right: 0;
          &:nth-child(2n) {
            float: right;
          }

          &.form-item-submitted-a-sinvestir-dans-luia-irol-souhaitez-vous-assurer-pour-le-compte-de-luia-des-missions--7 {
            width: 100%;
            label {
              height: auto;
              line-height: 20px;
              padding-top: 10px;
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .form-type-date-popup {
    .date-padding {
      label,
      .description {
        display: none;
      }

      position: relative;
      max-width: 180px;

      &:after {
        @include font($zmdi-var-calendar-alt);
        position: absolute;
        color: $turquoise-blue;
        font-size: 16px;
        height: 45px;
        line-height: 45px;
        top: 0;
        right: 15px;
        pointer-events: none;
      }
    }
  }

  .form-type-password {
    position: relative;

    span.toggle_password_show {
      height: 45px;
      line-height: 45px;
      position: absolute;
      bottom: 0;
      right: 15px;
      cursor: pointer;

      color: $turquoise-blue;

      &:hover {
        color: $muted-blue;
      }

      &.active {
        color: $brownish-grey;
      }

      &:after {
        @include font($zmdi-var-eye);

        font-size: 16px;
      }
    }
  }

  .col-5 {
    @include clearfix;
    margin: 0px -8px;

    &:not(.field_row) {
      margin-bottom: -15px;
    }

    @include breakpoint(medium up) {
      &.field_row {
        .fieldset-wrapper {
          margin-bottom: -15px;
        }
      }
    }

    .form-item {
      margin-bottom: 16px;
      width: 20%;
      padding: 0px 8px;
      float: left;

      @include breakpoint(medium down) {
        width: 33.333%;
      }

      @include breakpoint(small down) {
        width: 50%;
      }

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }

  .col-3 {
    margin: 0px -10px;
    &:not(.field_row) {
      margin-bottom: -20px;
    }
    &.field_row {
      .fieldset-wrapper {
        margin-bottom: -20px;
      }
    }

    @include clearfix;

    .form-item {
      margin-bottom: 20px;
      width: 33.333%;
      padding: 0 10px;
      float: left;

      @include breakpoint(medium down) {
        width: 50%;
      }

      @include breakpoint(small down) {
        width: 100%;
      }
    }
  }
  .col-2 {
    @include clearfix;
    margin: 0px -8px;

    &:not(.field_row) {
      margin-bottom: -15px;
    }
    @include breakpoint(medium up) {
      &.field_row {
        .fieldset-wrapper {
          margin-bottom: -15px;
        }
      }
    }

    .col {
      margin-bottom: 16px;
      width: 50%;
      padding: 0px 8px;
      float: left;

      @include breakpoint(small down) {
        width: 100%;
      }
    }

    &.field_block_legend_as_label {
      legend {
        padding-left: 8px;
      }
    }
  }

  .col-4 {
    margin: 0px -6px;

    @include clearfix;

    .col {
      width: 25%;
      padding: 0 6px;
      float: left;

      @include breakpoint(medium down) {
        width: 50%;
      }

      @include breakpoint(medium down) {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    &.field_block_legend_as_label {
      legend {
        padding-left: 6px;
      }
    }
  }

  .field_row {
    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }

  .checkbox_bottom_container {
    margin-top: 80px;

    &#edit-membership-contact-information-bottom-checkbox-bottom {
      margin-top: -50px;
    }
  }

  .element-invisible {
    display: none !important;
  }

  .checkbox_container {
    .form-item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      input {
        display: none;
      }

      label {
        min-height: 22px;
        line-height: 20px;
        padding-left: 35px;
        position: relative;
        color: #292929;
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 0;
        cursor: pointer;

        strong {
          font-weight: $fwSemiBold;
          font-style: italic;
          color: $turquoise-blue;
        }

        a {
          color: #292929;
          text-decoration: underline;

          &:hover {
            color: $turquoise-blue;
          }
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          background: #fff;
          border: solid 1px #bfbfbf;
          line-height: 20px;
          text-align: center;
          color: $turquoise-blue;
          font-size: 12px;
        }
      }

      input:checked + label {
        &:before {
          @include font($zmdi-var-check);
        }
      }

      input[disabled="disabled"] + label {
        color: #8e8e8e;

        a {
          color: #8e8e8e;
        }

        &:before {
          background: #ecedf2;
        }
      }
    }
  }

  .form-item-profile-coordonnees-field-utiliser-infos-en-cor-und,
  .form-item-profile-coordonnees-field-informations-dans-annuaire-und {
    > label {
      display: none;
    }
  }

  .bar_container {
    margin-bottom: -35px;
    .field_row {
      &:not(:first-child) {
        display: none;
      }

      .fieldset-wrapper {
        margin: 0px -8px;
        @include clearfix;

        > .form-item {
          padding: 0px 8px;
          float: left;
          &:nth-child(1) {
            width: 75%;
          }

          &:nth-child(2) {
            width: 25%;
          }
          &:nth-child(3),
          &:nth-child(4) {
            width: 50%;
          }

          @include breakpoint(small down) {
            width: 100% !important;
          }
        }
      }
    }

    .button_container {
      padding: 0px 8px;
      float: left;
      width: 100%;

      a {
        @include buttonAddField;
      }
    }
  }

  .rs_container {
    legend {
      margin-bottom: 33px;
    }
  }

  .field_block_pactivity {
    @include clearfix;
    margin-bottom: 35px;
    @include breakpoint(medium up) {
      #edit-membership-informations-professional-pactivitycontainer-pactivity {
        float: left;
      }
    }

    .form-item-membership-informations-professional-pactivitycontainer-pactivity-detail {
      float: right;
      width: 365px;
      margin-top: -25px;
      position: relative;
      display: none;

      @include breakpoint(large only) {
        width: 250px;
      }

      @include breakpoint(medium only) {
        float: left;
        margin-left: 20px;
        width: 300px;
      }

      @include breakpoint(small down) {
        width: 100%;
        float: none;
        margin-top: -33px;
      }

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 9px 5px 0;
        border-color: transparent #bfbfbf transparent transparent;
        position: absolute;
        top: 50%;
        margin-top: -5px;
        left: -9px;

        @include breakpoint(small down) {
          display: none;
        }
      }

      &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 9px 5px 0;
        border-color: transparent #fff transparent transparent;
        position: absolute;
        top: 50%;
        margin-top: -5px;
        left: -7px;

        @include breakpoint(small down) {
          display: none;
        }
      }
    }
  }

  .checkbox_bottom_container {
  }

  .address_container {
    margin-bottom: 35px;
    .form-item {
      &:not(:last-child) {
        margin-bottom: 13px;
      }
    }

    .form-item-profile-coordonnees-field-adresse-suite-und-0-value,
    .form-item-profile-coordonnees-field-complement-adresse-und-0-value,
    .form-item-profile-coordonnees-field-cor-adresse-suite-und-0-value,
    .form-item-profile-coordonnees-field-cor-complement-adresse-und-0-value {
      label {
        display: none;
      }
      margin-top: 13px;
    }
  }

  .phone_container {
    .fieldset-wrapper {
      position: relative;

      .form-item {
        margin-bottom: 0;
      }

      .add_phone {
        @include buttonAddField;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
      }
    }
  }

  .field_block_tva {
    @include clearfix;
    margin-bottom: 35px;

    .fieldset-wrapper {
      padding-left: 60px;
      position: relative;

      label {
        display: none;
      }

      .form-item-membership-informations-professional-tvacontainer-stva,
      .field-name-field-tva-first {
        width: 50px;
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: 0;

        input {
          text-align: center;
          padding: 0;
        }
      }
    }
  }

  .step_actions {
    margin-top: 80px;
    .form-submit {
      display: none;
    }

    @include breakpoint(small down) {
      .col-2 {
        .col {
          width: 49%;

          &:first-child {
            float: left;
          }

          &:last-child {
            float: right;
          }
        }
      }
    }

    a {
      height: 60px;
      display: block;
      text-align: center;
      color: #fff;
      font-size: 18px;
      font-weight: $fwSemiBold;
      text-align: center;
      line-height: 60px;
      border: solid 1px;

      @include breakpoint(small down) {
        font-size: 0;

        &:after,
        &:before {
          margin: 0;
          font-size: 24px;
        }
      }

      &.next_step,
      &.next_step_confirmation,
      &.next_step_last {
        background: $muted-blue;
        border-color: $muted-blue;

        &:after {
          @include font($zmdi-var-long-arrow-right);
          margin-left: 20px;
        }

        &:hover {
          background: #fff;
          color: $muted-blue;
        }
      }

      &.previous_step,
      &.previous_step_last {
        background: #dadbe2;
        border-color: #dadbe2;
        color: #3d3d3d;

        &:before {
          @include font($zmdi-var-long-arrow-left);
          margin-right: 20px;
        }

        &:hover {
          background: #fff;
        }
      }
    }
  }

  .autocomplete-deluxe-container {
    background: none;
    border: none;
    padding: 0;
    width: 100%;
    position: relative;

    .autocomplete-deluxe-throbber {
      position: absolute;
      right: 15px;
      bottom: 14px;
      height: 20px;
    }

    input.autocomplete-deluxe-form {
      float: none !important;
      margin: 0;
      padding: 0;
      padding-left: 15px;
      width: 100% !important;
    }

    .autocomplete-deluxe-item {
      background: $muted-blue;
      box-shadow: none;
      padding: 0px;
      margin: 0;
      height: 25px;
      line-height: 25px;
      padding-left: 10px;
      margin-bottom: 10px;
      padding-right: 30px;
      color: #fff;
      position: relative;

      &:not(:last-child) {
        margin-right: 10px;
        border: none;
      }

      .autocomplete-deluxe-item-delete {
        background: none;
        width: auto;
        position: absolute;
        right: 10px;
        top: 0;
        height: 25px;
        line-height: 25px;
        font-size: 13px;
        color: #fff;

        &:hover {
          color: $red;
        }

        &:after {
          @include font($zmdi-var-close);
        }
      }
    }
  }

  #edit-profile-informations-field-date-de-naissance,
  .form-item-membership-informations-personal-row-1-col-1-bday {
    .date-padding,
    .container-inline {
      margin: 0px -10px;
      @include clearfix;

      .form-item {
        width: 33.333%;
        padding: 0 10px;
        float: left;

        label {
          font-size: 14px;
          font-weight: normal;
          margin-bottom: 5px;
          display: block !important;
        }
      }
    }
  }

  .form-type-managed-file {
    .description {
      display: none;
    }

    .file-widget {
      height: 45px;
      border: solid 1px #bfbfbf;
      position: relative;

      .form-submit[value="Upload"] {
        display: none;
      }

      .form-file {
        position: absolute;
        width: 100%;
        height: 100%;
        appearance: none;
        opacity: 0;
        top: 0;
        left: 0;
        z-index: 10;
      }

      .file-size {
        display: none;
      }

      .file {
        a {
          background: #ecedf2;
          margin: 7px;
          height: 30px;
          line-height: 31px;
          padding: 0px 10px;
          color: $black;
          font-size: 13px;
          display: inline-block;
        }

        img {
          display: none;
        }
      }

      .browse_button {
        display: none;

        &:first-child {
          display: block;
        }
      }

      .browse_button,
      .form-submit {
        position: absolute;
        pointer-events: none;
        height: 100%;
        right: 0;
        top: 0;
        background: $turquoise-blue;
        color: #fff;
        width: 140px;
        text-align: center;
        padding: 0;

        font-size: 14px;
        line-height: 45px;
        border: none;
      }

      .form-submit {
        background: $red;
        z-index: 55;
        pointer-events: visible;
        cursor: pointer;
      }
    }
  }

  .image-widget {
    @include clearfix;
    background: #ecedf2;
    padding: 53px 0;
    position: relative;

    .image-preview {
      position: absolute;
      top: 53px;
      left: 50%;
      margin-left: -85px;
      border-radius: 50%;
      overflow: hidden;
      z-index: 55;
      width: 170px;
      height: 170px;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .image-widget-data {
      border-radius: 50%;
      width: 170px;
      height: 170px;
      background: url(../img/user_defaut.png);
      background-size: cover;
      margin: 0px auto;
      border: solid 1px #c4c4c4;
      position: relative;

      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .text_photo {
        font-size: 16px;
        font-weight: $fwSemiBold;
        color: $muted-blue;
        width: 80px;
        display: block;
        pointer-events: none;
        display: none;
        line-height: 19px;
        text-align: center;

        &:first-child {
          display: block;
        }

        &:before {
          @include font($zmdi-var-plus-circle);

          color: $turquoise-blue;
          margin-right: 7px;
        }
      }

      .form-file {
        width: 100%;
        height: 100%;
        appearance: none;
        cursor: pointer;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
      }

      .file-size,
      .file {
        display: none;
      }

      .form-submit {
        display: none;

        &[value="Remove"],
        &[value="Retirer"],
        &[value="Eliminar"],
        &[value="Supprimer"] {
          width: 100%;
          text-align: center;
          display: block;
          position: absolute;
          bottom: -20px;
          color: $muted-blue;
          font-size: 14px;
          text-decoration: underline;
          border: none;
          background: none;
          padding: 0;
          appearance: none;
          left: 0;
        }
      }
    }
  }

  .tabledrag-toggle-weight-wrapper {
    display: none;
  }

  .field-name-field-barreau {
    .messages {
      display: none !important;
    }
  }

  .field-multiple-table {
    display: block;

    .field-multiple-drag {
      display: none !important;
    }

    tr,
    td,
    tbody {
      display: block;
    }

    th {
      display: none;
    }

    .cancel {
      display: none;
    }

    .ajax-new-content,
    tr > td {
      margin: 0px -8px;
      @include clearfix;

      .field-name-field-nom-du-barreau,
      .field-name-field-annee,
      .field-name-field-ville,
      .field-name-field-pays,
      .field-name-field-barreau-ref {
        padding: 0px 8px;
        float: left;
        margin-bottom: 35px;
        &.field-name-field-nom-du-barreau,
        &.field-name-field-barreau-ref {
          width: 50%;
        }

        &.field-name-field-annee {
          width: 25%;
        }
        &.field-name-field-ville,
        &.field-name-field-pays {
          width: 37.5%;
        }

        @include breakpoint(small down) {
          width: 100% !important;
        }

        .description {
          display: none;
        }
      }
    }
  }

  .form-actions {
    padding-top: 40px;
    .form-submit {
      @include button($muted-blue, $turquoise-blue, 220px, inline-block);
      margin: 0px auto;
      border: none;
      display: block;
    }
  }

  #edit-group_mes_informations {
    display: none !important;
  }

  .field-add-more-submit {
    @include buttonAddField;
    width: 100%;
    padding: 0;
  }

  .telephone_container {
    margin-top: 35px;
    @include clearfix;

    .form-item {
      margin-bottom: 10px !important;

      width: 100% !important;
    }

    .field-multiple-table {
      width: 48%;
      float: left;

      th {
        &.field-label {
          display: block;
        }
      }
    }

    .clearfix {
      float: right;
      width: 48%;
      margin-top: 26px;
    }
  }

  .telephone_container_portable {
    margin-bottom: 35px;
  }

  .field-type-image {
    .description {
      display: none;
    }
  }
}

form.form_filter {
  .form-text,
  .form-select {
    border: none;
    font-size: 14px;
  }

  .form-item {
    position: relative;
  }

  .autocomplete-results {
    display: none;

    &.active {
      display: block;
    }

    left: 7px;
    right: 7px;
    top: 72px;
    position: absolute;

    padding-left: 22px;
    margin-left: 1px;
    background: #fff;

    padding-top: 15px;
    cursor: pointer;
    padding-right: 20px;
    padding-bottom: 15px;
    z-index: 5555;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);

    li {
      @include transition(0.3s);
      &:hover {
        color: $muted-blue;
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  .special_checkbox_radio_col {
    margin-bottom: 35px;

    .form-radios,
    .form-checkboxes {
      .form-item {
        input {
          display: none;
        }

        label {
          display: block;
          margin-bottom: 0;
          width: 100%;

          padding: 12px 10px;
          text-align: center;
          font-size: 16px;
          color: #3d3d3d;
          background: #dadbe2;
          font-weight: normal;
          cursor: pointer;
          line-height: 21px;
        }

        input:checked + label {
          color: #fff;
          background: $turquoise-blue;
        }
      }
    }
  }

  .form-actions {
    text-align: center;
    margin-top: 40px;

    a {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 16px;

        @include breakpoint(medium down) {
          margin-bottom: 16px;
          margin-right: 0;
        }
      }

      @include breakpoint(medium down) {
        display: block !important;
        width: 100% !important;
      }

      &.form-reset {
        @include buttonBorder($muted-blue, #fff, inline-block, 220px);
        font-size: 14px;
        color: #3d3d3d;

        &:before {
          @include font($zmdi-var-close);
          clore: $muted-blue;
          margin-right: 8px;
          font-weight: normal;
        }
      }

      &.form-submit {
        @include button($muted-blue, $turquoise-blue, 220px, inline-block);
      }
    }
  }
}

#page_contact_formulaire {
  .form-actions {
    width: 290px;
    margin: 0px auto;
    position: relative;

    &:after {
      @include font($zmdi-var-mail-send);
      height: 60px;
      line-height: 60px;
      color: #fff;
      font-size: 18px;
      position: absolute;
      top: 0;
      left: 30px;
      pointer-events: none;
    }

    input {
      padding: 0px;
      appearance: none;
      border: none;
      color: #fff;
      padding-left: 60px;
      padding-right: 35px;
      height: 60px;
      line-height: 60px;
      background: $muted-blue;
      font-size: 18px;
      font-weight: $fwSemiBold;
      width: 100%;

      &:hover {
        background: $turquoise-blue;
      }
    }
  }
}

#page_contact_formulaire_map {
  height: 565px;
  position: relative;

  background-size: cover;
  background-position: center center;

  display: flex;
  align-items: center;

  #block_coordonnees {
    display: block;
    margin-left: 120px;
    width: 450px;

    @include breakpoint(medium down) {
      width: 90%;
      margin: 0px auto;
    }

    h2 {
      background: #fff;
      font-size: 20px;
      font-weight: $fwBold;
      text-transform: uppercase;
      color: $muted-blue;
      padding: 30px;
      padding-right: 100px;
      line-height: 30px;
    }

    .block_container {
      background: #f8f8fa;
      padding: 30px;

      .block {
        &:not(:last-child) {
          margin-bottom: 40px;
        }

        h3 {
          color: $muted-blue;
          font-size: 16px;
          font-weight: $fwSemiBold;
          margin-bottom: 20px;
        }

        ul {
          li {
            font-size: 16px;
            color: #636363;

            &:not(:last-child) {
              margin-bottom: 12px;
            }

            strong {
              font-weight: $fwSemiBold;
            }

            &.phone {
              &:before {
                @include font($zmdi-var-phone);
                width: 22px;
              }
            }
            &.fax {
              &:before {
                @include font($zmdi-var-print);
                width: 22px;
              }
            }
          }
        }
      }
    }
  }
}

form.form_filter {
  .col-2 .col.form-item {
    margin-bottom: 35px !important;
  }
}

#user-profile-form {
  .field-name-field-name-of-cabinet {
    display: none;
  }

  .field-name-field-telephone-fixe,
  .field-name-field-telephone-portable {
    .ajax-new-content {
      margin: 0 !important;
    }
    tbody label {
      display: none !important;
    }

    .field-multiple-table tr > td {
      margin: 0 !important;
    }
  }
}
input:disabled {
  background: #ecedf2 !important;
}
#disabledInput input  {
  background: #ecedf2;
}
