.list-accordion{
	> li{
		
		&:not(:last-child){
			margin-bottom: 1px; 
		}
		

		h3{
			background: $muted-blue; 
			padding: 22px 30px;
			position: relative;
			color: #fff; 
			font-size: 20px;
			font-weight: $fwSemiBold; 
			cursor: pointer; 
			@include transition(0.3s);

			&:hover{
				background: $turquoise-blue;
			}

			@include breakpoint(small down){
				padding-left: 15px;
				font-size: 16px;
				line-height: 23px;
				padding: 15px 30px;
			}

			&:before{
				height: 10px;
				line-height: 10px; 
				@include font($zmdi-var-chevron-up); 
				position: absolute;
				@include rotate(180deg); 
				top: 50%;
				margin-top: -5px;
				right: 30px;
				color: #fff; 
				font-size: 30px;
				@include transition(0.3s);

				@include breakpoint(small down){
					right: 15px;
				}
			}
		}

		.accordiou_tab_item_content{
			display: none; 
		}
	
		&.active{
			h3{
				&:before{
					@include rotate(0deg); 
				}
			}
		}

	}
}