.tool_bar{
	z-index: 999 !important;

	@include breakpoint(small down){
		width: 100%; 
	}

	ul:not(.atcb-list){

		@include breakpoint(small down){
			float: right;
			@include clearfix; 			
		}

		> li{

			padding-bottom: 1px; 
			position: relative;
			z-index: 2; 
			&:hover{
				z-index: 55;
			}

			@include breakpoint(small down){
				padding-bottom: 0; 
				padding-left: 1px;
				float: left;
			}
			

			.flag-throbber{
				display: none !important;  
			}

			a:not(.atcb-item-link), .add_calendar{
				height: 50px; 
				width: 50px; 
				display: block;
				font-size: 0;
				display: flex;
				align-items: center; 
				justify-content: center; 
				position: relative;
				&:before{
					display: block; 
				}


			}

			.addtocalendar{
				position: relative;

				@include tooltip; 
			}

			.atcb-link{
				
				background-color: $muted-blue;
				color: #fff;
				display: flex;
				align-items: center; 
				justify-content: center;  
				position: relative;
				cursor: pointer; 
				font-size: 0;

				

				&:hover{
					background: $turquoise-blue;

				}

				&:after{
					content: '';
					@include pictos-calendar; 
					display: block;
				} 

				input[type=submit]{
					display: block;
					appearance: none; 
					opacity: 0; 
					width: 100%; 
					height: 100%; 
					position: absolute;
					top: 0; 
					left: 0;
				}

				&:before{
					font-size: 25px;
				}
			}

			&.blue a{
				background-color: $muted-blue;
				color: #fff; 

				&:before{
					font-size: 25px;
				}

				&:not(.flag):hover{
					background: $turquoise-blue; 
				
				}
			}

			&.grey a{
				background-color: $pale-grey-two;
				color: $muted-blue;
				&:before{
					font-size: 20px; 
				}

				&:hover{
					background: $muted-blue; 
					color:#fff;  
				}
			}

			&.share {
				> a{
					&:before{
						@include font($zmdi-var-share); 
					}
				}

				.share_list{
					overflow: hidden;
					max-height: 0; 
					overflow: hidden;
					@include transition(0.3s); 

					@include breakpoint(small down){
						max-height: 50px;
						max-width: 0; 
						height: 50px;
						float: left;
						@include clearfix; 

						> li{
							float: left;
						}
					}
				}

				.share_show{
					@include breakpoint(small down){
							float: left;
						}
				}

				&:hover{
					.share_list{
						max-height: 500px; 

						@include breakpoint(small down){
							max-height: 50px;
							max-width: 600px;   
						}
					}
				}
			}

			&.favorite a{
				&:before{
					@include font($zmdi-var-star-outline); 
				}

				&.flagged, &.unflag-action{
					&:before{
						@include font($zmdi-var-star); 
					}
				}
				
				@include tooltip; 

				.tooltip:not(:last-child){
					display: none !important;  
				}
			}

			&.print a{
				&:before{
					@include font($zmdi-var-print); 
				}
			}
			&.twitter a{
				&:before{
					@include font($zmdi-var-twitter); 
				}

				&:hover{
					background: $colorTwitter; 
					color:#fff;  
				}
			}
			&.facebook a{
				&:before{
					@include font($zmdi-var-facebook); 
				}
				&:hover{
					background: $colorFacebook; 
					color:#fff;  
				}
			}
			&.linkedin a{
				&:before{
					@include font($zmdi-var-linkedin); 
				}

				&:hover{
					background: $colorLinkedin; 
					color:#fff;  
				}
			}
			&.mail a{
				&:before{
					@include font($zmdi-var-email); 
				}
			}
		}
	}
}

.header_main_share_block{

	.tool_bar{
		
		@include clearfix; 

		> ul{
			> li{
				float: left;
				padding-right: 20px; 
						border-right: solid #e1e1e1;

				&:not(:last-child){
					margin-right: 20px;
				}

				&.print{
					display: none; 
				}

				
				a{
					width: auto; 
					height: auto; 
					background: none; 
					color: #3d3d3d; 

					height: 30px;
					line-height: 30px; 

					&:hover{
						background: none; 

						&:before{
							color: $turquoise-blue;
						}
					}
					

					&.flag{
						font-size: 14px;
						font-weight: $fwSemiBold; 
						text-transform: uppercase;
						
					}

					&.flag, &.share_show{
						&:before{
							margin-right: 12px;
						}
					}

					&.share_show{

						


						span{
							text-indent: 4px; 

							&.hide{
								max-width: 0px; 
								overflow: hidden;
								@include transition(0.3s);
								white-space: nowrap;
							}
						}
					}



					&:before{
						color: $muted-blue;
					}
				}

				&.share{
					&:hover{
						.share_show{
							span{
								max-width: 120px; 
							}
						}

						.share_list{
							max-width: 500px; 
						}
					}
				}

				span{
					font-size: 14px;
					font-weight: $fwSemiBold; 
					text-transform: uppercase;
				}

				.share_show{
					float: left;
				}

				.share_list{
					max-height: 100%;
					float: left; 
					max-width: 0px; 
					white-space: nowrap;
					height: 31px; 

					> li{
						float: left;
						margin-left: 20px;

						&.mail{
							margin-left: 50px;
						}
						
					}
				}
			}

			
		}
	}
}