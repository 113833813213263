%login-popup-block {
  position: absolute;
  top: 36px;
  right: 0;
  width: 360px;
  display: none;
  z-index: 8500;

  .overlay{
    z-index: 8000;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;left: 0;
    background: rgba(0,0,0,0.6);
  }

  .inside{
    z-index: 8810;
    position: relative;
  }

  i.close{
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    color: rgba(133, 133, 133, 0.85);
    height: 11px;
    line-height: 11px;

    &:hover{
      color: $turquoise-blue;
    }

    &:after{
      @include font($zmdi-var-close);
    }
  }

  #form_password{
    display: none;
  }

  .content{
    padding: 30px;
    padding-top: 40px;
    background-color: $pale-grey;

    h3{
      font-family: $ffT;
      font-size: 18px;
      font-weight: $fwBold;
      color: $muted-blue;
      line-height: 24px;
      margin-bottom: 35px;
    }

    form{
      .form-item-container{
        position: relative;

        &:not(:last-child){
          margin-bottom: 30px;
        }

        .placeholder{
          display: none;
        }

        .label_link{
          position: absolute;
          right: 0;
          top: 0;
          font-size: 12px;
          font-weight: 600;
          color: #3d3d3d;
          text-decoration: underline;
          z-index: 1;

          &:hover{
            color: $muted-blue;
          }
        }

        label{
          font-size: 14px;
          font-weight: $fwBold;
          color: $black;
          margin-bottom: 10px;
          display: block;
        }

        input.form-text{
          height: 40px;
          width: 100%;
          border: none;
          background: #fff;
          padding-left: 15px;
          display: block;
          font-size: 14px;
          color: $black;

          @include input-placeholder{
            color: #858585;
          }

          &:focus{
            @include input-placeholder{
              color: $pale-grey;
            }
          }
        }

        .form-type-password{

          position: relative;

          span.toggle_password_show{


            height: 40px;
            line-height: 40px;
            position: absolute;
            bottom: 0;
            right: 15px;
            cursor: pointer;

            color: $turquoise-blue;

            &:hover{
              color: $muted-blue;
            }

            &.active{
              color: $brownish-grey;
            }

            &:after{
              @include font($zmdi-var-eye);

              font-size: 16px;
            }
          }

        }
      }

      .form-type-checkbox{
        input{
          display: none;
        }

        label{
          font-size: 14px;
          color: #3d3d3d;
          position: relative;
          height: 16px;
          line-height: 16px;
          padding-left: 23px;
          display: block;
          cursor: pointer;

          &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            border: solid 1px #858585;
            font-size: 14px;
            text-align: center;
            color: $tealish-two;
          }
        }

        input:checked + label{

          &:before{
            @include font($zmdi-var-check);
          }
        }
      }

      .form-actions{
        margin-top: 30px;

        .form-submit{
          height: 55px;
          background-color: $muted-blue;
          line-height: 55px;
          border: none;
          padding: 0;
          display: block;
          color: #fff;
          font-size: 14px;
          text-align: center;
          width: 100%;
          cursor: pointer;

          &:hover{
            background: $turquoise-blue;
          }
        }
      }
    }
  }

  .bottom{
    text-align: center;
    padding: 22px;
    color: #ffffff;
    font-size: 14px;
    background-color: $tealish-two;

    a{
      color: #fff;
      text-decoration: underline;
      font-weight: $fwSemiBold;

      &:hover{
        color: $black;
      }
    }
  }
}
