.cookiebanner{



}


.cookiebanner-close{
			height: 30px;
			line-height: 30px; 
			padding-left: 15px !important;
			padding-right: 15px;
			background: $turquoise-blue; 
			color: #fff;
			margin-left: 20px; 

			&:hover{
				background: $muted-blue;
			}
	}


	.cookiebanner{
		@include clearfix; 
		height: auto !important;  
		line-height: initial !important; 
		padding-top: 10px !important;
		padding-bottom: 10px !important;

		span{
			display: block;
			@include breakpoint(large up){
				padding-top: 7px;
			}

			@include breakpoint(small down){
				text-align: left;
			}
			
		}
	}