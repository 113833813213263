.evenement{

	&_teaser{

		position: relative;

		.fav{
			position: absolute;
			top: 16px;
			z-index: 555;
			right: 30px;
			@include flag(#fff); 
			display: none; 
			
			@include tooltip; 

			.tooltip{
				left: -13px;
				top: 34px;
			}
		}
		.bg{
			display: block;
			position: relative;
			
			background: #fff; 
			max-width: 300px; 
			margin: 0px auto;

			.img{
					
				@include imgHover; 
				border-bottom: solid 3px $tealish;

				.cat{
					@include catTeaser;
					position: absolute;
					bottom: -3px;
					left: 0; 
			
				}
			

				.cat{
					@include catTeaser; 
					position: absolute;
					top: 0;
					left: 0;
				}
			}

			.detail{
				
				padding-top: 30px;
				padding-right: 30px;
				padding-left: 30px;
				border: solid 1px #dadbe2;
				height: 220px;

			

				.date{
					font-size: 18px;
					font-family: $ffT; 
					color: $muted-blue;
					margin-bottom: 15px;
					font-weight: $fwSemiBold; 
				}
		
				h3{
					font-size: 15px;
					font-weight: $fwSemiBold; 
					line-height: 23.71px;
					color: $black;  
				}

				.location{
					margin-top: 20px;
					color: $muted-blue;
					display: inline-block;
					font-size: 14px; 

					&:before{
						@include font($zmdi-var-pin); 
						font-size: 14px;
						margin-right: 5px;
						color: $turquoise-blue;

					}
				}
			}

			.button{
				position: absolute;
				z-index: 88; 
				right: 0;
				bottom: 0;
				width: 37px; 
				height: 37px;
				background-color: $tealish;
				display: flex;
				align-items: center; 
				justify-content: center; 

				&:after{
					content: "";
					display: block;
					@include pictos-edit; 
				}

			}


			&:hover{
				.img{
					@include imgHoverActive; 
				}
			}

		}
	}

	&_teaser_hp_big{
		.bg{
			display: block;
			position: relative;



			&:before{
				content: "";
				position: absolute;
				z-index: 1; 
				width: 100%; 
				height: 100%; 
				background: rgba(#0d2145, 0.55); 
				top: 0;
				left: 0;
			}
			
			.img{
				
				@include imgHover; 

				


				.cat{
					@include catTeaser; 
					position: absolute;
					top: 0;
					left: 0;
				}

			}

			.detail{
				position: absolute;
				left: 40px;
				bottom: 50px;
				right: 66px;
				z-index: 10;

				.date{
					font-family: $ffT;
					font-size: 26.5px;
					font-weight: $fwBold; 
					line-height: 20.92px;
					color: $greenish-yellow;
					margin-bottom: 35px;
				}

				h3{
					font-size: 22.2px;
					font-weight: 600;
					line-height: 30.25px;
					color: #fff; 
				}

				.location{
					margin-top: 20px;
					height: 15px;
					line-height: 15px; 
					padding-left: 20px;
					color: #fff; 
					font-size: 17px;
					position: relative;

					&:before{
						@include font($zmdi-var-pin); 
						position: absolute;
						top: 0;
						left: 0;
						height: 15px;
						line-height: 15px; 
						font-size: 17px;
					}
				}
			}

			.button{
				position: absolute;
				z-index: 88; 
				right: 0;
				bottom: 0;
				width: 37px; 
				height: 37px;
				background-color: $tealish;
				display: flex;
				align-items: center; 
				justify-content: center; 

				&:after{
					content: "";
					display: block;
					@include pictos-edit; 
				}

			}


			&:hover{
				.img{
					@include imgHoverActive; 
				}
			}

		}
	}

	&_teaser_hp{
		position: relative;

		@include breakpoint(small down){
				max-width: 250px; 
				margin: 0px auto; 
			}
		.bg{
			display: block;
			position: relative;

			
			@include clearfix; 
			background: $pale-grey;

			@include breakpoint(medium up){
				height: 176px; 
			}

			
				
			.img{
				
				@include breakpoint(medium up){
					width: 176px; 
					float: left;
					margin-right: 30px; 

				}

			



				img{
					display: block; 
					height: 176px;
					width: auto; 
				}

				&:before{
					content: "";
					position: absolute;
					z-index: 1; 
					width: 100%; 
					height: 100%; 
					background: rgba(#0d2145, 0.55); 
					top: 0;
					left: 0;
				}
				
				@include imgHover; 


				.cat{
					@include catTeaser; 
					position: absolute;
					top: 0;
					left: 0;
				}

				.date{
					font-family: $ffT;
					font-size: 18px;
					color: $greenish-yellow;
					z-index: 10; 
					position: absolute;
					width: 100%; 
					height: 100%; 
					display: flex;
					align-items: center; 
					justify-content: center; 
					top: 0;
					left: 0;
				}



			}

			.detail{
				
				padding-top: 30px;
				padding-right: 20px;

				@include breakpoint(medium down){
					padding: 20px;
					padding-top: 25px;
					padding-bottom: 50px; 
				}

				@include breakpoint(medium down){
					padding-right: 20px;
				}
		
				h3{
					font-size: 15px;
					font-weight: $fwSemiBold; 
					line-height: 23.71px;
					color: $black;  
				}

				.location{
					margin-top: 20px;
					color: $muted-blue;
					display: inline-block;
					font-size: 13px; 

					&:before{
						@include font($zmdi-var-pin); 
						font-size: 13px;
						margin-right: 5px;
						color: $turquoise-blue;

					}
				}
			}

			.button{
				position: absolute;
				z-index: 88; 
				right: 0;
				bottom: 0;
				width: 37px; 
				height: 37px;
				background-color: $tealish;
				display: flex;
				align-items: center; 
				justify-content: center; 

				&:after{
					content: "";
					display: block;
					@include pictos-edit; 
				}

			}


			&:hover{
				.img{
					@include imgHoverActive; 
				}
			}

		}

		.pay_alert{
			display: none; 
			position: absolute;
			right: 54px;
			bottom: 0; 
			background: $tealish; 
			height: 37px;
			line-height: 33px; 
			padding-left: 14px;
			padding-right: 16px;
			color: #fff; 
			font-size: 14px; 

			@include breakpoint(medium down){
				right: 0;
			}

			&:before{
				@include font($zmdi-var-alert-triangle);
				font-size: 19px;
				margin-right: 8px;
				position: relative;
				top: 3px;

			}

			&:hover{
				background: $muted-blue; 
			}
		}
	}

}

.page-account{
	.evenement_teaser_hp {
		.bg{
			 .button{
				/*display: none; */
			}
		}

		.pay_alert{
			display: block; 
		}
	}
}

.tbd_content_block{
	.evenement_teaser .fav{
		display: block; 
	}
}