#node_base{

	padding-bottom: 55px;
	min-height: 450px; 



	.node_base_container{
		@include container(943px); 
		background: #fff; 
		border-top: solid 4px $muted-blue;
		margin-top: -235px; 

		@include breakpoint(small down){
			margin-top: 0;
			padding-top: 65px;
		}

		
		position: relative;

		.inside{
			padding-top: 80px;
			padding-left: 100px;
			padding-right: 100px;
			position: relative;

			#backList{
				@include backLink(#858585, #858585); 
				position: absolute;
				top: 20px;
				left: 20px;
			}

			@include breakpoint(large down){
				padding-top: 60px;
			
			}

			@include breakpoint(medium down){
				padding-left: 20px;
				padding-right: 20px;
				padding-top: 40px;
			}

			@include breakpoint(small down){
				padding-left: 0;
				padding-right: 0;
			}
		}

		.top_infos{
			margin-bottom: 40px;
			text-align: center;
			font-size: 16px;
			font-weight: $fwSemiBold; 
			line-height: 45.0px;
			text-align: center;	
			color: $lemon-green;

			h3{
				text-transform: uppercase;
				color: $muted-blue;
			}
			
			span{
				color: $muted-blue;
			}
		}


		h1{
			max-width: 690px;
			margin: 0px auto; 
			font-family: $ffT;
			font-size: 36px;
			font-weight: $fwBold; 
			line-height: 54.0px;
			text-align: center;
			color: #434343;
			text-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
			margin-bottom: 80px;

			@include breakpoint(medium down){
					margin-bottom: 50px;
					font-size: 30px;
					line-height: 40px; 
			}

			@include breakpoint(small down){
				font-size: 24px;
				line-height: 36px; 
			}
		}

		.tool_bar{
			position: absolute;
			top: -4px; 
			right: -50px;

			@include breakpoint(small down){
				top: 0;
				right: 0; 
			}
		}

		.tab_content_menu{
			width: 200px; 
			left: -200px; 
			position: absolute;
			top: 241px;
			

			@include breakpoint(large down){
				position: relative;
				left: 0;
				width: 100%; 
				top: 0;
			}

			@include breakpoint(small down){
				
			}

		}

		.tab_content{
			&:not(.active){
				display: none; 
			}
		}
	}
}