#header_main{

	height: 118px; 

	@include breakpoint(small down){
		display: none; 
	}



	.inside{
		position: relative;
		background: #fff; 
		@include transition(0.5s);
	}

	.container{
		

		> .block{
			float: left;
			padding-top: 35px;

			&.header_main_logo_block{
				width: 300px; 
				padding-top: 20px;
				position: relative;
				z-index: 60;

				@include breakpoint(large down){
					width: 245px; 
				}

				@include breakpoint(medium down){
					display: none; 
				}


				a{
					display: block; 
					img{
						display: block; 
					}
				}
			}

			&.header_main_menu_block{
				width: 690px; 

				@include breakpoint(large down){
					width: 655px; 
				}

				.block_content{
					> .menu{
						@include clearfix; 
						width: 100%; 

						> li{
							float: left;
							padding-bottom: 30px;
							height: 83px;
							position: relative;

							&:not(:last-child){
								> a, > .nolink{
									padding-right: 84px;

									@include breakpoint(large down){
										padding-right: 68px; 
									}
								}
							}

							> a, > .nolink{
								display: block;	
								padding-left: 14px; 
								position: relative;
								font-size: 18px;
								color: $muted-blue;
								font-weight: $fwBold; 
								line-height: 22px; 
								font-family: $ffT; 
								opacity: 0; 
								@include transition(0.4s);

								&.show{
									opacity: 1; 
								}

								b{
									display: block; 
									font-family: $ffP; 
									text-transform: uppercase;
									white-space: nowrap;
								}

								&:before{
									content: "";
									position: absolute;
									top: 2px;
									
									left: 0px; 
									width: 2px;
									background: $lemon-green;
									height: 40px;

								}	
							}
							/* SECOND LEVEL */
							> ul{
								position: absolute;
								top: 83px;
								left: 0;
								width: 100%;
								z-index: 999;
								overflow: hidden;
								max-height: 0px; 
								@include transition(0.3s); 

								> li{
									position: relative;

									&:first-child{
										border-top: solid 4px $turquoise-blue;

									}

									&:not(:last-child){
										border-bottom: solid 1px #d0d1d8;
									}

									> a, > span{
										padding: 20px 13px;
										display: block;
										font-size: 15px;
										line-height: 20px; 
										font-weight: $fwSemiBold; 
										background: rgba(#ecedf2, 0.97);
										color: $black; 

										&:hover{
											color: $muted-blue;
										}
									}

									> span{
										&:hover{
											color: $black; 
										}
									}
									/* THIRD LEVEL */
									&.expanded{

										cursor: pointer; 

										> a{
											pointer-events: none; 
											
										}

										&:after{
											@include font($zmdi-var-chevron-down); 
											position: absolute;
											top: 15px;
											right: 15px;
											position: absolute;
											color: $muted-blue;
											font-size: 24px;
											@include transition(0.3s); 
										}
										
										> ul{
											overflow: hidden;
											max-height: 0px; 
											@include transition(0.3s); 

											> li{
												&:not(:last-child){
													border-bottom: solid 1px #d0d1d8;
												}

												> a{
													padding: 20px 25px; 
													color: #646464; 
													font-size: 14px;
													display: block;
													background: #d7d8de; 

													&:hover{
														color: $muted-blue; 
													}
												}
											}
										}

										&.deploy{
											
											&:after{
												@include rotate(180deg); 
											}

											> a{
												color: $muted-blue; 
											}

											> ul{
												max-height: 500px; 
											}
										}
									}
								}
							}

							&:hover{
								> a, > .nolink{
									color:  $tealish-two;
								}

								> ul{
									max-height: 900px; 
								}
							}
						}
					}
				}
			}

			&.header_main_button_block{
				float: right;


				@media screen and (max-width: 1410px){
					margin-right: 30px;
				}

				@media screen and (max-width: 1365px){
					display: none; 
				}

				a{
					display: block;
					height: 50px;
					line-height: 50px; 
					width: 180px; 
					text-align: center;
					text-transform: uppercase;
					color: #fff; 
					background-color: $tealish-two; 
					font-size: 16px;
					font-weight: $fwSemiBold; 

					&:hover{
						background-color: $muted-blue;
					}
				}

			}

			&.header_main_news_title_block{
				display: none; 
				margin-top: -13px;

				h2{
					height: 18px;
					font-size: 18px;
					line-height: 18px; 
					strong{
						text-transform: uppercase;
						font-weight: $fwSemiBold; 
						font-family: $ffP;
						color: $muted-blue;
						
						border-right: solid 1px $muted-blue;
						padding-right: 15px;
						margin-right: 15px;
						display: inline-block;
					}

					span{
						color: #3d3d3d;
						font-weight: normal;
						font-family: $ffT; 
						display: inline-block;

					}
				}
			}

			&.header_main_share_block{
				display: none; 
				margin-top: -20px;
				margin-left: 110px;
			}
		}
	}

	#scroll_top{
		position: absolute;
		top: 0px; 
		right: 50px; 
		line-height: 60px; 
		height: 60px;
		color: $black; 
		cursor: pointer; 
		opacity: 0; 
		@include transition(0.3s);

		
		@media screen and (max-width: 1500px) {
				right: 15px;
			}

		&:hover{
			color:$tealish-two;
		}

		&:before{
			@include font($zmdi-var-long-arrow-up); 
			font-size: 20px;
			display: inline-block;
			margin-right: 10px;
			vertical-align: middle;
			color: $tealish-two;
		}

		span{
			display: inline-block;
			vertical-align: middle;
			font-size: 15px;
			text-transform: uppercase;
		}
	}

	&.sticky{
		.inside{
			height: 60px;
			border-bottom: solid 1px #e1e1e1; 
			position: fixed; 
			width: 100%; 
			top: -60px;
			left: 0;
			z-index: 9999;
		}


		&.stickyTrigger{
			.inside{
				top: 0; 
			}	
		}

		#scroll_top{
			opacity: 1; 
		}



	 	.container > .block{
			&.header_main_logo_block{
				padding-top: 5px;
				width: 200px; 

				@include breakpoint(large down){
					width: 180px;
				}

				a{
					img{
						height: 50px;
						width: auto; 
						image-rendering:auto;       
						image-rendering: -webkit-optimize-contrast;
					}
				}
			}

	 		&.header_main_button_block{
	 			padding-top: 0;
	 			a{
	 				height: 60px;
	 				line-height: 60px; 
	 			}

	 			@include breakpoint(large down){
					display: none; 
	 			}
	 		}

	 		&.header_main_menu_block{
	 			width: 810px; 
				padding-top: 20px;

				@include breakpoint(large down){
					width: 720px; 
				}

				.block_content > .menu{

					> li{
						width: 33.333%;
						height: 40px;
						padding-bottom: 0;

						@include breakpoint(large down){
							width: auto; 

							&:not(:last-child){
								margin-right: 35px;
							}

						}

						> a, > .nolink{
							padding-right: 0;
							height: 23px;
							line-height: 23px; 
							font-size: 16px; 
							white-space: nowrap;

							@include breakpoint(large down){
								font-size: 14px;
							}
							&:before{
								top: 0;
								height: 100%;
							}

							b{
								display: inline;
							}
						}

						> ul{
							top: 40px; 
						}
					}
				}
	 		}


	 		&.header_main_news_title_block{
	 			display: block; 
	 		}

	 		&.header_main_share_block{
	 			display: block;
	 		}
	 	}
	}


	.header_main_searchform_block{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%; 
		height: 100%; 
		background: #fff; 
		padding-top: 40px;
		display: none; 
		z-index: 50; 



		i.close{
			position: absolute;
			top: 48px;
			right: 65px;
			cursor: pointer; 
			z-index: 999;

			&:after{
			@include font($zmdi-var-close); 
				font-size: 32px;
				line-height: 32px; 
				height: 20px;
				line-height: 20px; 
			}
			color : $muted-blue; 

			&:hover{
				color: $turquoise-blue; 
			}

		}

		form{
			padding-left: 300px;
			position: relative;
			padding-right: 300px;

			@include breakpoint(large only){
				padding-right: 150px;
			}

			@include breakpoint(medium down){
				padding: 0;
				padding-right: 70px;
			}

			> div{
				position: relative;

				&:after{
					@include font($zmdi-var-search); 
					font-size: 40px;
					color: $muted-blue;
					pointer-events: none;
					position: absolute;
					right: 0;
					top: 0;
				}
				.form-submit{
					position: absolute;
					width: 41px; 
					height: 41px;
					right: 0;
					top: 0;
					opacity: 0; 
					z-index: 55; 
					appearance: none; 
					cursor: pointer; 
				}
			}
			.form-type-textfield{
				input{
					display: block;
					width: 100%; 
					border: none; 
					height: 41px;
					padding-left: 22px;
					border-left: solid 2px $lemon-green;
					appearance: none;
					font-size: 32px;
					font-family: $ffT; 
					color: $black; 
					font-weight: $fwBold; 

					@include input-placeholder{
						color: #dadbe2;
					}

					&:focus{
						@include input-placeholder{
						color: #f4f5fe;
					}
					}
				}

				#autocomplete{
					position: absolute;
					
					top: 40px; 
					left: 0;
					padding-left: 22px; 
					border-left: solid 2px $lemon-green;
					background: #fff; 
					line-height: 30px; 
					padding-top: 15px;
					cursor: pointer; 
					padding-right: 20px;
					padding-bottom: 15px;
					z-index: 9999; 

					li{
						display: block !important; 
						&:first-child{
							display: none; 
						}
					}
				}
			}

			.form-actions{
				
			}
		}
	}
}


.node-type-actualite{
	#header_main.sticky{
		.inside{
			border-bottom-width: 2px; 

			.percent_bar{
				width: 100%; 
				position: absolute;
				bottom: -2px; 
				width: 100%; 
				height: 2px;
				left: 0;
				background: $muted-blue; 
			}
		}
	}

	#header_main.sticky .container > .block{

		&.header_main_menu_block, &.header_main_button_block{
			display: none; 
		}
	}
}