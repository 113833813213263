#section_home_acces_rapide{
	

	z-index: 60; 
	position: relative;
	text-align: center;
	background-color: $pale-grey;
	padding-bottom: 60px;

	@include breakpoint(small down){
		padding-bottom: 0px;
	}

	.list-acces_rapide{

		@include clearfix; 
		display: inline-block;
		margin-top: -74px;

		@include breakpoint(small down){
			margin-top: -67px;
			margin-bottom: -20px;
		}


		 > li{
			float: left;
			&:not(:last-child){
				margin-right: 50px; 

				@include breakpoint(medium down){
					margin-right: 20px;
				}

				@include breakpoint(small down){
					margin-right: 0;
				}
			}

			@include breakpoint(small down){
				width: 50%; 
				margin-bottom: 20px; 

				.bg{
					margin: 0px auto; 
				}
			}




			.bg{
				width: 148px; 
				height: 148px;
				display: block;
				border-radius: 50%; 
				background: #fff; 
				padding-top: 30px;

				@include breakpoint(medium down){
					width: 134px; 
					height: 134px;
					padding-top: 20px;
				}

				i{
					display: block;
					height: 48px;
					margin-bottom: 15px;
					display: flex;
					align-items: center; 
					justify-content: center; 

					@include breakpoint(medium down){
						margin-bottom: 10px;
					}	

					&:before{
						content: "";
						display: block;
					}

					&.annuaire:before{
						@include accesrapide-annuaire; 
					}
					&.evenement:before{
						@include accesrapide-evenement; 
					}
					&.comission:before{
						@include accesrapide-comission; 
					}
					&.bibliotheque:before{
						@include accesrapide-bibliotheque; 
					}

				}

				span{
					font-family: $ffP;
					font-size: 15px;
					font-weight: 600;
					letter-spacing: 0.4px;
					color: $mainColor; 
				}


				&:hover{
					background: $secondColor; 
					span{
						color: #fff; 
					}

					i{
						&.annuaire:before{
							@include accesrapide-annuaireWhite; 
						}
						&.evenement:before{
							@include accesrapide-evenementWhite; 
						}
						&.comission:before{
							@include accesrapide-comissionWhite; 
						}
						&.bibliotheque:before{
							@include accesrapide-bibliothequeWhite; 
						}
					}
				}
			}
		}
	}
	
}