#section_temoignage{
	background: $pale-grey; 
	padding-bottom: 100px;

	

	@include breakpoint(small down) {
		padding-bottom: 0;
	}

	.block_temoignage{
		position: relative;
		@include clearfix; 
		overflow: hidden;
		padding-top: 40px; 

		i{
			font-family: $ffDroid; 
			font-size: 277px; 
			color: #fff; 

			@include breakpoint(small down){
				font-size: 140px;
			}
		}

		@include breakpoint(large down) {
			padding-top: 103px;
		}

		@include breakpoint(medium down) {
			padding-top: 0;
		}
		
		&:after{
			content: "";
			position: absolute;
			@include triangle(topright, 164px, 142px, $turquoise-blue); 
			bottom: 0; 
			left: -13px;
			z-index: 55; 
			padding-top: 40px;

			@include breakpoint(large only){
				border-width: 0 150px 108px 0; 
				left: 0; 
			}

			 @include breakpoint(medium down) {
				left: -66px;
				bottom: 185px; 
				display: none; 
			 }
			
			 @media screen and (max-width: 550px) {
			    left: -94px;
    			bottom: 273px;
			}

			 @media screen and (max-width: 375px) {
					left: -77px; 
			 }
		}

		.block_quote{
			width: 460px;
			padding-top: 70px;
			padding-left: 80px;
			padding-right: 80px;
			padding-bottom: 40px;
			background: $muted-blue;
			z-index: 66;
			position: absolute;
			top: 0;
			left: 0;
			margin-top: 40px;
			height: 425px;

			.quote_start{
				position: absolute;
				top: -40px;
				right: 25px; 
				opacity: 0.07;
				@include breakpoint(small down){
					right: 15px; 
					top: -20px;
				}
			}


			 @include breakpoint(large down) {
				padding-top: 50px;
				padding-bottom: 50px;
				padding-left: 50px;
				padding-right: 50px;
				height: 340px;
			 }

			  @include breakpoint(medium down) {
				position: relative;
				margin-bottom: -40px;
			 }

			 @media screen and (max-width: 550px) {
				width: 500px;
				height: auto;
				padding-top: 22px;
				padding-bottom: 22px; 
				padding-left: 20px;
				padding-right: 20px; 
				max-width: 100%; 
			 }




			h2{
				font-family: $ffT;
				font-size: 28px;
				font-weight: $fwBold; 
				color: #ffffff;
				position: relative;
				padding-bottom: 25px;
				display: block;
				margin-bottom: 40px;
				line-height: 30px; 

				@media screen and (max-width: 550px) {
					margin-bottom: 30px;	
					font-size: 24px;
				}

				&:before{
					content: "";
					position: absolute;
					bottom: 0; 
					left: 0;
					width: 66px;
					height: 5px;
					background-color: $greenish-yellow;
					
				}
			}

			.text{
				p{
					&:not(:last-child){
						margin-bottom: 10px;
					}
					font-family: $ffT;
					font-size: 15.8px;
					line-height: 24px;
					color: #ffffff;

					@media screen and (max-width: 550px) {
						font-size: 14px;
					}
				}
			}

			&:after{
				content: "";
				position: absolute;
				left: 0; 
				top: -40px; 
				@include triangle(bottomright, 40px, 460px, $muted-blue); 

				 @media screen and (max-width: 550px) {
						@include triangle(bottomright, 25px, 500px, $muted-blue); 
						top: -25px;
				 }

				  @media screen and (max-width: 400px) {

				  	@include triangle(bottomright, 25px, 360px, $muted-blue); 
						top: -25px;
				  }
			}

			&:before{
				content: "";
				position: absolute;
				left: 0; 
				bottom: -35px; 
				@include triangle(topleft, 35px, 460px, $muted-blue); 

				 @media screen and (max-width: 550px) {
					@include triangle(topleft, 25px, 500px, $muted-blue); 
					bottom: -25px;
				 }

				  @media screen and (max-width: 400px) {
						@include triangle(topleft, 25px, 360px, $muted-blue); 
						bottom: -25px;
				  }
			}
		}

		.img{
			position: relative;
			.quote_end{
				position: absolute;
				top: -50px;
				right: 25px;

				@include breakpoint(small down){
					display: none; 
				}
			}

		 	@include breakpoint(large up) {
				padding-left: 150px; 
			}
			padding-top: 40px;
			position: relative;

			 @media screen and (max-width: 550px) {
				overflow: hidden;
				display: flex;
				align-items: center; 
				justify-content: center; 
				
			 }

			img{
				max-width: 100%; 
				height: auto; 
				display: block;

				 @media screen and (max-width: 550px) {
					width: auto; 
					height: 295px; 
					max-width: inherit; 

				 }
			}
		}

		.caption{
			position: absolute;
			bottom: 30px; 
			right: 30px;
			border-left: solid 5px $greenish-yellow;; 
			padding-left: 15px;
			background: #fff; 
			padding-top: 20px; 
			padding-bottom: 20px;
			padding-right: 20px;
			max-width: 300px; 

			@media screen and (max-width: 550px) {
				position: relative;
				max-width: 100%; 
				right: 0;
				bottom: 0;

			}

			h3{
				text-transform: uppercase;
				font-size: 14px;
				font-weight: $fwBold; 
				color: $muted-blue;
				margin-bottom: 10px;
			}

			p{
				font-size: 14px;
				font-weight: normal; 
				color: $muted-blue;
			}
		}
	}
}

.node-type-temoignage{
	#section_temoignage{
		padding-top: 100px;
	}
}

@include breakpoint(small down) {
	.node-type-page-de-base, .node-type-temoignage{
	#section_temoignage{
		padding-bottom: 50px;
	}
	}
}