

.modele_lettre{

	&_teaser{
		
		.bg{
			display: block;
			background: #fff; 
			max-width: 300px; 
			margin: 0px auto;

		

			.detail{
				padding: 26px 55px 70px 30px; 
				border: solid 1px #dadbe2;
				border-top: 0px; 
				position: relative;

				

				h3{
					
					font-size: 16px;
					font-weight: $fwSemiBold; 
					line-height: 24px;
					color: $black;
					min-height: 96px;
				}

				.button_download{
					position: absolute;
					bottom: 0;
					right: 0;
					@include buttonDownload();
				}

				.lang{
					font-size: 12px;
					color: $turquoise-blue;
					font-weight: $fwSemiBold; 
					margin-top: 20px;

					label{
						display: none; 

					}
				}


			}

		
		}
	}
}