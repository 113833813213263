.commission{

	&_teaser{

		position: relative;

		.bg{
			display: block;
			background: #fff; 
			max-width: 300px; 
			margin: 0px auto;

			.img{
				
				@include imgHover; 
				border-bottom: solid 3px $tealish;

			}

			.detail{
				padding: 26px 30px 26px 30px; 
				border: solid 1px #dadbe2;
				border-top: 0px; 

				h3{
					min-height: 48px; 
					font-size: 16px;
					font-weight: $fwSemiBold; 
					line-height: 24px;
					color: $black;
				}

			}

			&:hover{
				.img{
					@include imgHoverActive; 
				}
			}
		}

		.fav{
			position: absolute;
			top: 16px;
			z-index: 555;
			right: 30px;
			@include flag(#fff); 
			
			
			@include tooltip; 

			.tooltip{
				left: -13px;
				top: 34px;
			}
		}
	}

	&_teaser_big{

		.bg{
			background: #fff; 
			@include clearfix; 
			display: block;

			.img{
				
				@include imgHover; 
				float: left;
				width: 640px; 
				border-right: solid 3px $tealish;
				display: block;

				@media screen and (max-width: 1366px) {
					width: 100%; 
					float: none; 
					border-right: none; 
					border-bottom: solid 3px $tealish;
				}
			}

			.detail{
				width: 295px;
				float: right;
				padding: 40px 35px;
				position: relative;
				border: solid 1px #dadbe2;
				border-left: 0; 
				height: 299px;

				@media screen and (max-width: 1366px) {
					width: 100%; 
					float: none; 
					height: auto; 
					border-top: none; 
					border-left: solid 1px #dadbe2;
				}

				h3{
					line-height: 25px; 
					color: $muted-blue;
					margin-bottom: 15px; 
					font-size: 20px;
				}

				p{
					line-height: 24px; 
					font-size: 16px;
					color: $black; 
				}

				i{
					position: absolute;
					right: 30px;
					bottom: 30px;
					color: $turquoise-blue;
					
					&:before{
						@include font($zmdi-var-long-arrow-right); 
						font-size: 20px;
					}
				}
			}


			&:hover{
				.img{
					@include imgHoverActive; 
				}
			}
		}
	}
}

.page-account-my-national-committee{
		@media screen and (min-width: 1367px) {
			.commission_teaser_big .bg .detail {
			    width: 336px;
			}
		}
}