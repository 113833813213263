
* {
  @include box-sizing(border-box);
}
*:before,
*:after {
  @include box-sizing(border-box);
}

/*Transitions*/

a,
input {
	@include transition(0.2s);
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: none;
	}
} 

html{
	height: 100%; 
}

body{
	margin: 0 !important;
	color: $black; 
	font-family: $ffP; 
	background: $bodyBG;

	@include breakpoint(medium down){
		&.no-scroll, &.no_scroll{
			overflow: hidden;
		}
	}
}

.element-invisible{
	display: none; 
}


a, input{
	outline: none; 
}


#page_wrapper{
	overflow: hidden;
}


input[type=submit]{
	border-radius: 0px; 
	appearance: none; 
}

#admin-menu{
	z-index: 9999;
}

.flag-wrapper{
position: relative;
}

.ui-autocomplete {
	z-index: 999;
}