#page_user{
	padding-bottom: 120px;
	background: #ecedf2; 

	.section_bottom_page{
		&:last-child{
			margin-bottom: -120px;
		}
	}
}

#node_user{
	background: #ecedf2; 
	padding-top: 40px;


	.container{
		@include container(945px); 
	}

	.back_link{
		@include backLink(); 
		margin-bottom: 20px; 
		display: block;
	}

	.fiche{

		margin-bottom: -60px; 
		position: relative;

		.top{
			background: $muted-blue; 
			@include clearfix; 
			position: relative;
			padding: 40px 65px; 

			@include breakpoint(medium down){
				padding: 40px;
			}

			@include breakpoint(small down){
				padding-top: 60px;
				padding-left: 30px;
				padding-right: 30px;
			}

			.import_vcard{
				display: none; 
				@include breakpoint(small down){
					display: block; 
					width: 100%; 
					height: 50px;
					line-height: 50px; 
					color: #fff; 
					text-transform: uppercase;
					font-size: 16px;
					font-weight: $fwSemiBold; 
					background: $turquoise-blue; 
					text-align: center;
					margin-top: 30px;
				}
			}

			.role{
				position: absolute;
				top: 0;
				right: 0;
				padding: 0px 23px; 
				height: 45px;
				line-height: 45px; 
				text-transform: uppercase;
				color: #fff; 
				font-size: 12px;
				font-weight: $fwSemiBold; 
				background: #274573; 
				
				@include breakpoint(small down){
					width: 100%; 
				}

				&.officiel{
					&:before{
						content: "";
						background: url(../img/officiel.png); 
						width: 12px;
						height: 17px;
						display: inline-block;
						vertical-align: middle;
						margin-right: 10px;
						background-size: 12px; 
						background-repeat: no-repeat;
					}
				}
			}

			.img{
				
				display: inline-block;
				vertical-align: middle;
				margin-right: 65px; 

				@include breakpoint(medium down){
					margin-right: 30px;
				}

				@include breakpoint(small down){
					margin-right: 0;
					width: 100%;
				}

				img{
					display: block; 
					border-radius: 50%; 

					@include breakpoint(small down){
						margin: 0px auto; 
					}
				}
			}

			.title{
				
				max-width: 520px; 
				display: inline-block;
				vertical-align: middle;

				@include breakpoint(medium down){
					max-width: 325px; 
				}

				@include breakpoint(medium down){
					width: 100%; 
					margin-top: 20px;
					text-align: center;
				}

				h1{
					font-size: 25.8px;
					font-weight: $fwSemiBold; 
					line-height: 38.74px;
					color: #ffffff;
				}

				h3{
					color: #fff; 
					font-size: 16px; 
					font-style: italic; 
					margin-top: 20px; 
				}
			}
		}

		.content{
			background: #fff; 
			padding: 60px 30px 40px;              
			@include clearfix; 

			@include breakpoint(small down){
				padding: 30px; 
			}

			.left{
				float: left;
				width: 265px;
				padding-right: 35px; 
				margin-right: 30px; 
				border-right: solid 1px #e1e1e1;

				@include breakpoint(small down){
					width: 100%; 
					padding: 0;
					border-right: 0;
					float: none; 
				}

				p{
					color: #3d3d3d;
					font-size: 14px;
					line-height: 21px; 
					.pays{
						text-transform: uppercase;
					}
				}

				.language{
					margin-bottom: 20px;
				}

				.cabinet{
					margin-bottom: 10px;
				}

				.phoneWeb{
					margin-top: 30px; 
					margin-bottom: 40px;

					> li{
						
						color: #3d3d3d;
						font-size: 14px;

						&:before{
							color: $muted-blue;
							font-size: 14px; 
							display: inline-block;
							vertical-align: middle;
							width: 20px; 
						}

						&.phone{
							&:before{
								@include font($zmdi-var-phone); 
							}

							a{
								text-decoration: none; 
							}

						}

						&.fax{
							&:before{
								@include font($zmdi-var-print); 
							}

						}
						&.web{
							&:before{
								@include font($zmdi-var-globe-alt); 
							}

						}

						a{
							color: #3d3d3d;
							text-decoration: underline;
							max-width: 205px;
						    display: inline-block;
						    white-space: nowrap;
						    text-overflow: ellipsis;
						    overflow: hidden;

							&:hover{
								color: $muted-blue;
							}
						}

						&:not(:last-child){
							margin-bottom: 8px; 
						}
					}
				}

				.social{
					@include clearfix; 
					margin-bottom: 40px; 

					> li{
						float: left;
						&:not(:last-child){
							margin-right: 10px; 
						}

						a{
							display: block; 
							font-size: 0;
							width: 38px;height: 38px;
							line-height: 38px; 
							text-align: center;
							border-radius: 50%; 
							
							&:before{
								font-size: 18px; 
								color: #fff; 
							}

							&.facebook{
								background: $colorFacebook; 

								&:before{
									@include font($zmdi-var-facebook); 
								}
							}
							&.twitter{
								background: $colorTwitter; 

								&:before{
									@include font($zmdi-var-twitter); 
								}
							}
							&.linkedin{
								background: $colorLinkedin; 

								&:before{
									@include font($zmdi-var-linkedin); 
								}
							}
							&.skype{
								background: $colorSkype; 

								&:before{
									@include font($zmdi-var-skype); 
								}
							}
						}
					}
				}

				.buttons{
					a{
						display: block;
						padding-left: 65px;
						width: 100%; 
						height: 45px; 
						line-height: 45px; 
						color: #fff; 
						font-size: 16px;
						font-weight: $fwSemiBold; 
						position: relative;
						background: $tealish;

						&:hover{
							background: $muted-blue; 
						}

						&:not(:last-child){
							margin-bottom: 23px; 
						}

						&:before{
							content: "";
							position: absolute;
							top: 0;
							left: 0; 
							height: 45px;
							line-height: 45px; 
							width: 46px; 
							text-align: center;
							border-right: solid 1px $viridian;
						}

						&.download{
							&:before{
								@include font($zmdi-var-download); 
							}
						}

						&.email{
							&:before{
								@include font($zmdi-var-email); 
							}
						}
					}
				}
			}

			.right{
				float: right;
				width: 590px;

				

				@include breakpoint(medium down){
					width: 245px;
				}

				@include breakpoint(small down){
					float: none; 
					margin-top: 30px;
					width: 100%; 
				}

				.content_block{

					&:not(:last-child){
						margin-bottom: 35px; 


					}



					&.firm_block{
						.bg{
							display: block;

							h4{
								color: $black; 
								text-decoration: underline;
								font-size: 16px;
								margin-bottom: 15px;
								@include transition(0.3s);
							}

							.img{
								border: solid 1px #dadbe2;
								
								display: flex;
								align-items: center; 
								justify-content: center; 
								@include transition(0.3s);
								width: 160px; 
								height: 90px;

								img{
									display: block;
									height: 70px; 
									width: auto; 
									margin: 10px auto; 

								}
							}

							&:hover{
								h4{
									color: $turquoise-blue;
								}

								.img{
									border-color: $turquoise-blue;
								}
							}
						}
					}

					.retrait{
						padding-left: 20px;
					}

					h3{
						text-transform: uppercase;
						color: $muted-blue;
						font-size: 16px;
						font-weight: $fwBold; 
						margin-bottom: 15px;

					}

					ul{
						padding-left: 20px; 

						> li{
							&:not(:last-child){
								margin-bottom: 10px; 
							}

							padding-left: 10px;
							font-size: 16px;
							color: #3d3d3d; 
							position: relative; 

							&:before{
								content: "";
								position: absolute;
								width: 4px;
								height: 4px;
								background: $tealish; 
								left: 0;
								top: 7px;
								border-radius: 50%;  
							}
						}
					}

				}
			}
		}
	}
}
