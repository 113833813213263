#breadcrumbs{

	width: 100%;
	height: 30px;
	background: rgba(#000, 0.4); 
	padding-left: 36px; 
	margin-bottom: -30px;
	position: relative;
	z-index: 55;

	@include breakpoint(small down){
		display: none; 
	}
	 	

	.easy-breadcrumb{
		line-height: 30px; 
		font-size: 12px;

		a{
			color: #fff; 


			&:hover{
				color: $muted-blue; 
			}
		}

		span{
			color: #fff;
		}

		.easy-breadcrumb_segment-separator{
			margin: 0px 5px; 
		}

		.easy-breadcrumb_segment-front{
			font-size: 0;
			&:before{
				content: "";
				@include font($zmdi-var-home); 
				font-size: 12px;
			}
		}
	}
}


.page-user, .page-account, .node-type-liste-with-map, .page-search{
	#breadcrumbs{

		margin-bottom: 0;
		background: #dadbe2;
		color: #858585;

		a, span{
			color: #858585;
		}
	}

}


.page-user-me{
	#breadcrumbs{
		background: #ecedf2; 
	}
}
