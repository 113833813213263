$grid-row-width: 1245px;
$columns: 12;
$gutters: 30px;


$breakpoints: (
  small: 0px,
  medium: 768px,
  large: 1024px,
  xlarge: 1300px,
  xxlarge: 1440px,
);


.container {
  @include grid-row($columns, $gutters, $grid-row-width);

	@include breakpoint(large down) {
		max-width: 900px; 
	}	

	@include breakpoint(medium down) {
		max-width: 680px; 
	}

	@include breakpoint(small down) {
		max-width: 100%; 
		padding-left: 20px;
		padding-right: 20px;
	}
}


@mixin container($width){
	
	width: $width; 
	margin: 0px auto; 
	max-width: 100%; 

	@include breakpoint(medium down) {
		max-width: 600px; 
	}

	@include breakpoint(small down) {
		max-width: 100%; 
		padding-left: 20px;
		padding-right: 20px; 
	}
}


@mixin row($padding){
	@include clearfix; 
	margin: 0px #{- $padding / 2}px; 
}