#section_home_banner{

	.banner_item{
		height: 455px;
		width: 100%; 
		background-size: cover; 
		background-position: center center; 
		background-repeat: no-repeat;
		padding-top: 122px;
		position: relative;


		@include breakpoint(medium down){
			height: 420px;
			padding-top: 100px;
		}

		@include breakpoint(small down){
			height: 378px;
			padding-top: 70px;
		}
	
		&:before{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%; 
			height: 100%; 
			opacity: 0.41;
			background-color: #000000;
			z-index: 10; 
		}

		.inside{
			max-width: 500px; 
			margin: 0px auto; 
			position: relative;
			z-index: 30; 
			text-align: center;
			padding-left: 15px;
			padding-right: 15px;
			

			h2{
				font-family: $ffP;
				font-size: 32.4px;
				font-weight: $fwSemiBold; 
				 
				line-height: 49.27px;
				letter-spacing: normal;
				text-align: center;
				color: #ffffff;
				text-shadow: 0 0 4.8px rgba(0, 0, 0, 0.55);
				margin-bottom: 20px; 

				@include breakpoint(small down){
					font-size: 24px;
					margin-bottom: 22px; 
					line-height: 36px; 
				}

				strong{
					font-family: $ffT; 
					font-weight: $fwBold; 
					font-style: italic; 
					@include breakpoint(small down){
						display: block;
					}
				}
			}

			a{
				@include buttonBorder(#fff, $mainColor); 
					text-shadow: 0 0 4.8px rgba(0, 0, 0, 0.55); 

			}
		}
	}
}