@mixin flag($color: $muted-blue){
	

	a{
		display: block;
		height: 21px;
		line-height: 21px; 
		font-size: 0;
		&:after{
			@include font($zmdi-var-star-outline); 
			font-size: 26px;
			color: $color; 
		}

		&.unflag-action {
			&:after{
				@include font($zmdi-var-star); 
			}
		}

	}

	.flag-throbber{
		display: none !important;  
	}


}