

.diagonale{
		width: 100%; 
		height: 175px;

		background: #ffffff; 
		background: linear-gradient(-5deg, #ffffff 0%,#ffffff 50%,$pale-grey 51%,$pale-grey 100%); 


	&.reverse{
		background: linear-gradient(-5deg, $pale-grey 0%,$pale-grey 50%,#fff 51%,#fff 100%); 

	}

	@media screen and (max-width:1366px) {
		height: 115px;
	}

	@include breakpoint(medium down) {
		height: 80px; 
	}

	@media screen and (max-width:401px) {
		height: 35px; 
	}


}