.body_expand, .list_expand, .doc_expand{

	&:not(.too_small){
		
		@include transition(0.5s); 
		max-height: 300px; 
		overflow: hidden;
		padding-bottom: 80px; 
		
		position: relative;
		.rte{
		    padding-top: 0; 
		}

		.trigger_button{
			.close{
				display: none; 
			}
		}

		&:after{
			content: "";
			bottom: 50px; 
			height: 150px;
			left: 0;
			position: absolute;
			width: 100%; 
			  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
			  border-bottom: solid 10px #fff;
			  @include transition(0.3s); 
			  opacity: 1;
		}

		&.active{

			max-height: 20000px; 
			overflow: visible; 

			&:after{
				opacity: 0;
			}

			.trigger_button{
				.close{
					display: block; 
				}

				.open{
					display: none; 
				}
			}
		}
	}

	&.too_small{
		.trigger_button{
			display: none; 
		}
	}
	


	.trigger_button{
		height: 50px;
		background-color: $pale-grey;
		line-height: 50px; 
		text-align: center;
		color: $muted-blue;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: $fwSemiBold; 
		
		position: absolute;
		bottom: 0; 
		width: 100%; 
		left: 0;
		@include transition(0.3s); 
		cursor: pointer; 

		&:hover{
			background: $muted-blue;
			color: #fff; 
		}

		span{
			&:after{
				font-size: 16px;
				margin-left: 12px; 
			}

			&.open{
				&:after{
					@include font($zmdi-var-chevron-down); 
				}
			}
			&.close{
				&:after{
					@include font($zmdi-var-chevron-up); 
				}
			}
		}
	}
}

 .doc_expand{

	&:not(.too_small){
		max-height: 384px; 

		@include breakpoint(medium down){
			max-height: 545px; 
		}

	}
}

.list_expand, .doc_expand{
	&:not(.too_small){
		&:after{
			display: none; 
		}
	}
}