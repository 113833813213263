.list-feeds{
	
	> li{
		&:not(:last-child){
			margin-bottom: 22px; 
		}

		.bg{
			background: #fff; 
			padding: 25px 30px; 
			color: $black; 
			padding-left: 145px; 
			position: relative;
			min-height: 118px; 

			&:before{
				@include font($zmdi-var-twitter); 
				width: 145px; 
				position: absolute;
				left: 0; top: 30px; 
				font-size: 24px;
				color: $colorTwitter; 
				text-align: center;

				@include breakpoint(large down){
					top: 15px;
				}
				
			}

			&:after{
				content: "UIA";
				width: 145px; 
				text-align: center;
				font-size: 11.7px;
				font-weight: $fwBold;
				line-height: 17.94px;
				text-align: center;
				color: #05578a;
				position: absolute;
				top: 63px;
				left: 0;
				padding: 0px 30px; 

				@include breakpoint(large down){
					top: 47px; 
				}
			}

			.social-feed-text{
				font-size: 13px;
				line-height: 17px; 

				a{
					color: $muted-blue;
				}
			}

			.date{
				margin-top: 10px;
				font-size: 12px;
				font-style: italic; 
				color: $brownish-grey;
			}
		}
	}

}