

	.list_loading{
	
		min-height: 400px; 
	
		
		background: url(../img/loader.gif) center center no-repeat; 

		> li{
			opacity: 0; 
			@include transition(0.8s);
		}

	}




