/**
 * AddToCalendar Blue Style
 * http://addtocalendar.com
 */

/* Base */

.addtocalendar var{
    display: none;
}

.addtocalendar {
    position: relative;
    display: inline-block;
    background: transparent!important;
}



.atcb-link:focus~ul,
.atcb-link:active~ul,
.atcb-list:hover{
    visibility:visible;
}

.atcb-list {
    visibility: hidden;
    position: absolute;
    top:0;
    right: 50px;
   
    width: 240px;
    z-index: 1111;

}

.atcb-list,
.atcb-item
{
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;
}

.atcb-item {
    float: none;
    text-align: left;
}

.atcb-item-link
{
    text-decoration: none;
    outline: none;
    display: block;
}

.atcb-item.hover,
.atcb-item:hover {
    position: relative;
    z-index: 900;
    cursor: pointer;
    text-decoration: none;
    outline: none;
}


/* Blue */


.atc-style-blue .atcb-list {
    width: 170px;
    border: 1px solid rgb(186,186,186);
    border-radius: 2px;
    box-shadow: 0 0 5px #AAA;
}

.atc-style-blue .atcb-list,
.atc-style-blue .atcb-item
{
    background: #fff;
    color: #000;
}

.atc-style-blue .atcb-item,
.atc-style-blue .atcb-item-link
{
    line-height: 1.3em;
    vertical-align: middle;
    zoom: 1;
}

.atc-style-blue .atcb-item-link,
.atc-style-blue .atcb-item-link:hover,
.atc-style-blue .atcb-item-link:active,
.atc-style-blue .atcb-item-link:focus
{
    color: #000;
    font-family: "Verdana";
    font-size: 14px;
    text-decoration: none;
    outline: none;
    padding: 5px 15px;
}

.atc-style-blue .atcb-item-link:hover,
.atc-style-blue .atcb-item-link:active,
.atc-style-blue .atcb-item-link:focus
{
    color: #fff;
}

.atc-style-blue .atcb-item.hover,
.atc-style-blue .atcb-item:hover {
    background: rgb(66,129,244);
}
