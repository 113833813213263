
.clientside-error{
	margin-bottom: 30px;
}

#messages .messages, .clientside-error{
		
		padding: 20px;
		color: #fff;
		font-weight: normal;
		
		border: none; 
		position: relative;
		text-align: left;
		font-family: $ffP;
		display: flex;
		align-items: center; 

		@include breakpoint(small down){
				display: block;

				h2{
					margin-bottom: 15px;
					text-align: center;
					
					margin-bottom: 15px; 
				}

				&:before{
					text-align: center;
					margin-bottom: 15px;
					font-size: 20px !important;
				}
			}



		label{
			color: #fff;
		}

		a{
			text-decoration: underline;
			color: #fff;
			&:hover{
				color:#000;
			}
		}


		li{
			margin: 5px 0px;
			font-weight: normal;
			color: #fff;

			label{
				color: #fff !important; 
				font-weight: normal !important; 
			}


		}

		


		&.status{
			background: $turquoise-blue;
			&:before{
				@include font($zmdi-var-notifications-active); 
			}
		}

		&.error{
			background: $red;
			&:before{
				@include font($zmdi-var-alert-circle); 
			}
		}
		&.warning{
			background: $red; 

			*{
				opacity: 0.82; 
			}

			&:before{
				@include font($zmdi-var-alert-triangle); 
			}
		}

		&:last-child{
			margin-bottom: 0;

		}

		p{
			display: block;
			line-height: 18px; 
			 
		}

		h2{
			text-transform: uppercase;
			margin-right: 35px; 
			font-weight: $fwSemiBold; 
			white-space: nowrap;

			

		}

	
			&:before{
				color: #fff;
				font-size: 14px; 
				margin-right: 10px;
				display: block;
				 
			}
		

	}




#messagesDialog{
	

		.messages{
			text-align: center;
			font-size: 18px;
			
			font-family: $ffP; 
			
			margin: 0px auto; 
			
			a{
				color: $tealish;
				&:hover{
					color: $muted-blue;
				}
			}



			h2{
				width: 160px; 
				border-bottom: solid 1px #d2d2d2;
				padding-bottom: 30px;
				overflow: hidden;
				color: #fff;
				display: block;
				margin: 0px auto; 
				margin-bottom: 30px;
				font-size: 0;

				&:after{
					content: "";
					font-size: 70px;
				}
			}

			&.status{
				

				h2:after{
					@include font($zmdi-var-check-circle)
					color: $green; 
				}
			}

			&.error{
				

				h2:after{
					@include font($zmdi-var-alert-circle)
					color: $red; 
				}
			}

			&.warning{
				h2:after{
					@include font($zmdi-var-alert-triangle)
					color: $red; 
				}
			}
		}

		ul{
			li{
				font-size: 16px; 
				line-height: 20px; 

				&:not(:last-child){
					margin-bottom: 15px;
				}
			}
		}
	
}
