#page_404, #page_403, #page_attente{
	width: 100%; 
	height: 100%; 

	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center; 
	background-repeat: no-repeat; 
	
	text-align: center;
	
	&:before{
		content: "";
		display: inline-block;
		vertical-align: middle;
		width: 0px; 
		height: 100%; 
	}
	.content{
		max-width: 90%; 
		display: inline-block;
		vertical-align: middle;
		padding: 0px 15px; 

		#logo_banner{
			text-align: center;
			display: block;
			margin-bottom: 85px;

			@include breakpoint(large down){
			    margin-bottom: 40px;
				margin-top: 20px;
			}

			img{
				display: block;
				margin: 0px auto; 
				margin-bottom: 30px;
			}

			h2{
				display: block; 
				text-transform: uppercase;
				font-weight: $fwBold; 
				font-size: 15px; 
				color: #fff; 
				line-height: 15px; 
				display: none;				
			}

		}


		h2{
			font-size: 40px;
			margin-bottom: 50px; 
			color: #fff;
			 
			@include breakpoint(small down){
					font-size: 22px;
				}
		}

		p{
			font-size: 30px;
			color: #fff;
			font-weight: 500;

			@include breakpoint(small down){
				font-size: 20px;
				line-height: 30px; 
			}
			
			a{
				color: #fff;
			}
		}

		.button{
			display: inline-block;
			margin-top: 70px;

			@include buttonBorder(#fff, #fff); 

			&:hover{
				color: $muted-blue;
			}
		}
	}

}

#page_404{
	background-image: url(../img/bg_404_2.png); 
}

#page_403{
	background-image: url(../img/bg_404.png); 
}

#page_attente{
	background-image: url(../img/bg_404.png); 

	h1{
		font-size: 50px !important;
		letter-spacing: 2px !important; 

		@include breakpoint(small down){
			font-size: 35px !important; 
		}
	}

	#logo_banner{
		h2{
			display: block !important; 
		}
	}
}


.page-not-found, .page-unauthorized{
	height: 100%; 
}

