#content_tbd {
  .content_tbd_tab {
    @include clearfix;

    @include breakpoint(medium down) {
      margin-top: 20px;
    }

    @include breakpoint(small down) {
      margin-left: -20px;
      margin-right: -20px;
    }

    > li {
      float: left;
      width: 50%;

      a {
        display: block;
        height: 65px;
        line-height: 65px;
        text-align: center;
        font-size: 18px;
        color: #858585;
        font-weight: $fwSemiBold;
        font-size: 18px;
        text-transform: uppercase;
        color: #858585;
        background: #dadbe2;

        @media screen and (max-width: 500px) {
          text-transform: none;
          font-size: 14px;
        }
      }
    }

    > li.active a,
    > li a:hover {
      color: #fff;
      background: $muted-blue;
    }
  }

  .inside {
    #user-profile-form,
    .mandatory_text,
    .edit-account-link {
      max-width: 755px;
      margin-left: auto;
      margin-right: auto;
    }

    .edit-account-link {
      margin-bottom: 40px;

      a {
        color: $turquoise-blue;
        font-weight: $fwSemiBold;

        &:hover {
          color: $muted-blue;
        }
      }
    }

    > h2,
    > h3 {
      font-family: $ffT;
      font-size: 24px;
      font-weight: $fwBold;
      margin-bottom: 20px;
      color: $muted-blue;

      @include breakpoint(medium down) {
        text-align: center;
      }
    }

    > h2 {
      font-size: 22px;
      padding-bottom: 20px;
      border-bottom: 1px solid $muted-blue;
      margin-bottom: 40px;
    }

    .button_publish {
      height: 100px;
      line-height: 100px;
      padding-left: 37px;
      font-size: 24px;
      color: #fff;
      font-weight: $fwBold;
      background-color: $muted-blue;
      background-image: url(../img/bg_file);
      background-position: 20px bottom;
      background-repeat: no-repeat;
      display: block;
      font-family: $ffT;
      position: relative;
      &:hover {
        background-color: $turquoise-blue;
      }

      &:after {
        @include font($zmdi-var-plus);
        height: 100px;
        line-height: 100px;
        font-size: 40px;
        position: absolute;
        right: 40px;
        top: 0;
      }

      &.active {
        &:after {
          @include font($zmdi-var-minus);
        }
      }
    }

    .form-rapport {
      padding: 45px 0px;
      background: #ecedf2;
      display: none;

      @include breakpoint(medium down) {
        padding-left: 40px;
        padding-right: 40px;
      }

      @include breakpoint(small down) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .messages {
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
      }
      form {
        max-width: 750px;
        margin: 0px auto;

        legend {
          display: none;
        }

        label {
          color: #3d3d3d;
        }

        @include breakpoint(medium up) {
          .align_left {
            width: 49%;
            float: left;
          }

          .align_right {
            float: right;
            width: 49%;
          }

          .align_clear {
            clear: both;
          }
        }
        .form-item-title {
          clear: both;
        }

        .form-actions {
          margin-bottom: 0;
          text-align: center;

          #edit-draft {
            display: none;
          }

          .form-submit {
            border-radius: 0;
            appearance: none;
            padding: 0;
            display: inline-block;
            height: 60px;
            line-height: 60px;
            padding: 0px 30px;
            font-size: 18px;
            font-weight: $fwSemiBold;
            background: $muted-blue;
            color: #fff;
            border: none;

            &:hover {
              background: $turquoise-blue;
            }
          }
        }
      }
    }
    .see_previous_container {
      text-align: center;

      .see_previous {
        display: inline-block;
        @include seePrevious;
      }
    }
  }
  .tbd_content_block {
    &:not(:last-child) {
      margin-bottom: 80px;
    }
    background: #ecedf2;

    .block_content {
      padding: 20px;
    }

    .pager {
      background: #fff;
      margin-top: 20px;
    }

    .see_all {
      display: block;
      height: 50px;
      line-height: 50px;
      background: #dadbe2;
      color: #3d3d3d;
      text-align: center;
      font-size: 14px;
      clear: both;
      &:hover {
        color: #fff;
        background: $muted-blue;
      }
    }

    .empty_content {
      display: block;
      height: 50px;
      line-height: 50px;

      color: #3d3d3d;
      text-align: center;
      font-size: 14px;
    }

    &.tbd_content_block_tabs {
      position: relative;
      @include breakpoint(medium down) {
        margin-top: 60px;
      }
      h2 {
        display: none;
      }

      .tabs_items {
        @include clearfix;
        position: absolute;
        top: -32px;
        right: 0;

        @include breakpoint(medium down) {
          width: 100%;

          top: -40px;
          padding-left: 40px;
          padding-right: 40px;
          position: relative;
          .owl-controls {
            .owl-nav {
              > div {
                width: 35px;
                height: 35px;
                background: $secondColor;
                position: absolute;
                top: 0;
                font-size: 0;

                &:after {
                  text-align: center;
                  line-height: 35px;
                  color: #fff;
                  font-size: 20px;
                  display: block;
                }

                &.owl-prev {
                  left: 0;

                  &:after {
                    @include font($zmdi-var-chevron-left);
                  }
                }

                &.owl-next {
                  right: 0;

                  &:after {
                    @include font($zmdi-var-chevron-right);
                  }
                }
              }
            }
          }
        }

        li {
          cursor: pointer;
          float: left;
          height: 32px;
          border-bottom: solid 3px transparent;
          color: #858585;
          font-size: 14px;
          font-weight: $fwSemiBold;
          text-transform: uppercase;

          @include breakpoint(medium down) {
            float: none;
            margin-right: 0 !important;
            width: 100%;
			text-align: center;
			border-bottom: none !important;
			line-height: 35px;
			height: 35px; 

			color: $tealish;
            border-bottom-color: $tealish;
            font-weight: $fwBold;
          }

          &:not(:last-child) {
            margin-right: 40px;
          }

          &.active {
            color: $tealish;
            border-bottom-color: $tealish;
            font-weight: $fwBold;
          }
        }
      }

      .tabs_content {
        > .block-views {
          &:not(:first-child) {
            display: none;
          }
        }
      }

      &.all {
        margin-top: 70px;
        .tabs_items {
          left: 0;
          right: auto;
        }
      }
    }
  }
}

.block_subscribe {
  margin-top: 20px;
  background-color: $muted-blue;
  background-image: url(../img/bg_globe.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  padding: 45px 40px 55px;

  h2 {
    color: #fff;
    font-family: $ffT;
    font-size: 22px;
    margin-bottom: 40px;
    font-weight: $fwBold;
  }

  form {
    position: relative;

    @include breakpoint(large up) {
      padding-right: 155px;

      .form-actions {
        position: absolute;
        right: 0;
        top: 0;
        width: 130px;
      }
    }

    .form-type-select {
      position: relative;

      &:before {
        @include font($zmdi-var-chevron-down);
        position: absolute;
        color: $turquoise-blue;
        font-size: 20px;
        height: 45px;
        line-height: 45px;
        right: 18px;
      }

      select {
        width: 100%;
        appearance: none;
        padding-left: 15px;
        height: 45px;
        border: solid 1px #bfbfbf;
        outline: none;
        border-radius: 0px;
        background: #fff;
        font-size: 16px;
        color: #3d3d3d;
      }
    }

    .form-actions {
      .form-submit {
        appearance: none;
        width: 100%;
        text-align: center;
        height: 45px;
        line-height: 45px;
        background: $turquoise-blue;
        color: #fff;
        font-size: 16px;
        font-weight: $fwSemiBold;
        padding: 0px;
        border: none;
        cursor: pointer;

        &:hover {
          background: $dark-slate-blue;
        }
      }
    }
  }
}

.total_block {
  border-top: solid 1px #cbced5;

  padding: 20px 45px;
  @include clearfix;

  font-weight: $fwBold;
  font-size: 20px;
  text-transform: uppercase;
  color: $muted-blue;

  .label {
    float: left;
  }
  .total {
    float: right;
    font-weight: $fwSemiBold;
  }
}
.tabs_content {
  .button_container {
    background: #fff;
    padding: 30px 0px;

    a {
      float: right;
      height: 60px;
      line-height: 60px;
      padding: 0px 30px;
      font-size: 18px;
      font-weight: $fwSemiBold;
      background: $muted-blue;
      color: #fff;

      @include breakpoint(small down) {
        float: none;
        width: 100%;
        display: block;
      }

      &:hover {
        background: $turquoise-blue;
      }
    }
  }
}
