#steps_container{
	position: relative;
	z-index: 555;
	border-bottom: solid 1px #dadbe2;

	background: $muted-blue; 

	ul{
		@include container(1200px);

		@include breakpoint(large down) {
			max-width: 100%; 
		}

		@include breakpoint(small down) {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		@media screen and (max-width: 400px) {
			padding-left: 0;
			padding-right: 0;
		}

		
		@include clearfix; 


		&[data-total-step='2']{
			> li{
				width: 50%; 
			}
		}
		&[data-total-step='3']{
			> li{
				width: 33.33%; 
			}
		}
		&[data-total-step='4']{
			> li{
				width: 25%; 
			}
		}
		&[data-total-step='5']{
			> li{
				width: 20%; 
			}
		}
		

		> li{
			float: left;

			@include breakpoint(small down) {
				width:  auto !important; 
				float: none; 
				display: block;
				align-items: center;
				justify-content: center;
			}
	


			a{
				display: block;
				height: 65px; 
				display: flex; 
				align-items: center;
				justify-content: center; 
				color: #9db1d2;
				position: relative;
				pointer-events: none; 
				
				@include breakpoint(medium down) {
					padding-left: 25px;
				}

				@include breakpoint(small down) {
					color: $black; 
				}
				
				i{
					display: block;
					width: 32px; 
					height: 32px;
					line-height: 30px; 		
					text-align: center;
					border-radius: 50%; 
					background: #30558e; 
					font-size: 18px;
					font-weight: $fwBold; 
					margin-right: 20px; 
					color: #9db1d2;

					@include breakpoint(medium down) {
						margin-right: 8px;
						width: 26px; 
						height: 26px;
						line-height: 26px; 
						font-size: 15px; 
					}
				}

				span{
					display: block;
					font-size: 18px;
					font-weight: $fwSemiBold; 

					@include breakpoint(small down) {
						max-width: 100px; 
					}

					@include breakpoint(medium down) {
						font-size: 14px;
					}

					@include breakpoint(small down) {
						display: none; 
					}
				}

				&.active{
					background: #fff; 



					@include breakpoint(small down) {
						padding-left: 25px;
						padding-right: 25px;
						span{
							display: block;
						}
					}

					&:before{
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 32px 0 32px 16px;
						border-color: transparent transparent transparent $muted-blue;
					}

					&:after{
						content: "";
						position: absolute;
						top: 0;
						right: -16px; 
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 32px 0 32px 16px;
						border-color: transparent transparent transparent #fff;
						@include breakpoint(small down) {
							
						}
					}
				}
			}

			&:first-child{
				a.active{
					@include fillBackgroundLeftOutside(#fff, 500%);

					&:before{
						border: none; 
					}
				}
			}
			&:last-child{
				a.active{
					@include fillBackgroundRightOutside(#fff, 500%);

					&:after{
						border: none; 
					}
				}
			}
		}
	}
}

.step_container{
	
	display: none; 

	&[data-step='1']{
		display: block;
	}

}