@mixin imgHover{

	position: relative;
	overflow: hidden;

	img{
		@include transition(0.3s); 
		@include scale(1);
		display: block; 
		width: 100%; 
		height: auto; 
	}

	&:after{
		@include font($zmdi-var-eye);
		z-index: 50; 
		position: absolute;
		width: 100%; 
		height: 100%; 
		background: rgba($secondColor, 0.7);
		left: 0;
		top: 0;
		opacity: 0; 
		@include transition(0.3s); 
		color: #fff;
		font-size: 50px;
		line-height: 100%; 
		display: flex;
		align-items: center;
		justify-content: center;
		text-shadow: 0px 0px 4px  rgba(#000, 0.3);
	}

}


@mixin imgHoverActive{

	img{
		@include scale(1.1); 
	}

	&:after{
		opacity: 1; 
	}

	&:before{
		opacity: 1; 
	}

}