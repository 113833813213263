// Generated with Spriteowl (compiler version 1.3.1)
//
// Please define the location for your image source:
//
// $accesrapide-sheet-image: url("../sprites/accesrapide.png");
// $accesrapide-sheet-image-2x: url("../sprites/accesrapide@2x.png");
//
// --- Layers --------------------------------------------
//
// - accesrapide/
//   - annuaireWhite
//   - evenementWhite
//   - comissionWhite
//   - bibliothequeWhite
//   - annuaire
//   - evenement
//   - comission
//   - bibliotheque
//
$accesrapide-sheet-width: 118px !default;
$accesrapide-sheet-height: 209px !default;
$accesrapide-sheet-image: url("../sprites/accesrapide.png") !default;
$accesrapide-sheet-image-2x: url("../sprites/accesrapide@2x.png") !default;
$accesrapide-sheet: $accesrapide-sheet-width $accesrapide-sheet-height $accesrapide-sheet-image $accesrapide-sheet-image-2x accesrapide !default;

@mixin accesrapide-element() {
  background-image: $accesrapide-sheet-image;
  background-repeat: no-repeat;
  @media only screen and (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
    background-image: $accesrapide-sheet-image-2x;
    background-size: $accesrapide-sheet-width $accesrapide-sheet-height;
  }
}
%accesrapide-element {
  @include accesrapide-element();
}

$accesrapide-annuaireWhite-x: -65px !default;
$accesrapide-annuaireWhite-y: -54px !default;
$accesrapide-annuaireWhite-width: 41px !default;
$accesrapide-annuaireWhite-height: 45px !default;
$accesrapide-annuaireWhite: $accesrapide-annuaireWhite-x $accesrapide-annuaireWhite-y $accesrapide-annuaireWhite-width $accesrapide-annuaireWhite-height accesrapide-annuaireWhite !default;

@mixin accesrapide-annuaireWhite-width() {
  width: $accesrapide-annuaireWhite-width;
}
%accesrapide-annuaireWhite-width {
  @include accesrapide-annuaireWhite-width();
}
@mixin accesrapide-annuaireWhite-height() {
  height: $accesrapide-annuaireWhite-height;
}
%accesrapide-annuaireWhite-height {
  @include accesrapide-annuaireWhite-height();
}
@mixin accesrapide-annuaireWhite-size() {
  @include accesrapide-annuaireWhite-width();
  @include accesrapide-annuaireWhite-height();
}
%accesrapide-annuaireWhite-size {
  @include accesrapide-annuaireWhite-size();
}
@mixin accesrapide-annuaireWhite-position() {
  background-position: $accesrapide-annuaireWhite-x $accesrapide-annuaireWhite-y;
}
%accesrapide-annuaireWhite-position {
  @include accesrapide-annuaireWhite-position();
}
@mixin accesrapide-annuaireWhite-adjust() {
  @include accesrapide-annuaireWhite-size();
  @include accesrapide-annuaireWhite-position();
}
%accesrapide-annuaireWhite-adjust {
  @include accesrapide-annuaireWhite-adjust();
}
@mixin accesrapide-annuaireWhite() {
  @include accesrapide-annuaireWhite-adjust();
  @extend %accesrapide-element;
}
%accesrapide-annuaireWhite {
  @include accesrapide-annuaireWhite();
}

$accesrapide-evenementWhite-x: -65px !default;
$accesrapide-evenementWhite-y: --1px !default;
$accesrapide-evenementWhite-width: 42px !default;
$accesrapide-evenementWhite-height: 44px !default;
$accesrapide-evenementWhite: $accesrapide-evenementWhite-x $accesrapide-evenementWhite-y $accesrapide-evenementWhite-width $accesrapide-evenementWhite-height accesrapide-evenementWhite !default;

@mixin accesrapide-evenementWhite-width() {
  width: $accesrapide-evenementWhite-width;
}
%accesrapide-evenementWhite-width {
  @include accesrapide-evenementWhite-width();
}
@mixin accesrapide-evenementWhite-height() {
  height: $accesrapide-evenementWhite-height;
}
%accesrapide-evenementWhite-height {
  @include accesrapide-evenementWhite-height();
}
@mixin accesrapide-evenementWhite-size() {
  @include accesrapide-evenementWhite-width();
  @include accesrapide-evenementWhite-height();
}
%accesrapide-evenementWhite-size {
  @include accesrapide-evenementWhite-size();
}
@mixin accesrapide-evenementWhite-position() {
  background-position: $accesrapide-evenementWhite-x $accesrapide-evenementWhite-y;
}
%accesrapide-evenementWhite-position {
  @include accesrapide-evenementWhite-position();
}
@mixin accesrapide-evenementWhite-adjust() {
  @include accesrapide-evenementWhite-size();
  @include accesrapide-evenementWhite-position();
}
%accesrapide-evenementWhite-adjust {
  @include accesrapide-evenementWhite-adjust();
}
@mixin accesrapide-evenementWhite() {
  @include accesrapide-evenementWhite-adjust();
  @extend %accesrapide-element;
}
%accesrapide-evenementWhite {
  @include accesrapide-evenementWhite();
}

$accesrapide-comissionWhite-x: -67px !default;
$accesrapide-comissionWhite-y: -108px !default;
$accesrapide-comissionWhite-width: 50px !default;
$accesrapide-comissionWhite-height: 50px !default;
$accesrapide-comissionWhite: $accesrapide-comissionWhite-x $accesrapide-comissionWhite-y $accesrapide-comissionWhite-width $accesrapide-comissionWhite-height accesrapide-comissionWhite !default;

@mixin accesrapide-comissionWhite-width() {
  width: $accesrapide-comissionWhite-width;
}
%accesrapide-comissionWhite-width {
  @include accesrapide-comissionWhite-width();
}
@mixin accesrapide-comissionWhite-height() {
  height: $accesrapide-comissionWhite-height;
}
%accesrapide-comissionWhite-height {
  @include accesrapide-comissionWhite-height();
}
@mixin accesrapide-comissionWhite-size() {
  @include accesrapide-comissionWhite-width();
  @include accesrapide-comissionWhite-height();
}
%accesrapide-comissionWhite-size {
  @include accesrapide-comissionWhite-size();
}
@mixin accesrapide-comissionWhite-position() {
  background-position: $accesrapide-comissionWhite-x $accesrapide-comissionWhite-y;
}
%accesrapide-comissionWhite-position {
  @include accesrapide-comissionWhite-position();
}
@mixin accesrapide-comissionWhite-adjust() {
  @include accesrapide-comissionWhite-size();
  @include accesrapide-comissionWhite-position();
}
%accesrapide-comissionWhite-adjust {
  @include accesrapide-comissionWhite-adjust();
}
@mixin accesrapide-comissionWhite() {
  @include accesrapide-comissionWhite-adjust();
  @extend %accesrapide-element;
}
%accesrapide-comissionWhite {
  @include accesrapide-comissionWhite();
}

$accesrapide-bibliothequeWhite-x: -67px !default;
$accesrapide-bibliothequeWhite-y: -167px !default;
$accesrapide-bibliothequeWhite-width: 51px !default;
$accesrapide-bibliothequeWhite-height: 42px !default;
$accesrapide-bibliothequeWhite: $accesrapide-bibliothequeWhite-x $accesrapide-bibliothequeWhite-y $accesrapide-bibliothequeWhite-width $accesrapide-bibliothequeWhite-height accesrapide-bibliothequeWhite !default;

@mixin accesrapide-bibliothequeWhite-width() {
  width: $accesrapide-bibliothequeWhite-width;
}
%accesrapide-bibliothequeWhite-width {
  @include accesrapide-bibliothequeWhite-width();
}
@mixin accesrapide-bibliothequeWhite-height() {
  height: $accesrapide-bibliothequeWhite-height;
}
%accesrapide-bibliothequeWhite-height {
  @include accesrapide-bibliothequeWhite-height();
}
@mixin accesrapide-bibliothequeWhite-size() {
  @include accesrapide-bibliothequeWhite-width();
  @include accesrapide-bibliothequeWhite-height();
}
%accesrapide-bibliothequeWhite-size {
  @include accesrapide-bibliothequeWhite-size();
}
@mixin accesrapide-bibliothequeWhite-position() {
  background-position: $accesrapide-bibliothequeWhite-x $accesrapide-bibliothequeWhite-y;
}
%accesrapide-bibliothequeWhite-position {
  @include accesrapide-bibliothequeWhite-position();
}
@mixin accesrapide-bibliothequeWhite-adjust() {
  @include accesrapide-bibliothequeWhite-size();
  @include accesrapide-bibliothequeWhite-position();
}
%accesrapide-bibliothequeWhite-adjust {
  @include accesrapide-bibliothequeWhite-adjust();
}
@mixin accesrapide-bibliothequeWhite() {
  @include accesrapide-bibliothequeWhite-adjust();
  @extend %accesrapide-element;
}
%accesrapide-bibliothequeWhite {
  @include accesrapide-bibliothequeWhite();
}

$accesrapide-annuaire-x: --1px !default;
$accesrapide-annuaire-y: -54px !default;
$accesrapide-annuaire-width: 41px !default;
$accesrapide-annuaire-height: 45px !default;
$accesrapide-annuaire: $accesrapide-annuaire-x $accesrapide-annuaire-y $accesrapide-annuaire-width $accesrapide-annuaire-height accesrapide-annuaire !default;

@mixin accesrapide-annuaire-width() {
  width: $accesrapide-annuaire-width;
}
%accesrapide-annuaire-width {
  @include accesrapide-annuaire-width();
}
@mixin accesrapide-annuaire-height() {
  height: $accesrapide-annuaire-height;
}
%accesrapide-annuaire-height {
  @include accesrapide-annuaire-height();
}
@mixin accesrapide-annuaire-size() {
  @include accesrapide-annuaire-width();
  @include accesrapide-annuaire-height();
}
%accesrapide-annuaire-size {
  @include accesrapide-annuaire-size();
}
@mixin accesrapide-annuaire-position() {
  background-position: $accesrapide-annuaire-x $accesrapide-annuaire-y;
}
%accesrapide-annuaire-position {
  @include accesrapide-annuaire-position();
}
@mixin accesrapide-annuaire-adjust() {
  @include accesrapide-annuaire-size();
  @include accesrapide-annuaire-position();
}
%accesrapide-annuaire-adjust {
  @include accesrapide-annuaire-adjust();
}
@mixin accesrapide-annuaire() {
  @include accesrapide-annuaire-adjust();
  @extend %accesrapide-element;
}
%accesrapide-annuaire {
  @include accesrapide-annuaire();
}

$accesrapide-evenement-x: --1px !default;
$accesrapide-evenement-y: --1px !default;
$accesrapide-evenement-width: 42px !default;
$accesrapide-evenement-height: 44px !default;
$accesrapide-evenement: $accesrapide-evenement-x $accesrapide-evenement-y $accesrapide-evenement-width $accesrapide-evenement-height accesrapide-evenement !default;

@mixin accesrapide-evenement-width() {
  width: $accesrapide-evenement-width;
}
%accesrapide-evenement-width {
  @include accesrapide-evenement-width();
}
@mixin accesrapide-evenement-height() {
  height: $accesrapide-evenement-height;
}
%accesrapide-evenement-height {
  @include accesrapide-evenement-height();
}
@mixin accesrapide-evenement-size() {
  @include accesrapide-evenement-width();
  @include accesrapide-evenement-height();
}
%accesrapide-evenement-size {
  @include accesrapide-evenement-size();
}
@mixin accesrapide-evenement-position() {
  background-position: $accesrapide-evenement-x $accesrapide-evenement-y;
}
%accesrapide-evenement-position {
  @include accesrapide-evenement-position();
}
@mixin accesrapide-evenement-adjust() {
  @include accesrapide-evenement-size();
  @include accesrapide-evenement-position();
}
%accesrapide-evenement-adjust {
  @include accesrapide-evenement-adjust();
}
@mixin accesrapide-evenement() {
  @include accesrapide-evenement-adjust();
  @extend %accesrapide-element;
}
%accesrapide-evenement {
  @include accesrapide-evenement();
}

$accesrapide-comission-x: -1px !default;
$accesrapide-comission-y: -108px !default;
$accesrapide-comission-width: 50px !default;
$accesrapide-comission-height: 50px !default;
$accesrapide-comission: $accesrapide-comission-x $accesrapide-comission-y $accesrapide-comission-width $accesrapide-comission-height accesrapide-comission !default;

@mixin accesrapide-comission-width() {
  width: $accesrapide-comission-width;
}
%accesrapide-comission-width {
  @include accesrapide-comission-width();
}
@mixin accesrapide-comission-height() {
  height: $accesrapide-comission-height;
}
%accesrapide-comission-height {
  @include accesrapide-comission-height();
}
@mixin accesrapide-comission-size() {
  @include accesrapide-comission-width();
  @include accesrapide-comission-height();
}
%accesrapide-comission-size {
  @include accesrapide-comission-size();
}
@mixin accesrapide-comission-position() {
  background-position: $accesrapide-comission-x $accesrapide-comission-y;
}
%accesrapide-comission-position {
  @include accesrapide-comission-position();
}
@mixin accesrapide-comission-adjust() {
  @include accesrapide-comission-size();
  @include accesrapide-comission-position();
}
%accesrapide-comission-adjust {
  @include accesrapide-comission-adjust();
}
@mixin accesrapide-comission() {
  @include accesrapide-comission-adjust();
  @extend %accesrapide-element;
}
%accesrapide-comission {
  @include accesrapide-comission();
}

$accesrapide-bibliotheque-x: -2px !default;
$accesrapide-bibliotheque-y: -167px !default;
$accesrapide-bibliotheque-width: 51px !default;
$accesrapide-bibliotheque-height: 42px !default;
$accesrapide-bibliotheque: $accesrapide-bibliotheque-x $accesrapide-bibliotheque-y $accesrapide-bibliotheque-width $accesrapide-bibliotheque-height accesrapide-bibliotheque !default;

@mixin accesrapide-bibliotheque-width() {
  width: $accesrapide-bibliotheque-width;
}
%accesrapide-bibliotheque-width {
  @include accesrapide-bibliotheque-width();
}
@mixin accesrapide-bibliotheque-height() {
  height: $accesrapide-bibliotheque-height;
}
%accesrapide-bibliotheque-height {
  @include accesrapide-bibliotheque-height();
}
@mixin accesrapide-bibliotheque-size() {
  @include accesrapide-bibliotheque-width();
  @include accesrapide-bibliotheque-height();
}
%accesrapide-bibliotheque-size {
  @include accesrapide-bibliotheque-size();
}
@mixin accesrapide-bibliotheque-position() {
  background-position: $accesrapide-bibliotheque-x $accesrapide-bibliotheque-y;
}
%accesrapide-bibliotheque-position {
  @include accesrapide-bibliotheque-position();
}
@mixin accesrapide-bibliotheque-adjust() {
  @include accesrapide-bibliotheque-size();
  @include accesrapide-bibliotheque-position();
}
%accesrapide-bibliotheque-adjust {
  @include accesrapide-bibliotheque-adjust();
}
@mixin accesrapide-bibliotheque() {
  @include accesrapide-bibliotheque-adjust();
  @extend %accesrapide-element;
}
%accesrapide-bibliotheque {
  @include accesrapide-bibliotheque();
}

