.list-evenements-hp{
	> li{
		&:not(:last-child){
			margin-bottom: 30px; 
		}
	}
}

.list-evenements-bottom{
	
	margin-bottom: -25px !important;
	
	> li{
		
		margin-bottom: 25px;

		.bg{
			background: #fff; 
		}
	}

	@include breakpoint(large up){
					
		@include row(22); 

		> li {

			@include grid-column(6,  22);

		}
	}

	@include breakpoint(medium down){
		> li{
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
	}

		@include breakpoint(small down){
				max-width: 300px; 
		}

}



.list-evenements-tbd{
	margin-bottom: -23px !important;
	> li{
		margin-bottom: 23px; 

		.bg{
			background: #fff;
		}
	}
	@include breakpoint(xlarge up){
					
		@include row(22); 

		> li {

			@include grid-column(6,  22);

		}
	}


	@media screen and (max-width: 1366px) {
		
	
		> li{

			width: 100%; 
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
	}
}