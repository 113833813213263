.rte {
  h2 {
    text-transform: uppercase;
    color: $muted-blue;
    font-size: 26px;
    font-weight: $fwBold;
    font-family: $ffP;
    margin-bottom: 30px;

    @media print {
      font-size: 20px;
      margin-bottom: 20px;
    }

    @include breakpoint(small down) {
      font-size: 22px;
    }
  }

  h3 {
    text-transform: uppercase;
    color: $muted-blue;
    font-size: 23px;
    font-weight: $fwBold;
    font-family: $ffP;
    margin-bottom: 30px;

    @media print {
      font-size: 18px;
      margin-bottom: 20px;
    }

    @include breakpoint(small down) {
      font-size: 18px;
      margin-bottom: 25px;
    }
  }

  h4 {
    text-transform: uppercase;
    color: $muted-blue;
    font-size: 20px;
    font-weight: $fwBold;
    font-family: $ffP;
    margin-bottom: 30px;

    @include breakpoint(small down) {
      font-size: 16px;
      margin-bottom: 25px;
    }

    @media print {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  h4 {
  }

  h5 {
  }

  p,
  div {
    font-size: 20px;
    line-height: 30px;
    word-break: break-word;

    @include breakpoint(small down) {
      font-size: 16px;
      line-height: 24px;
    }

    &:not(:last-child) {
      margin-bottom: 35px;

      @media print {
        margin-bottom: 15px;
      }
    }

    strong {
      font-weight: bold;
    }

    a {
      color: $viridian;
      font-weight: $fwBold;
      text-decoration: underline;

      &:hover {
        color: $muted-blue;
      }

      &[target="_blank"] {
        &:after {
          @include font($zmdi-var-open-in-new);

          font-size: 18px;
          padding-left: 5px;
        }
      }
    }
    @media print {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .file-document {
    span.file {
      img {
        display: none;
      }

      
	}
	
	// a {
	// 	display: block;
    //     &:after {
    //       @include font($zmdi-var-open-in-new);
    //       font-size: 18px;
	// 	  padding-left: 5px;
	// 	  display: inline !important;
    //     }
    //   }
  }

  em {
    font-style: italic;
  }

  blockquote {
    margin-top: 90px;
    margin-bottom: 100px;
    border-left: solid 6px $greenish-yellow;
    padding-left: 30px;
    font-family: $ffT;
    font-size: 30px;
    font-style: italic;
    line-height: 55px;
    color: $muted-blue;
    font-weight: $fwBold;

    @media print {
      font-size: 16px;
      line-height: 20px;
    }

    @include breakpoint(small down) {
      font-size: 22px;
      line-height: 33px;
      padding-left: 20px;
      margin-top: 50px;
      margin-bottom: 60px;
    }

    p {
      font-size: 30px;
      line-height: 55px;

      @media print {
        font-size: 16px;
        line-height: 20px;
      }

      @include breakpoint(small down) {
        font-size: 22px;
        line-height: 33px;
      }
    }

    strong {
      font-family: $ffP;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: $fwSemiBold;
      font-style: normal;

      @include breakpoint(small down) {
        font-size: 16px;
      }
    }
  }

  .file {
    a {
      &:after {
        display: none !important;
      }
    }
  }

  .bouton,
  .bouton_container a {
    width: 275px;
    max-width: 100%;
    display: block;
    height: 60px;
    line-height: 60px;
    text-align: center;
    margin: 0px auto;
    margin-bottom: 60px;
    color: #fff;
    font-size: 18px;
    font-weight: $fwSemiBold;
    background: $muted-blue;
    text-decoration: none;

    @media print {
      display: none;
    }

    &:hover {
      background: $tealish;
      color: #fff;
    }
  }

  .exergue {
    background: $tealish;
    padding: 50px 0px;
    color: #fff;
    font-size: 26px;
    line-height: 39px;
    margin-bottom: 60px;
    position: relative;
    z-index: 20;

    @media print {
      font-size: 16px;
      line-height: 20px;
    }

    @include breakpoint(small down) {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 40px;
      padding: 40px 0px;
    }

    &:after {
      content: "";
      position: absolute;
      background: $tealish;
      height: 100%;
      width: 1000%;
      left: -500%;
      top: 0;
      z-index: -1;
    }

    &:before {
      font-size: 28.6px;
      line-height: 8.3px;
      @include font($zmdi-var-arrow-left-bottom);
      @include rotate(270deg);
      display: inline-block;
      margin-right: 20px;

      @include breakpoint(small down) {
        font-size: 20px;
        margin-right: 15px;
      }
    }
  }

  ul,
  ol {
    padding-left: 20px;
    margin-bottom: 50px;

    > li {
      font-size: 20px;

      padding-left: 13px;
      position: relative;
      line-height: 25px;

      @include breakpoint(small down) {
        font-size: 16px;
        line-height: 24px;
      }

      strong {
        font-weight: 700;
      }

      @media print {
        font-size: 14px;
      }

      a {
        color: $viridian;
        font-weight: $fwBold;
        text-decoration: underline;

        &:hover {
          color: $muted-blue;
        }
      }

      &:before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: $aqua-blue;
        position: absolute;
        top: 10px;
        left: 0;
      }

      &:not(:last-child) {
        margin-bottom: 20px;

        @media print {
          margin-bottom: 10px;
        }
      }
    }
  }

  ul.no_normal_li {
    padding-left: 0;

    > li {
      &:before {
        display: none;
      }
    }
  }

  ol {
    counter-reset: liincrement;
    > li {
      counter-increment: liincrement;
      &:before {
        content: counter(liincrement);
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #fff;
        font-size: 11px;
        top: 5px;
        left: -16px;
        font-weight: $fwSemiBold;
      }
    }
  }

  .accroche {
    font-size: 22px;
    font-weight: $fwSemiBold;
    line-height: 33px;
    color: $tealish-two;
    margin-bottom: 65px;

    @media print {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 30px;
    }

    @include breakpoint(small down) {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 50px;
    }

    @media print {
      font-size: 16px;
      line-height: 19px;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0px auto; 
  }

  .media-float-right .file-image{
    @include clearfix; 
  }

  iframe {
    margin: 0px auto;
    display: block;
    max-width: 100%;
    width: 100%;
  }

  .media {
    margin-bottom: 60px;

   a{
     display: block; 
     text-decoration: none;
    &:after {
      display: none !important;
    }
   }

    iframe {
      margin: 0px auto;
      display: block;
      max-width: 100%;
      width: 100%;
    }

    .caption,
    .field-name-field-legende {
      margin-top: 12px;
      font-size: 14px;
      font-style: italic;
      line-height: 30px;
      color: #7d7d7d;
    }

    .field-label {
      display: none;
    }

    &.media-full_width {
      .img {
        display: flex;
        justify-content: center;

        @include breakpoint(small down) {
          display: block;
        }

        img {
          max-width: 9000px;
          display: block;

          @include breakpoint(small down) {
            max-width: 130%;
            height: auto;
            position: relative;
            left: -15%;
          }
        }
      }
    }
  }
}
