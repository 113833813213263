.dossier{

	&_teaser{

		position: relative;

		.fav{
			position: absolute;
			top: 16px;
			z-index: 555;
			right: 30px;
			@include flag(#fff); 
			display: none; 
			
			@include tooltip; 

			.tooltip{
				left: -13px;
				top: 34px;
			}
		}


		.bg{
			display: block;
			background: #fff; 
			max-width: 300px; 
			margin: 0px auto;

			.img{

				min-height: 200px; 
				background: $brownish-grey; 
				
				@include imgHover; 
				border-bottom: solid 3px $tealish;

				.cat{
					@include catTeaser;
					position: absolute;
					bottom: -3px;
					left: 0; 
			
				}
			}

			.detail{
				padding: 26px 40px 26px 30px; 
				border: solid 1px #dadbe2;
				border-top: 0px; 

				h3{
					min-height: 96px; 
					font-size: 16px;
					font-weight: $fwSemiBold; 
					line-height: 24px;
					color: $black;
				}

			}

			&:hover{
				.img{
					@include imgHoverActive; 
				}
			}
		}
	}

	&_teaser_hp{
		padding-bottom: 18px;
		.bg{
			display: block; 
			background: #fff; 
			padding: 60px 20px 45px 20px;
			position: relative;

			.cat{
				position: absolute;
				top: 0px; 
				left: 0;
				@include catTeaser;
			}

			.detail{
				h3{
					font-size: 18px;
					font-weight: $fwSemiBold; 
					color: $muted-blue;
					line-height: 27.22px;
					margin-bottom: 20px;
					@include breakpoint(large up){
					height: 81px;
					}
				}

				p{
					color: $black; 
					line-height: 24px; 
					font-size: 16px; 
					@include breakpoint(large up){
						height: 96px;
					}
				}
			}

			&:before{

				@include font($zmdi-var-plus); 
				font-size: 24px;
				color: #fff;
				text-align: center;
				width: 36px;
				height: 36px;
				line-height: 36px; 
				background: $mainColor; 
				border-radius: 50%; 
				position: absolute;
				bottom: -18px; 
				left: 50%;
				margin-left: -18px;		
				@include scale(1); 
				@include transition(0.3s);		
			}

			&:hover{
				box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.1); 

				&:before{
					@include scale(1.2); 
					background: $secondColor; 
				}
			}

		}
	}
}


.page-account-my-favourites{
	.dossier_teaser .fav{
		display: block; 
	}
}