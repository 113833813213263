#alphabetical_listing_filter{

	position: relative;
	padding-right: 85px; 
	
	clear: both; 
	

	ul.list_letter{
		width: 100%; 
		@include clearfix; 
		padding-right: 30px;
		padding-left: 10px;
		background: #ecedf2; 
		> li{
			float: left;
			width: 100 / 26 * 1%;

			input{
				display: none; 
			}

			label{
				width: 100%; 
				height: 27px; 
				line-height: 27px; 
				text-align: center;
				text-transform: uppercase;
				color: #3d3d3d;
				font-size: 14px;
				font-weight: $fwSemiBold; 
				cursor: pointer; 
				display: block;
				background: #ecedf2; 
				&:hover{
					background: $muted-blue;
					color: #fff;
				}
			}

			&.disabled{
				label{
					color: #858585; 
					pointer-events: none; 
				}
			}

			input:checked + label{
				background: $turquoise-blue; 
				color: #fff; 
			}
		}
	}

	.all_letters a{
		position: absolute;
		right: 0;
		bottom: 0;

		width: 85px; 
		height: 27px;
		line-height: 27px; 
		background: $turquoise-blue; 
		font-size: 10px;
		text-align: center;
		color: #fff; 

		&:before{
			@include font($zmdi-var-arrow-right); 
			margin-right: 3px;
			font-size: 12px;
		}

		&:hover{
			background: $muted-blue;
		}
	}
}