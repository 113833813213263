.list-partenaires{

	@include breakpoint(medium up){
					
		@include row(25); 

	}

	@include breakpoint(large up){

		> li {
			@include grid-column(3,  25);
		}

	}

	@include breakpoint(medium only){

		> li{
			@include grid-column(6,  25);
		}
	}

	> li{
		margin-bottom: 25px; 
		
		.bg{
			display: block;
			width: 100%; 
			height: 225px; 
			display: flex;
			align-items: center; 
			justify-content: center; 
			overflow: hidden;
			background: #fff; 

			img{
				display: block;
				max-width: 95%; 
				height: auto; 
			}

		}

	}

}