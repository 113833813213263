.list-search-results{

	margin-bottom: 60px;

	> li{
		padding-top: 35px;
		border-top: solid 1px #e1e1e1;

		&:not(:last-child){
			padding-bottom: 30px; 
		}
	}


}